import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../../shared/services/firestore/firestore.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/share-data/data.service';
import { LogService } from 'src/app/shared/services/logging/log.service';

@Component({
  selector: 'app-members-home',
  templateUrl: './members-home.component.html',
  styleUrls: ['./members-home.component.css']
})
export class MembersHomeComponent implements OnInit {

  requestCount:number=0;
  approvedCount:number=0;
  rejectedCount:number=0;
  constructor(private router: Router,private dataService:DataService,logger:LogService){
    logger.info("Members home constructor");
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    this.dataService.getnewReqCount().subscribe((count)=>{
      this.requestCount = count;
    })
    this.dataService.getApprovedUsersCount().subscribe((count)=>{
      this.approvedCount = count;
    })
    this.dataService.getRejectedUsersCount().subscribe((count)=>{
      this.rejectedCount = count;
    })
  }

}
