import { Component, OnInit, NgZone, ChangeDetectorRef, HostListener } from '@angular/core';
import { FirestoreService , MIGRATION_STAGE } from 'src/app/shared/services/firestore/firestore.service';
import { Router } from '@angular/router';
import { OverlayService } from '../../services/overlay/overlay.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FireAuthService } from '../../services/firebaseAuth/fire-auth.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from '../mat-spinner-overlay/mat-spinner-overlay.component';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { ReAuthService } from 'src/app/shared/services/rc-reauth/rc-reauth.service';
import { PromptDialogComponent } from 'src/app/shared/components/dialog/prompt-dialog/prompt-dialog.component';

const numberOfCardsToShow = 5;
const maxQueryDoc = 99;
const LAST_STATUS = {
      UNKNOWN: 'unknown',
      NOTSTARTED: 'notstarted',
      STARTED: 'started',
      INPROGRESS: 'inprogress',
      SUCCESS: 'success',
      FAILURE: 'failure',
      ABORTED: 'aborted'
  };

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  private TIMER_INTERVAL = 30000;
  public migrationCardsDetails = [];
  private authStateChange: Subscription;
 // public tempinProgressProjects:number = 0;
 // public tempCompletedProjects:number = 0;
   private successCount:number = 0;
   private failureCount:number = 0
   private completedCount:number = 0;
   private inProgressCount:number = 0;
   private notstartedCount:number = 0;
   private abortedCount:number = 0;
   public inProgressProjects:number;
   public completedProjects:number;
   public status = LAST_STATUS;
   public stage = MIGRATION_STAGE;
  lastDoc: any;

  constructor(private router: Router , private fireStoreService:FirestoreService,
    private overlay:OverlayService, public zone: NgZone ,private fireauthService:FireAuthService,
    private cdRef: ChangeDetectorRef, private logger:LogService, 
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private reauthService: ReAuthService, public dialog: MatDialog) {
    this.logger.info("DashboardComponent Constructor");
    this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
      this.authStateChange.unsubscribe();
      if(data !== null){
        this.fetchUserCardDetails();
        this.resetPaginateQuery();
        this.fetchSuccessCardCount();
        this.resetPaginateQuery();
        this.fetchInprogressCardCount();
      }else{
        this.zone.run(() => { this.router.navigate(['/']); });
      }
    });
    this.fireauthService.authStateChangeListener();
   }

  ngOnInit(): void {
    
  }

  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  addCard(){
    this.router.navigate(['/home/migration/company-info']);
  }
     
 /* fetchCompletedCardCount(){
  this.fireStoreService.getCardCount(maxQueryDoc, this.lastDoc, "markAsCompleted" ,true)
  .then((querySnapshot: any) =>{
    querySnapshot.forEach((doc) => {
      let temp = { id: doc.id, ...doc.data() }
      if(temp["stage"] != -1)
       this.completedCount++;
    })
    let len = querySnapshot.docs.length - 1;
    this.lastDoc = querySnapshot.docs[len];
     if(len != -1)
    {         
      this.fetchCompletedCardCount();         
    }
    else
    {
    this.lastDoc = {};
    this.fetchSuccessCardCount();
    }
    })
  }*/
  fetchSuccessCardCount()
  {
    this.fireStoreService.getCardCount(maxQueryDoc, this.lastDoc,"lastStatus","success")
      .then((querySnapshot: any) =>{
        querySnapshot.forEach((doc) => {
          let temp = { id: doc.id, ...doc.data() }
          if(temp["stage"] != -1)
          this.successCount++;
        })
        let len = querySnapshot.docs.length - 1;
        this.lastDoc = querySnapshot.docs[len];
         if(len != -1)
        {         
          this.fetchSuccessCardCount();         
        }
        else
        {
          this.lastDoc = {};
          this.fetchFailureCardCount();
        }
        })
  }
    fetchFailureCardCount(){

    this.fireStoreService.getCardCount(maxQueryDoc, this.lastDoc,"lastStatus","failure")
    .then((querySnapshot: any) =>{
      querySnapshot.forEach((doc) => {
        let temp = { id: doc.id, ...doc.data() }
        if(temp["stage"] != -1)
        this.failureCount++;
    })
    let len = querySnapshot.docs.length - 1;
    this.lastDoc = querySnapshot.docs[len];
     if(len != -1)
    {         
      this.fetchFailureCardCount();         
    }
      this.completedProjects = this.successCount + this.failureCount;
      this.cdRef.detectChanges();
    })
  }
  fetchInprogressCardCount(){
   // this.logger.debug("fetching inprogress card count");
    this.fireStoreService.getCardCount(maxQueryDoc, this.lastDoc,"lastStatus", "inprogress")
    .then((querySnapshot: any) =>{
      querySnapshot.forEach((doc) => {
          let temp = { id: doc.id, ...doc.data() }
          if(temp["stage"] != -1)
          this.inProgressCount++;
      })
        let len = querySnapshot.docs.length - 1;
        this.lastDoc = querySnapshot.docs[len];
         if(len != -1)
        {         
          this.fetchInprogressCardCount();         
        } 
        else
        {
          this.lastDoc = {};
          this.fetchnotStartedCardCount();
        }
        })  
  }
  fetchnotStartedCardCount(){
    // this.logger.debug("fetching inprogress card count");
     this.fireStoreService.getCardCount(maxQueryDoc, this.lastDoc,"lastStatus", "notstarted")
     .then((querySnapshot: any) =>{
       querySnapshot.forEach((doc) => {
           let temp = { id: doc.id, ...doc.data() }
           if(temp["stage"] != -1)
           this.notstartedCount++;
       })
         let len = querySnapshot.docs.length - 1;
         this.lastDoc = querySnapshot.docs[len];
          if(len != -1)
         {         
           this.fetchnotStartedCardCount();        
         } 
         else
         {
           this.lastDoc = {};
           this.fetchabortedCardCount();
         }
         })  
   }

   fetchabortedCardCount()
   {
    // this.logger.debug("fetching inprogress card count");
     this.fireStoreService.getCardCount(maxQueryDoc, this.lastDoc,"lastStatus", "aborted")
     .then((querySnapshot: any) =>{
       querySnapshot.forEach((doc) => {
           let temp = { id: doc.id, ...doc.data() }
           if(temp["stage"] != -1)
           this.abortedCount++;
       })
         let len = querySnapshot.docs.length - 1;
         this.lastDoc = querySnapshot.docs[len];
          if(len != -1)
         {         
           this.fetchabortedCardCount();        
         } 
      
         this.inProgressProjects = this.abortedCount + this.notstartedCount + this.inProgressCount;
         this.cdRef.detectChanges();
        })  
   }
  fetchUserCardDetails() {
   // this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    
   this.fireStoreService.fetchAllCards(maxQueryDoc, this.lastDoc)
    .then((querySnapshot: any) =>{
        let tempDoc = [];
          querySnapshot.forEach((doc) => {
              tempDoc.push({ id: doc.id, ...doc.data() })
          });
          
        let len = querySnapshot.docs.length - 1;
        this.lastDoc = querySnapshot.docs[len];
        /*if(len != -1)
        {         
          this.fetchUserCardDetails();         
        }
       
        if(len == -1)
        {
          this.inProgressProjects = this.tempInProgressProjects;
          this.completedProjects = this.tempCompletedProjects;
        }*/
         this.fetchCardDataForThumbNail(tempDoc);
    })
    .catch((error) =>{
        this.logger.error("fetch user card failed:", error);
    })  
  }

  fetchCardDataForThumbNail(migrationCardsList){
    //let tempArray = [];
   this.migrationCardsDetails = [];
    migrationCardsList.forEach((card , index) => {
        if(card.stage != -1){
        
          let temp = card;
          temp['id'] = card.id;
          temp['date'] = moment(temp.created.toDate()).fromNow();
          temp['users'] = card.totalIpoUsers;
          switch(card.stage){
            case MIGRATION_STAGE.PREVIEWSUMMARY:
            case MIGRATION_STAGE.MORRISONSUMMARY:
              temp['percentage'] = "20%";
              break;
            case MIGRATION_STAGE.PREPARETABLE:
              temp['percentage'] = "40%";
              break;
            case MIGRATION_STAGE.DATAMIGRATION:
              temp['percentage'] = "50%";
              break;
            case MIGRATION_STAGE.COMPLETE:
                if(card.lastStatus == LAST_STATUS.SUCCESS){
                  temp['percentage'] = "100%";
                  temp['users'] = card.assignUsersSuccess;
                }else if(card.lastStatus == LAST_STATUS.FAILURE || card.lastStatus == LAST_STATUS.ABORTED){
                  //calculate the percentage for 40% alloted value for cloud functions
                  /* let successPercentage:number = 0 , totalPercentage:number = 0;
                  successPercentage = Math.round(card.assignUsersSuccess/card.assignUsersTotalUsers) * 40;
                  totalPercentage = 60 + successPercentage;
                  
                  this.logger.debug("success percentage with failure migration :",successPercentage);
                  temp['percentage'] = totalPercentage+"%"; */
                  temp['percentage'] = "60%";
                  temp['users'] = card.assignUsersSuccess;
                }else{
                  temp['percentage'] = "60%";
                  temp['users'] = card.totalIpoUsers;
                }
              break;
            default:
                //this.logger.debug("Stage field not found for :",card.id); 
                temp['percentage'] = "0%";  
          }
          if(temp.markAsCompleted ){
          //  this.tempCompletedProjects = this.tempCompletedProjects + 1;
            temp['percentage'] = "100%";
          }else if(temp.stage === MIGRATION_STAGE.COMPLETE && card.lastStatus === LAST_STATUS.SUCCESS){
         //   this.tempCompletedProjects = this.tempCompletedProjects + 1;
          }else{
           // this.tempInProgressProjects = this.tempInProgressProjects + 1;
          }

          if(this.migrationCardsDetails.length < numberOfCardsToShow ){
            this.migrationCardsDetails.push(temp);
          } 
        }
       /* else{
            this.logger.debug("Stage is -1 for card id :",card.id);
           // this.overlay.closeSpinner();
        }*/
      })

      /* let sortedArray = tempArray.sort(function(a, b) {
        return (b.created.toDate() - a.created.toDate()) 
      })
      if(sortedArray.length < numberOfCardsToShow){
        this.migrationCardsDetails = sortedArray;
        this.cdRef.detectChanges();
      }else{  
        this.migrationCardsDetails = sortedArray.slice(0,5);
        this.cdRef.detectChanges();
      } */
      this.cdRef.detectChanges();
      
      this.logger.debug("Migration cards count :" ,this.migrationCardsDetails.length);

  }

  showCardSummary(cardId, stage, company) {
    this.logger.info("showCardSummary", cardId);
    this.migrationDataService.clearData();
    this.reauthService.validateTokenAndReauthenticate(cardId)
      .then((result: any) => {
        this.session.cardId = cardId;
        this.session.companyName = company;
        switch (stage) {
          case MIGRATION_STAGE.PREVIEWSUMMARY:
            this.zone.run(() => { this.router.navigate(['/home/migration/summary']); });
            break;
          case MIGRATION_STAGE.MORRISONSUMMARY:
            this.zone.run(() => { this.router.navigate(['/home/migration/morrison-summary']); });
            break;
          case MIGRATION_STAGE.PREPARETABLE:
            this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/user-data']); });
            break;
          case MIGRATION_STAGE.DATAMIGRATION:
          case MIGRATION_STAGE.COMPLETE:
            this.zone.run(() => { this.router.navigate(['/home/migration/startMigration']); });
            break;
          default:
            this.logger.debug("showCardSummary for card id not found", cardId);
        }
      })
      .catch((error) => {
          this.logger.error(`${cardId} - showCardSummary: ${error}`);
          //open a UI pop up to show that exception has occurred
          this.notifyAuthenticationFailure(error);
      });
  }

  notifyAuthenticationFailure(message) {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
          message: message,
          isWarning: true
      }
    });
    return dialogRef.afterClosed();
  }

  getRecentMigrations(){
    this.fireStoreService.getRecentMigrationData()
    .then((res) => {
      this.logger.debug("getRecentMigrations: ", res);
    }).catch((err) => {
        this.logger.error("unable to getRecentMigrations: ", err);
    });
  }

  markAsComplete(event:any , cardId){
    this.logger.debug("deleteCard :",cardId);
    document.getElementById(cardId).classList.toggle("show");
    event.cancelBubble = true;
    //update CardId doc with markAsCompleted as 'true'
    //TODO: upadate export status with progress 100%
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.fireStoreService.updateMarkAsComplete(cardId)
    .then((res) =>{
      //TODO:
      //update the status in ACOExport status , progress to 100%   OR
      //in startMigration page , check for markascomplete field and update progress directly 
      return this.fireStoreService.updateMarkAsCompleteToAnalytics(cardId);   
    }).then((res) =>{
      this.resetPaginateQuery();
      this.overlay.closeSpinner();
      this.fetchUserCardDetails();
      this.fetchSuccessCardCount();
      this.resetPaginateQuery();
      this.fetchInprogressCardCount();
      

    }).catch((error) =>{
      this.overlay.closeSpinner();
      this.logger.error("Upadating markascomplete failed: ",cardId)     
    })
  }

  deleteCard(event:any , cardId , companyName){
    this.logger.debug("deleteCard :",cardId);
    event.cancelBubble = true;
    let msg = "'"+companyName + "' migration card will be deleted. Are you sure ?"
    this.session.alertModal(this.onDelete.bind(this , cardId) , msg); 
    this.zone.run(() => {
      this.cdRef.detectChanges();
    })
  }

  onDelete(cardId){
    document.getElementById(cardId).classList.toggle("show");
    event.cancelBubble = true;
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.fireStoreService.deleteUserCard(cardId)
    .then((result:any) =>{
      this.logger.debug("card deleted :",cardId)
      this.overlay.closeSpinner();
      //fetch all cards again to update UI with latest 9 cards 
      this.fetchUserCardDetails();
      this.resetPaginateQuery();
      this.fetchSuccessCardCount();
      this.fetchInprogressCardCount();     
    }).catch((error) =>{
      this.overlay.closeSpinner();
      this.logger.error("card deletion failed :",cardId)
    })
  }
  resetPaginateQuery(){
    this.lastDoc = {};
    this.successCount = 0;
    this.failureCount = 0;
    this.inProgressCount = 0;
    this.completedCount = 0;
    this.inProgressCount = 0;
    this.notstartedCount = 0;
    this.abortedCount = 0;
  }
  openDropDown(event:any ,cardId) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    for (var i = 0; i < dropdowns.length; i++) {
      let openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show') && openDropdown.id !== cardId) {
        openDropdown.classList.remove('show');
      }
    }
    document.getElementById(cardId).classList.toggle("show");
    event.cancelBubble = true;
  }

  @HostListener('document:click', ['$event'])
  onClick(event:any) {
    // do something meaningful with it
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

}
