import { Component, OnInit , Output , EventEmitter, NgZone} from '@angular/core';
import { FirestoreService ,Result, REQUEST, STATUS, MAILTYPE } from 'src/app/shared/services/firestore/firestore.service';
import Tabulator from 'tabulator-tables';
import { firestore } from 'firebase';
import { DataService } from 'src/app/shared/services/share-data/data.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { logging } from 'protractor';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { AdminTableDisplayService } from 'src/app/shared/services/admin-table-display/admin-table-display.service';
import * as firebase from 'firebase/app';


interface user{
  name:string,
  email:string,
  photoUrl:string,
  company:string
};

@Component({
  selector: 'app-new-requests',
  templateUrl: './new-requests.component.html',
  styleUrls: ['../../../../../assets/customcss/user-requests-tabs.css']
})

export class NewRequestsComponent implements OnInit {

  userList = [];
  requestTable :Tabulator;
  searchInput="";
  TIMER_INTERVAL=30000;
  lastViewedUserDoc = {};
  lastViewedProfileDoc = {};
  allRead = false;
  
  constructor(private firestore:FirestoreService,private dataService:DataService,private overlay:OverlayService, 
    private logger:LogService,private adminDisplay:AdminTableDisplayService,private zone:NgZone) 
  {   
    logger.info("New requests constructor");   
    this.dataService.updateUsersCount(REQUEST.APPROVE);
    this.dataService.updateUsersCount(REQUEST.REJECT);
  }


  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  updateFilter(){
    if(this.searchInput.trim() == "")
      this.requestTable.clearFilter();
    else
      this.requestTable.setFilter(this.customFilter,this.searchInput.toLowerCase());   
  }

  customFilter(data,searchString){
      return (data.name.toLowerCase().includes(searchString) ||
           data.email.toLowerCase() === searchString ||
           data.company.toLowerCase().includes(searchString))
  }

  ngOnInit(): void {
    this.requestTable = new Tabulator("#tabulator-div", {	layout:"fitColumns",      //fit columns to width of table
        headerVisible:false,
        resizableColumns:false,
        resizableRows:false,
        autoResize:true,
        addRowPos:"top",
        responsiveLayout:"hide",  //hide columns that dont fit on the table
        tooltips:true,            //allow undo and redo actions on the table
        pagination:"local",       //paginate the data
        paginationSize:10,
        columns:[
          {title:"Avatar",formatter:this.adminDisplay.printImg,  hozAlign:"justify", width:"20%"},
          {title:"userdetails", formatter:this.adminDisplay.printUserDetails, vertAlign:"center", width:"50%"},
          {title:"Approve",formatter:this.adminDisplay.printApproveButton, hozAlign:"right",width:"15%" , vertAlign:"center" ,cellClick:(e, cell)=>{
            this.addressRequest(REQUEST.APPROVE,cell);
          }},
          {title:"Reject",formatter:this.adminDisplay.printRejectButton, hozAlign:"center",width:"15%" , vertAlign:"center" ,cellClick:(e, cell)=>{
            this.addressRequest(REQUEST.REJECT,cell);
          }}
        ]
    });
    window.addEventListener('resize',()=>{
      this.zone.run(() => {
        this.requestTable.redraw(); 
      });
    })
  }
 
  addressRequest(reqType,cell){
        var data =cell.getRow().getData();
        this.logger.info(data);
        this.logger.info("Request type:"+REQUEST[reqType]);
        this.firestore.addressRequest(data,reqType,true)
        .then((res:Result)=>{
              this.logger.info("User: "+res.user["id"]+"\nReason:"+res["reason"]);
              let userData = [];
              userData['name'] = data.name;
              userData['email'] = data.email;
              if(reqType === REQUEST.APPROVE)
                this.firestore.triggerEmailNotification(MAILTYPE.NOTIFY_APPROVAL , userData)
              .then(()=>{
                //TO DO
                //Show a pop-up
              })
              .catch((err)=>{
                this.logger.error(err);
              })

              const index = this.userList.indexOf(res.user);
              if(index != -1){
                this.userList.splice(index,1);
                this.dataService.setNewUsersCount(this.userList.length);
                if(reqType == REQUEST.REJECT)
                  this.dataService.updateUsersCount(REQUEST.REJECT);
                else
                  this.dataService.updateUsersCount(REQUEST.APPROVE);
                cell.getRow().delete();
                if(this.userList.length == 0){ 
                  document.getElementById("displayDiv").style.display = "none";
                }
              }
        }).catch((err:Result)=>{
              this.logger.error("Type: "+err.type+"\nReason: "+err.reason);
        })
  }
  ngAfterViewInit(){ 
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.resetPaginateQuery();
    this.fetchUserDetails();
  }

  fetchUserDetails(){
    return new Promise((resolve,reject)=>{
    var lastViewedDocId =  "";
    if(this.userList.length != 0)
    {
      lastViewedDocId = this.userList[this.userList.length-1]["id"];
    }
    
    this.firestore.getMergedUserList(REQUEST.NEWREQ,lastViewedDocId).then((users:[])=>{
      if(users.length == 0){
        if(this.requestTable.getDataCount() == 0)
          document.getElementById("displayDiv").style.display = "none";
        this.allRead= true;
        this.dataService.setNewUsersCount(this.userList.length);
        this.overlay.closeSpinner();
        resolve();
        return;
      }
      let len = users.length-1;
      users.forEach(user=>{
        this.userList.push(user);
      })
      if(this.requestTable.getDataCount() == 0){        
        this.requestTable.setData(users);
      }else{
        this.requestTable.addData(users);
      }
      this.fetchUserDetails();
    }).catch((error)=>{
      this.logger.error("Error getting users list:"+error);
    });
         
  });  
 
  }

  resetPaginateQuery(){
    this.allRead = false;
    this.lastViewedUserDoc = {};
    this.lastViewedProfileDoc ={};
    this.userList = [];
  }

}


