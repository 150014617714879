<div class="tabInfo">    
    <!-- <div *ngIf="!showErrorModal" style="margin-right:-15vw;margin-left:4%"> -->
        <mat-label class="greetingsLabel">
            Greetings
        </mat-label>
        <p>Upload User Voicemail Greetings, Hunt Group Announcements and Auto Attendant
            Prompts in one {{fileExtnList}} file. Post upload you can verify them in the next data sections
            before migration. This step is optional if none of these configurations are to be migrated.
        </p>

        <div style="display:flex;align-items:center;">
            <mat-form-field >
                <mat-label class="uploadFileLabel">Upload Greetings File ({{allowedFileExtns.join(', ')}})</mat-label>
                <input accept="{{allowedFileExtns.join(',')}}" style="display: none" type="file" multiple (click)="fileInput.value = null" (change)="onGreetingsFileChanged($event)" #fileInput> 
                <input matInput type="text"  style="display:flex;text-overflow: ellipsis;" [(ngModel)]="greetingsfilename">
                <button mat-button  matSuffix mat-icon-button (click)="fileInput.click()">
                    <img src="../../../../../assets/images/config-summary/Icon_upload_file.svg">
                </button>
            </mat-form-field>
            <mat-progress-bar class="progressBar" mode="determinate"  *ngIf="fileUploadStatus == status.INPROGRESS || fileUploadStatus == status.STARTED"  value={{uploadProgress}}></mat-progress-bar>
            <p class="uploadProgressPercentage" *ngIf="fileUploadStatus == status.INPROGRESS || fileUploadStatus == status.STARTED"> 
                {{uploadProgress}}%
            </p>
        </div>

        <span class="lastUpdatedLabelField" *ngIf="(fileUploadStatus != status.FAILURE && fileUploadStatus != status.ABORTED)">
            <img  *ngIf="fileUploadStatus != status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Info_Greetings_last_file_uploaded.svg" class="lastUploadedFileIcon">
            <img  *ngIf="fileUploadStatus == status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Green_check_mark.svg" class="lastUploadedFileIcon">
            <span class="lastUpdatedFileLabelText">Last Uploaded :  {{lastUploadedFileName}}</span>
            <span style="white-space: break-spaces;">{{lastUploadedFileSize}}</span>
        </span>
        

        <span class="errorMsg" *ngIf="(fileUploadStatus == status.FAILURE || fileUploadStatus == status.ABORTED)">
            <mat-icon class="errorIcon">error</mat-icon>
            Upload Failed,{{errorMsg}}
        </span>
    <!-- </div> -->


    
</div>
