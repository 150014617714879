<div class="usersBlock" >
    <div id="displayDiv">
        <div class="searchBlock">
            <mat-form-field class="search-field" >
                <button mat-button  matPrefix mat-icon-button >
                    <mat-icon >search</mat-icon>
                </button>
                <mat-label>Search</mat-label>
                <input matInput type="text" [(ngModel)]="searchInput" (keyup) = "updateApprovedSearchFilter()">
            </mat-form-field >
        </div>
      
        <div id="approved-div" >
        </div>
    </div>
    <div *ngIf="userList.length == 0" class="emptyUsersBlock">
        No Users Approved
    </div>
</div>