<div class="contentBlock">
    <div class="contentInfo">
        <span class="migrationStepInfo">Step 1 (Migration Basic Information)</span>
    </div>
    <div class="contentMain">
        
    </div>
    
    <div class="navigation">
        <button mat-button class="arrowBack" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateNext()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>

