import { FirestoreService } from './../../services/firestore/firestore.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GSignInService } from './../../services/googleAPI/g-sign-in.service';
import { Component, OnInit , NgZone} from '@angular/core';
import { FireAuthService } from '../../services/firebaseAuth/fire-auth.service';
import { Router } from '@angular/router';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from '../mat-spinner-overlay/mat-spinner-overlay.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  subscription: Subscription;
  readonly TIMER_INTERVAL = 60000;

  constructor(private googleService:GSignInService , private fireauthService:FireAuthService,
    private router: Router , public zone: NgZone  , private firestoreService: FirestoreService,
    private overlay:OverlayService, private logger:LogService , private _snackBar: MatSnackBar) {
    this.logger.info("LoginComponent constructor");
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    //subscribe for googleuser data after succesfull g-signin
    this.subscription = this.googleService.getMessage().subscribe(data => {
      this.subscription.unsubscribe();
      this.domainValidation(data);
    });

    //this.googleService.googleInit();
  }

  doLogin(){
    this.logger.debug("login");
    this.googleService.signIn()
    .then((googleUser:any) =>{
      let profileInfo = googleUser.getBasicProfile();
        //this.logger.debug('Token || ' + googleUser.getAuthResponse().id_token);
        this.logger.debug('ID: ' + profileInfo.getId());
        this.logger.debug('Name: ' + profileInfo.getName());
        this.logger.debug('Name: ' + profileInfo.getEmail());
      this.domainValidation(googleUser);
      this.logger.debug(googleUser);
    }).catch((err) =>{
      this.logger.error("google sigin failed :",err);
    });
  }

  domainValidation(googleUserData){
    this.logger.debug("domainValidation");
    if(googleUserData !== null){
      let userEmail:string = googleUserData.getBasicProfile().getEmail();
      this.getSupportedDomains(userEmail)
      .then(()=>{
        this.triggerFireBaseAuth(googleUserData);
      }).catch(()=>{
        this.logger.debug("Domain validation failed for user: ",userEmail);
        this.openSnackBar("Invalid email address");
      })
    }
  }

  userCreationStatus(data: any) {
    if(data.authorized == 1){
      this.zone.run(() => { this.router.navigate(['/home/dashboard']); });
    }else{
      //currenty if authorized is o|2 , we route to profile
      //TODO:do not route if it is 2 
      this.zone.run(() => { this.router.navigate(['/home/profile']); });
    }
  }

  getSupportedDomains(userEmail){
    var promise = new Promise((resolve, reject) => {

      var xhr = new XMLHttpRequest();
      xhr.onload = (event) => {
        let domainList:string = xhr.responseText;
        this.logger.debug(domainList);
        if(userEmail.includes('@') && domainList.includes(userEmail.split('@')[1])){
          resolve(xhr.response);
        }else{
          reject();
        }  
      };
      xhr.onabort = (err) => {
        this.logger.error("Request to fetch well-known domains is aborted" ,err); 
        reject();
      }
      xhr.onerror = (err) =>{
        this.logger.error("Error while fetching well-known domains" ,err); 
        reject();
      }
      xhr.open('GET', environment.well_known_endpoint );
      xhr.send();

    });
    return promise;
}

  timeupCallbackfunc(ol){
    //this.logger.info("Time up");
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  triggerFireBaseAuth(googleUser){
    
      this.subscription = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
        //fire store user creation is successful
        this.subscription.unsubscribe();
        this.overlay.closeSpinner();
        this.userCreationStatus(data);
      }
  );

    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.fireauthService.doFirebaseAuth(googleUser)
      .then((val) => {
        //firebase authentication is successful. But user creation is still in progress.
          this.logger.debug("auth success: ");       
      })
      .catch((err) => 
       {
        this.logger.debug(err)        
       }
        
      );
  }

  openSnackBar(error) {
    this._snackBar.open(error, '', {verticalPosition: 'bottom', horizontalPosition: 'center',
        duration: 10000
      });
  }

}
