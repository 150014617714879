import { FirestoreService, MIGRATION_STAGE, TASK_NAME} from './../../../shared/services/firestore/firestore.service';
import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { Component, OnInit ,NgZone, ChangeDetectorRef } from '@angular/core';
import { FireAuthService } from 'src/app/shared/services/firebaseAuth/fire-auth.service';
import { Router , ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { resolve } from 'dns';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { Subscription } from 'rxjs';


export enum SEVERITY{
  WARNING,
  ERROR
}

const FEATURE_NAME = {
  MORRISON: "Morrison",
  //USER_UPDATERINGONMONITOREDCALLDATA: "ringOnMonitoreCallData",
  POST_MIGRATION: "PostMigration"
}

const TASK_QUEUE_PRIORITY = [
  FEATURE_NAME.MORRISON,
 // FEATURE_NAME.USER_UPDATERINGONMONITOREDCALLDATA,
  FEATURE_NAME.POST_MIGRATION
]

const HEAD_QUATERS_USER_LENGTH = 4;

@Component({
  selector: 'app-morrison-data-component',
  templateUrl: './morrison-data-component.component.html',
  styleUrls: ['./morrison-data-component.component.css']
})
export class MorrisonDataComponentComponent implements OnInit {

  public companyName: string;
  public id: string;
  public showSummary = true;
  private authStateChange: Subscription;
  notifications: any = [];
  SEVERITY = SEVERITY;
  readonly TIMER_INTERVAL = 120000;
  _taskChainList = [];
  private unConditionalForwardingList = [] ;
  private textTOSpeechList = [];
  private extnListFromCsv = [101];
  private accessCode = "";

  constructor(private cdr: ChangeDetectorRef,public zone: NgZone , 
    public _Activatedroute:ActivatedRoute , public db:AngularFirestore,
    private router: Router,
    private migrationDataService:MigrationDataService , private firestoreService:FirestoreService,
    private overlay:OverlayService, private logger:LogService,private cdRef: ChangeDetectorRef,
    private session:SessionHandlerService , private fireauthService:FireAuthService) { 
      this.logger.debug("MigrationDataComponent constructor");
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
      if(this.id == null){
          this.logger.warn("CardId does not exist - route to dashboard");
          this.zone.run(() => { this.router.navigate(['/home/migration']); });
          return;
      }
      this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
          this.authStateChange.unsubscribe();
          if(data !== null){
              //this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
              this.firestoreService.updateStage(this.id, MIGRATION_STAGE.MORRISONSUMMARY).catch();
              this.doACOPrerequisiteInfo();
          }else{
              this.zone.run(() => { this.router.navigate(['/']); });
          } 
      });
      this.fireauthService.authStateChangeListener();
    }

  ngOnInit(): void {
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("Migration summary for morrison - close current Migration Activity");
    this.migrationDataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

  navigateNext(){
    /* let found = this.notifications.find(entry => (
      entry.severity === SEVERITY.ERROR));
    if(found === undefined){ // allow navigation }
        this.zone.run(() => { this.router.navigate(['/home/migration/config-summary']); });
    }else{
      this.getErrorWarningList();
    } */
    if(this.unConditionalForwardingList.length !== 0 || this.textTOSpeechList.length !== 0){
      //push the data to db 
      this.logger.debug(`unConditionalForwardingList length: ${this.unConditionalForwardingList.length} , textTOSpeechList length: ${this.textTOSpeechList.length}`)
      this.updateTaskResult(this.unConditionalForwardingList, this.textTOSpeechList);
    }else{
      this.logger.error("data for migration is empty")
      this.overlay.closeSpinner();
    }
  }

  refresh(){

  }

  proceed(){

  }

  doACOPrerequisiteInfo(){
    this.logger.debug("doACOPrerequisiteInfo");
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.notifications = [];    
    firebase.auth().currentUser.getIdToken(true)
    .then((idToken) => {
        let passArgs = {
            idToken: idToken,
            cardId: this.id
        };

        var readACOInfo = firebase.functions().httpsCallable('readACOInfo');
        readACOInfo(passArgs)
        .then((res) => {        
            this.logger.debug('Received result -' + JSON.stringify(res.data));
            this.checkAcoImportStatus();
        })
        .catch((error) => {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            this.logger.error(code + ' - ' + message);
            this.checkAcoImportStatus();
        });
    })
    .catch((error) => {
        this.logger.error("Unable to fetch getIdToken", error);
        this.checkAcoImportStatus();
    });
  }

  checkAcoImportStatus(){
      this.firestoreService.checkAcoImportStatus(this.id)
      .then((curdata:any) => {  
          this.logger.debug("checkAcoImportStatus "+curdata.desc);   
          if(curdata.status === 0){
              this.getAssignedExtensionList();
          }else if(curdata.status === 1){
              this.notifications.push({msg: curdata.desc, severity: SEVERITY.ERROR});
              this.overlay.closeSpinner();
              this.zone.run(() => {
                this.cdRef.detectChanges();
              })
          }      
      }).catch((err) => {
          this.overlay.closeSpinner();
      });
  }

  getAssignedExtensionList(){
    this.logger.debug("getAssignedExtensionList")
    this.notifications = [];
    this.textTOSpeechList = [];
    this.unConditionalForwardingList = [];
    this.firestoreService.readDoc(this.id)
    .then((curdata:any) => {  
      this.extnListFromCsv = JSON.parse(curdata.data);
      this.accessCode = curdata.accesscode;
      return this.firestoreService.getAssignedExtensions(this.id);
    }).then((curdata:any) => {  
        //this.logger.debug("getAssignedExtensionList success "+curdata.data);
        //get extn list from db 
        let assignedExtnList = JSON.parse(curdata.data);  
        this.logger.debug("assignedExtnList:", assignedExtnList.length);
        assignedExtnList.forEach(extnList => {
          //Removed check based on ACOS-702 | extnList.extensionNumber.length === HEAD_QUATERS_USER_LENGTH && 
          if(extnList.contact.pronouncedName !== undefined && extnList.contact.pronouncedName.text.includes(extnList.extensionNumber) 
          && extnList.contact.pronouncedName.text.trim().startsWith(extnList.extensionNumber)){
            this.textTOSpeechList.push({
              id: extnList.extensionNumber,
              extId: extnList.id,
              name: extnList.contact.pronouncedName.text.replace(extnList.extensionNumber,'')
            })
          }        
        });

        this.extnListFromCsv.forEach(extn => {
          let found = assignedExtnList.find(entry => entry.extensionNumber === extn);
          if(found){
            this.unConditionalForwardingList.push({
              id: found.extensionNumber,
              extId: found.id,
              phoneNumber: this.accessCode + found.extensionNumber
            })
          }       
        });

        if(this.extnListFromCsv.length > assignedExtnList.length){
          this.notifications.push({msg: `CSV contains ${this.extnListFromCsv.length - this.unConditionalForwardingList.length} users more, please create these extension in ACO `, severity: SEVERITY.ERROR});
        }
        this.notifications.push({msg: `${this.unConditionalForwardingList.length} users unconditional Callforwarding will be enabled with ${this.accessCode}E as forwarding number.`, severity: SEVERITY.WARNING});
        this.notifications.push({msg: `${this.textTOSpeechList.length} Head Quater users text-to-speech text will be modified.`, severity: SEVERITY.WARNING});
        this.zone.run(() => {
          this.cdRef.detectChanges();
        })
        this.updateCardDetails();
    }).catch((err) => {
        this.logger.debug("getAssignedExtensionList failed: "+err);
        this.overlay.closeSpinner();
    });
  }

  updateCardDetails(){
    this.logger.debug("updateCardDetails");
    this.firestoreService.getCardDetails(this.id)
    .then((curdata:any)=>{
      var morrisonFieldsForMTUserCard = {
        totalIpoUsers: this.unConditionalForwardingList.length + this.textTOSpeechList.length,
        acoCompany: curdata.company,
        acoAccountId: curdata.accountId,
      };
      return this.firestoreService.setMTUserCardDetailsForMorrison(this.id , morrisonFieldsForMTUserCard);
    }).then(()=>{
      this.overlay.closeSpinner();
    }).catch((err) => {
      this.logger.debug("updateCardDetails failed: "+err);
      this.overlay.closeSpinner();
    });
  }

  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    //this.prerequisiteFetchFailure('timed out');
    ol.showSpinner = false;
    ol.closeSpinner();
  }
  
  updateTaskResult(unConditionalForwardingList, textTOSpeechList){
    this.logger.debug("updateTaskResult");
    this.updateFeatureTaskChain(FEATURE_NAME.MORRISON);
    this.updateFeatureTaskChain(FEATURE_NAME.POST_MIGRATION);
    this.prioritizeTaskQueue();
    this.firestoreService.getTasksTemplate(this.id)
    .then((tasks)=>{
      tasks[TASK_NAME.CHAIN].data = this._taskChainList;
      tasks[TASK_NAME.MORRISON_UNCONDITIONAL_FORWARDING].data = unConditionalForwardingList;
      tasks[TASK_NAME.MORRISON_TEXT_TO_SPEECH].data = textTOSpeechList;
      return this.firestoreService.setACOMigrationData(tasks)
    }).then(()=>{
      this.firestoreService.updateStage(this.id, MIGRATION_STAGE.DATAMIGRATION).catch();
      this.overlay.closeSpinner();
      this.zone.run(() => { this.router.navigate(['/home/migration/startMigration']); }); 
    }).catch((error)=>{
      this.logger.error("updateTaskResult- setACOMigrationData failed ",error)
      this.overlay.closeSpinner();
    });
  }

  private updateFeatureTaskChain(featureName){ 
    let featureFound  = this._taskChainList.find((entry:any) =>  entry == featureName);
    if(!featureFound){
        this._taskChainList.push(featureName);
    }
}

private prioritizeTaskQueue(){
    let newTaskChain = [];
    TASK_QUEUE_PRIORITY.filter((entry:any) => {
        let featureFound = this._taskChainList.find((featureName:any) => featureName === entry);
        if(featureFound){
            newTaskChain.push(featureFound);
        }
    })
    this._taskChainList = newTaskChain;
}

}
