import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';

@Component({
  selector: 'app-customer-company-info',
  templateUrl: './customer-company-info.component.html',
  styleUrls: ['./customer-company-info.component.css']
})
export class CustomerCompanyInfoComponent implements OnInit {
  migration_company_name:string;
  constructor(private router: Router ,
    private migrationdataService:MigrationDataService , public zone: NgZone,
    private logger:LogService ,private session: SessionHandlerService ) { 
    this.logger.debug("Customer comapany info Constructor");
    this.migrationdataService.clearData();
    this.migration_company_name = this.migrationdataService.getCustomerCompanyName;
  }

  ngOnInit(): void {
  }

  navigateNext(){
    if(this.migration_company_name != null && this.migration_company_name != undefined) {
      if(this.migration_company_name.trim() !== ""){
        this.migrationdataService.setCustomerCompanyName = this.migration_company_name;
        this.session.companyName = this.migration_company_name;
        this.router.navigate(['/home/migration/upload-csv']);
      }  
    }  
  }

  navigateBack(){
    //this.router.navigate(['/home/migration/upload-csv']);
  }

  closeMigrationActivity(){
    this.logger.debug("Close current Migration Activity");
    this.migrationdataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

}
