import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { LogService } from '../logging/log.service';
import { MatDialog } from '@angular/material/dialog';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { PromptDialogComponent } from 'src/app/shared/components/dialog/prompt-dialog/prompt-dialog.component';

const LOGIN_FAILED_ERROR = {
      GENERIC_MSG:'Failed to reauthenticate. Retry later with valid service account',
};

const NOTIFICATIONS = {
    REAUTH_NOTIFICATION: '\n\nCard has expired. Please reauthenticate to proceed with migration. \nUse the refresh button after successful reauthentication to fetch the latest ACO configurations, if required.',
    REFRESH_NOTIFICATION: '\n\nACO configurations might have changed. \nPlease use the refresh button to fetch latest ACO configurations and verify assigned configurations before proceeding to migration.'
}

const LOGIN_VIA_SERVICE_WEB_MSG = 'Login using service web failed';

@Injectable({
    providedIn: 'root'
  })
export class ReAuthService {

    showRCSignInErrMsg = false;
    onACOAuthSuccess = false;
    rcSignInErrorMsg: string = LOGIN_FAILED_ERROR.GENERIC_MSG;
    readonly TIMER_INTERVAL=60000;
    newWindow:any;
    wait:any;

    constructor(private firestoreService: FirestoreService,
        private logger:LogService, public dialog: MatDialog,
        private spinnerOverlay:OverlayService, private session: SessionHandlerService) {
        this.logger.info("ReAuthService constructor"); 
    }

    public validateTokenAndReauthenticate(cardId) {
        var promise = new Promise((resolve, reject) => {
            this.logger.debug("validateTokenAndReauthenticate");
            if (cardId !== undefined && cardId.length > 0 && cardId !== null) {
                this.spinnerOverlay.openSpinner(this.TIMER_INTERVAL, this.timeupCallbackfunc, MatSpinnerOverlayComponent);
                this.showRCSignInErrMsg = false;
                this.firestoreService.rcAuth(cardId)
                .then((result:any) => {
                    this.logger.debug(`${cardId} - RC auth complete `);
                    this.validateResponse(result)
                    .then((result) => {
                        this.spinnerOverlay.closeSpinner();
                        resolve(result);
                    })
                    .catch((err) => {
                        console.log("Caught in catch of validateResponse in validateTokenAndReauthenticate");
                        this.logger.error(`${cardId} - validateTokenAndReauthenticate : ${err}`);
                        this.spinnerOverlay.closeSpinner();
                        reject(err);
                    })
                })
                .catch((err) => {
                    this.logger.error(`${cardId} - validateTokenAndReauthenticate : ${err}`);
                    this.spinnerOverlay.closeSpinner();
                    reject(err);
                });
            } else {
                this.logger.error("cardId is invalid");
                reject(`CardId: {cardId} is invalid`);
            }
        });
        return promise;
    }

    validateResponse(responseData){
        var promise  = new Promise<void>((resolve, reject) => {
            this.logger.debug("validateResponse");
            //Cred based login can only get responseData.result
            if(responseData && responseData.result){
                this.logger.debug("cardId : "+responseData.result.cardId);
                this.logger.debug("rcauth URL : "+responseData.result.rcLoginUrl) ;
                this.logger.debug("validtokenExist : "+responseData.result.validTokenExist);
                this.logger.debug("accountBasedLogin : "+responseData.result.accountBasedLogin);
                this.session.cardId = responseData.result.cardId;
            
                if(!responseData.result.validTokenExist){ 
                    if (responseData.result.accountBasedLogin !== undefined && responseData.result.accountBasedLogin !== null && 
                        responseData.result.accountBasedLogin === false) {
                        this.spinnerOverlay.closeSpinner();
                        
                        //ACOS-762 , this change has to be reverted once we have new RC App created with ACO brand enabled for access permission 
                        let rcLoginUrl = responseData.result.rcLoginUrl;
                        if(window.location.hostname.includes("dev1.migrationsrv.com") && responseData.result.rcLoginUrl.includes("&brand_id=6010")){
                            this.logger.debug("dev1.migrationsrv.com deployment , removing ACO brand from LoginURL");
                            rcLoginUrl = rcLoginUrl.replace("&brand_id=6010","");
                        }
                        //Notify to refresh ACO configurations and Reauthenticate on user's approval
                        this.notifyAndReauthenticate(NOTIFICATIONS.REAUTH_NOTIFICATION, true, rcLoginUrl)
                        .then((result) => {
                            resolve();
                        })
                        .catch(error => {
                            this.logger.error(`${this.session.cardId} - validateResponse : ${error}`);
                            reject(error);
                        })
                    } else if (responseData.result.accountBasedLogin !== undefined && responseData.result.accountBasedLogin !== null && 
                        responseData.result.accountBasedLogin === true) {
                        //Notify user to refresh ACO configurations
                        this.notifyAndReauthenticate(NOTIFICATIONS.REFRESH_NOTIFICATION, false, null)
                        .then((result) => {
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            }
            else{
                //show error dialog
                this.rcSignInErrorMsg = LOGIN_FAILED_ERROR.GENERIC_MSG;
                this.showRCSignInErrMsg = true;
                this.logger.error("Response data is not valid");
                this.spinnerOverlay.closeSpinner(); 
                reject();     
            }
        });
        return promise;
    }

    notifyAndReauthenticate(message, isReauth, rcLoginUrl) {
        var promise = new Promise<void>((resolve, reject) => {
            let dialogRef;
            if (isReauth === true) {
                dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        message: message,
                        buttonText: {
                            ok: 'OK',
                            cancel: 'Cancel'
                        },
                        isWarning: true
                    }
                });
            } else {
                dialogRef = this.dialog.open(PromptDialogComponent, {
                    data: {
                        message: message,
                        isWarning: true
                    }
                });
            }
            
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed && isReauth) {
                    this.popupCenter(rcLoginUrl);
                    this.onAcoAuthChange()
                    .then((result) => {
                        resolve();
                    })
                    .catch(error => reject(error));
                } else if (isReauth) {
                    this.logger.warn("User choice to reauthenticate/refresh ACO data : ", confirmed);
                    resolve();
                } else {
                    this.logger.warn("User choice to refresh ACO data : ", confirmed);
                    resolve();
                }
            });
        });
        return promise;
    }

    popupCenter(rcLoginUrl){
        //var url = "https://us-central1-vignaraj-playground.cloudfunctions.net/authorize?state="+id;
        var w = 400;
        var h = 400;
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        this.newWindow = window.open(rcLoginUrl, "RC Authentication",
            `
            scrollbars=yes,
            width=${w / systemZoom}, 
            height=${h / systemZoom}, 
            top=${top}, 
            left=${left}
            `
        );

        if (window.focus) this.newWindow.focus();
        document.onmousedown = this.focusPopup.bind(this);
        document.onkeyup = this.focusPopup.bind(this);
        document.onmousemove = this.focusPopup.bind(this);
        this.wait = setInterval(() => {
            this.setRCErrMsgStr();
            if (this.newWindow === null) {
                clearInterval(this.wait);
            }
        }, 1000);
    }

    onAcoAuthChange() {
        var promise = new Promise<void>((resolve, reject) => {
        this.logger.debug(" onAcoAuthChange ");
        var cardId = this.session.cardId;
        this.onACOAuthSuccess = false;
        this.firestoreService.onAcoAuthChange(cardId)
            .then((curdata: any) => {
                if (curdata.status == "started") {
                    //this._snackBar.open(curdata.status);
                    this.logger.debug("started");
                } else if (curdata.status == "inprogress") {
                    this.logger.debug("inprogress");
                } else if (curdata.status == "success") {
                    this.logger.debug("success");
                    if (this.newWindow) {
                        this.onACOAuthSuccess = true;
                        this.showRCSignInErrMsg = false;
                        this.newWindow.close()
                    }
                    this.spinnerOverlay.closeSpinner();
                    resolve();
                } else if (curdata.status == "failed") {
                    this.logger.debug("failure");
                    this.spinnerOverlay.closeSpinner();
                    this.rcSignInErrorMsg = LOGIN_FAILED_ERROR.GENERIC_MSG;
                    if (this.newWindow) {
                        this.newWindow.close();
                    }
                    reject();
                }
            }).catch((err) => {
                this.spinnerOverlay.closeSpinner();
                this.logger.error(err);
                reject();
            });
        })
        return promise;
    }

    focusPopup(){
        if(this.newWindow && !this.newWindow.closed) {
             this.newWindow.focus(); 
        }
        this.setRCErrMsgStr();
    } 

    setRCErrMsgStr(){
        if(this.newWindow && this.newWindow.closed){
          this.newWindow = null;
          if(!this.onACOAuthSuccess){
            this.rcSignInErrorMsg = LOGIN_VIA_SERVICE_WEB_MSG;
            this.showRCSignInErrMsg = true;
          }
        }
    }

    timeupCallbackfunc(ol){
        //this.logger.debug("Time up");
        ol.showSpinner = false;
        ol.closeSpinner();
    }
  }