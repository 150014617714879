import { Component, OnInit, ChangeDetectorRef , ViewChild , NgZone} from '@angular/core';
import * as firebase from 'firebase';
import {MatAccordion} from '@angular/material/expansion';
import { LogService } from '../../services/logging/log.service';
import { OverlayService } from '../../services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from '../mat-spinner-overlay/mat-spinner-overlay.component';
import {NgxLinkifyjsService} from 'ngx-linkifyjs';

@Component({
  selector: 'app-contact',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

    faqList:Array<Object>=[];
    allPanelsExpanded:boolean=false;
    TIMER_INTERVAL:number=30000;
    isFaqListEmpty:boolean=false;
    userDisplayMsg:String="Error while fetching FAQ content.";

    constructor(private logger:LogService , private zone: NgZone,private overlay:OverlayService,private linkifyService: NgxLinkifyjsService) {
    }

    ngOnInit(): void {
     
    }

    ngAfterViewInit(){
      this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
      var storage = firebase.storage();
      var storageRef= storage.ref('faq/faq.json');
       storageRef.getDownloadURL().then((url)=>{
        var xhr = new XMLHttpRequest();
        xhr.onload = (event) => {
          this.zone.run(() => {
            this.faqList = JSON.parse(xhr.response);
            this.faqList.forEach(faq=>{
             // faq["question"] = this.linkifyService.linkify(faq["question"]);
              faq["answer"] = this.linkifyService.linkify(faq["answer"]);
            });
            if(this.faqList.length != 0)
              this.isFaqListEmpty = false;
            else{
              this.userDisplayMsg = 'FAQ contents yet to be added';
              this.isFaqListEmpty=true;
            }
            this.overlay.closeSpinner();
          })         
        };
        xhr.onabort = () => {
          this.overlay.closeSpinner();
          this.zone.run(() => {
            this.isFaqListEmpty=true;
          });
          this.logger.error("Request to fetch faq is aborted");
        }
        xhr.onerror = () =>{
          this.overlay.closeSpinner(); 
          this.zone.run(() => {
            this.isFaqListEmpty=true;
          });
          this.logger.error("Error while fetching faq document");
        }
        xhr.open('GET', url);
        xhr.send();
       }).catch(error => {
          this.zone.run(() => {
            this.isFaqListEmpty=true;
          });
          this.logger.error("Error getting the faq doc url: "+error.message);
       });
    }

   /* toggleExpansion(){
      if(this.allPanelsExpanded){
        this.accordion.closeAll();
        this.allPanelsExpanded=false;
        document.getElementById("toggleBtn").innerHTML = "Expand All";
      }else{
        this.accordion.openAll();
        this.allPanelsExpanded=true;
        document.getElementById("toggleBtn").innerHTML = "Collapse All";
      }  
    }*/

    timeupCallbackfunc(ol){
      ol.showSpinner = false;
      ol.closeSpinner();
    }
}
