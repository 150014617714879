import { error } from 'protractor';
import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FirestoreService ,MIGRATION_STAGE } from 'src/app/shared/services/firestore/firestore.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { Subscription } from 'rxjs';
import { FireAuthService } from 'src/app/shared/services/firebaseAuth/fire-auth.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import * as firebase from 'firebase/app';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
/* import { saveAs } from 'file-saver'; */
import * as jsPDF from 'jspdf';
/* import {jsPDF} from 'jspdf'; */
import html2canvas from 'html2canvas';
/* (window as any).html2canvas = html2canvas; */
import { saveAs } from 'file-saver';

var RESULT = {
    NOTAVAILABLE: -1,
    SUCCESSFUL: 0,
    FAILURE: 1,
    ABORTED: 2,
    TASK_ABORTED: 3,
    NOTAPPLICABLE: 4
}

const TRIGGER_VALUE = {
    START : "start",
    RETRY : "retry"
}

const MIGRATION_SUMMARY_STRINGS = {
    INITIAL : "Please click 'Start Migration' to start.",
    INPROGRESS : "Migration in progress...",
    SUCCESSFUL : "Migration Successful.",
    FAILURE : "Migration Failed.",
    FAILURE_RETRY : "Migration Failed. Click 'Retry' to try again.",
    MORE_OPTIONS : "(Click Here for more details)",
    STATUS: "Status :",
    START_TIME: "Start Time :",
    END_TIME : "End Time :",
    DURATION : "Duration :",
    TOTAL_USERS : "Total Users :",
    SUCCESSFUL_USERS : "Successful Users :",
    FAILURE_USERS : "Failure Users :",
    FAILURE_USER_DATA_GENERIC : "Connection Error",
    FAILURE_USER_DATA_ACOERROR : "acoerror",
    COMPLETED_BY_USER : "Migration is completed manually.", 
    TASK_ABORTED: "Oops! Something went wrong. Retry or if error persist, please contact Administrator"
}

@Component({
  selector: 'app-start-migration',
  templateUrl: './start-migration.component.html',
  styleUrls: ['./start-migration.component.css']
})
export class StartMigrationComponent implements OnInit, OnDestroy {
    
    subscription: Subscription;
    private id:any;
    public isMigrationStarted = false;
    public migrationStatus = -1;
    public migrationProgress = 0;
    private readonly TIMER_INTERVAL=90000;
    private readonly MINUTE=60000;
    private authStateChange: Subscription;
    public status = RESULT;
    public migration_strings = MIGRATION_SUMMARY_STRINGS;
    public migrationInfo:string;
    public migrationSummaryData:any;
    public showMigrationSummary = false;
    public showFailureData = false;
    public failureUserData:any = [];
    public isMarkAsComplete: false;

    private statusCheckTimer:any = null;
    private isTaskInQueue:any;
    readonly MAX_CHECK_ITERATION = 3;
    private iterationCount: number;
    private isOnline = false;
    public companyName: string;

    public featureDocData = [];
    public migrationStatusDocData= null;

    public isMorrisonProject = false;
    private extensionMap = [];

  constructor(private router: Router , public zone: NgZone, 
    private migrationDataService:MigrationDataService,
    private firestoreService: FirestoreService, public _Activatedroute:ActivatedRoute,
    private overlay: OverlayService ,private fireauthService:FireAuthService,
    private cdRef: ChangeDetectorRef, private logger:LogService,
    private session:SessionHandlerService )
  { 
    this.isTaskInQueue = false;
    this.iterationCount = 0;
    this.isOnline = false;
    this.id = this.session.cardId;  
    this.logger.debug("StartMigrationComponent constructor: ", this.id)
    this.companyName = this.session.companyName;
    if(this.id == null){
        this.logger.warn("CardId does not exist - route to dashboard");
        this.zone.run(() => { this.router.navigate(['/home/migration']); });
        return;
    }
    
    this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
        this.authStateChange.unsubscribe();
        if(data !== null){
            this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
            this.preFetchAcoExportData();
        }else{
        this.zone.run(() => { this.router.navigate(['/']); });
        } 
    });
    this.fireauthService.authStateChangeListener();
  }

  preFetchAcoExportData(){
    this.logger.debug("preFetchAcoExportData");
    //this.firestoreService.updateStage(this.id, MIGRATION_STAGE.DATAMIGRATION).catch();
    this.migrationInfo = MIGRATION_SUMMARY_STRINGS.INITIAL;
    this.firestoreService.readACOExportTrigger(this.id)
    .then((result:any) => {
        this.overlay.closeSpinner();
        if(result.trigger !== 'notyet'){
            this.logger.debug("Migration completed already");
            this.isMigrationStarted = true;
            this.migrationInfo = MIGRATION_SUMMARY_STRINGS.INPROGRESS;
            this.getCardDetails(); 
        }else{
            this.firestoreService.getUserCardDetails(this.id)
            .then((result:any) => {
                if(result.type !== undefined && result.type === 'morrison'){
                    this.isMorrisonProject = true;
                }
            }).catch(()=>{})   
        }
    })
    .catch((error) => {
        this.overlay.closeSpinner();
        this.logger.error(error);
    });
  }

  getCardDetails(){
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.firestoreService.getUserCardDetails(this.id)
    .then((result:any) => {
        this.logger.debug("getUserCardDetails successful:",this.id);
        this.isMarkAsComplete = result.markAsCompleted; 
        if(result.type !== undefined && result.type === 'morrison'){
            this.isMorrisonProject = true;
        }
        //migration progress is updated as 100% since it is complete by user manually by "markAsComplete"
        if(this.isMarkAsComplete){
            this.migrationProgress = 100;
        }
        this.subscribeMigrationStatus();
        this.firestoreService.checkACOMigrationStatus(this.id);
        return;
    })
    .then( () => {
        this.checkNetworkConn$().subscribe(isOnline => { 
            this.isOnline = isOnline;
            this.logger.debug("Network Connected: ", isOnline);
            if(this.isOnline){
                this.isTaskInQueue = false;
                this.doMigrationStatusCheck();
            }
        });
    })
    .catch((error) => {
        this.overlay.closeSpinner();
        this.logger.error(error);
    });
  }

  ngOnInit(): void {
  }

  navigateBack(){
    if(!this.isMigrationStarted){
        if(!this.isMorrisonProject){
            this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/upload-greetings-files']); });
        }else{
            this.zone.run(() => { this.router.navigate(['/home/migration/morrison-summary']); });
        }
    }else{
        this.logger.error("Back Navigation Failed. Migration has started")
    }     
  }
  
  clearStatusCheckTimer(){
      if(this.statusCheckTimer != null){
        clearTimeout(this.statusCheckTimer);
        this.statusCheckTimer = null;
      }
  }

  checkNetworkConn$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

    closeMigrationActivity(){
        this.session.alertModal(this.onClose.bind(this));
    }

    onClose() {
        this.logger.debug("Start Migration - close current Migration Activity");
        this.migrationDataService.clearData();
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }

    startMigration(triggerValue){
        this.logger.debug("startMigration with trigger: %s for card : %s:" , triggerValue ,this.id);
        this.firestoreService.updateStage(this.id, MIGRATION_STAGE.COMPLETE).catch();
        if(!this.isMigrationStarted){ 
            this.migrationInfo = MIGRATION_SUMMARY_STRINGS.INPROGRESS;    
            this.isMigrationStarted = !this.isMigrationStarted;
            this.logger.debug("Start Migration for :" , this.id);
            this.showMigrationSummary = false;
            this.isTaskInQueue = false;
            this.iterationCount = 0;
            if(triggerValue === TRIGGER_VALUE.START){
                this.setTriggerForACOExport(triggerValue);
                this.statusCheckTimer = setTimeout(() => { 
                    this.doMigrationStatusCheck();
                }, this.MINUTE);
                
            }else if(triggerValue === TRIGGER_VALUE.RETRY){
                this.firestoreService.resetACOExportStatus(this.id)
                .then(() => {
                    this.logger.debug("ACO Export status is updated for retry :" , this.id );
                    this.migrationProgress = 0;
                    this.setTriggerForACOExport(triggerValue);
                    this.clearStatusCheckTimer();
                    this.statusCheckTimer = setTimeout(() => { 
                        this.doMigrationStatusCheck();
                    }, this.MINUTE);
                })
                .catch((error) => {
                    this.logger.error(error);
                });
            }
        }
    }


    setTriggerForACOExport(triggerValue){
        this.logger.info("setTriggerForACOExport :",this.id);
        this.firestoreService.setACOExportTrigger(this.id , triggerValue)
        .then(() => {
            this.logger.debug("Migration for card ID %s triggered Succefully" , this.id );
            this.subscribeMigrationStatus();
            this.firestoreService.checkACOMigrationStatus(this.id);
        })
        .catch((error) => {
            this.logger.error(error);
        });
    }

    subscribeMigrationStatus(){
        this.subscription = this.firestoreService.getMessage().subscribe((result) => {
            if(result.progress > this.migrationProgress ){
                this.migrationProgress = result.progress;
                this.logger.debug("migration progress :", this.migrationProgress);
            }
            this.migrationStatus = result.status;
            this.logger.debug("Migration status update -  progress: ", result.progress, " status: ", this.migrationStatus);
            if(this.migrationStatus === RESULT.SUCCESSFUL){
                this.logger.debug("MIGRATION SUCCESSFUL");
                this.subscription.unsubscribe();
                this.modifyMigrationStatusDocData(result);
                if(!this.isMorrisonProject){
                    this.getSummary();
                }else{
                    this.showMigrationSummary =true;
                }
            }
            else if(this.migrationStatus !== RESULT.NOTAVAILABLE && this.migrationStatus !== RESULT.SUCCESSFUL){
                this.logger.debug("Migration failed");
                this.isMigrationStarted = false;
                this.subscription.unsubscribe();
                this.modifyMigrationStatusDocData(result);
                if(!this.isMorrisonProject){
                    this.getSummary();
                }else{
                    this.showMigrationSummary =true;
                }
            }
            else{
                this.logger.debug("Migration result not available yet");
            }
            this.cdRef.detectChanges();
            this.overlay.closeSpinner();
        });
    }

    modifyMigrationStatusDocData(data){
        let tempData = data;
        let startTime = moment(tempData.startTime.toDate())
        let endTime = moment(tempData.endTime.toDate())
        let Migrationduration = moment.duration(endTime.diff(startTime));
        tempData['duration'] = Math.round(Migrationduration.asMinutes()) === 0 ? 1 : Math.round(Migrationduration.asMinutes());
        tempData.startTime = tempData.startTime.toDate();
        tempData.endTime = tempData.endTime.toDate();
        this.migrationStatusDocData = tempData;
    }

    getSummary(){
        this.logger.debug("getSummary :",this.id);
        //this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.getExtensionMap(this.id)
        .then((extensionDoc:any)=>{
            this.extensionMap = JSON.parse(extensionDoc.data);
            return this.firestoreService.getCardSummary(this.id)
        }).then((result:any) => {
            this.showMigrationSummary =true;
            let summaryDoc = [];
            this.migrationSummaryData = [];
            result.forEach(doc => {
                if(doc.id === "summary"){
                    summaryDoc = doc;
                }
            });
            this.buildSummaryData(summaryDoc , result );
        }).catch((error) => {
            this.overlay.closeSpinner();
            this.logger.error("fetching card's migration summary failed :",this.id);
        })
    }

    /* migration status check flow 

    */
    doMigrationStatusCheck(){
        this.logger.debug("doMigrationStatusCheck");
        this.clearStatusCheckTimer();
        this.statusCheckTimer = setTimeout(() => { 
            this.handleStatusCheck();
        }, this.TIMER_INTERVAL);
    }

    buildSummaryData(summaryDoc , resultDocs){
        this.logger.debug("buildSummaryData :",this.id);
        this.migrationSummaryData = summaryDoc;
        resultDocs.forEach(doc => {
            if(doc.id === this.migrationSummaryData.lastRunDocId){
                this.migrationSummaryData['failure'] = doc.failure;
                this.migrationSummaryData['failureData'] = doc.failureData;
            }
        });
        if(this.migrationStatus !== RESULT.NOTAVAILABLE && this.migrationStatus !== RESULT.SUCCESSFUL
            && this.migrationStatus !== RESULT.TASK_ABORTED){
            this.buildFailureUserData();
        }

        this.migrationSummaryData['startTime'] = this.migrationSummaryData.started.toDate();
        if(typeof this.migrationSummaryData.retryStarted !== 'undefined' &&
            this.migrationSummaryData.retryStarted !== ''){
            this.migrationSummaryData['startTime'] = this.migrationSummaryData.retryStarted.toDate();
        }

        this.migrationSummaryData['endTime'] = this.migrationSummaryData.finished.toDate();
        if(typeof this.migrationSummaryData.retryFinished !== 'undefined' &&
            this.migrationSummaryData.retryFinished !== ''){
            this.migrationSummaryData['endTime'] = this.migrationSummaryData.retryFinished.toDate();
        }

        let startTime = moment(this.migrationSummaryData.startTime)
        let endTime = moment(this.migrationSummaryData.endTime)
        let Migrationduration = moment.duration(endTime.diff(startTime));
        this.migrationSummaryData['duration'] = Math.round(Migrationduration.asMinutes());
        this.getMigrationReportData();
        this.cdRef.detectChanges();
        this.overlay.closeSpinner();
        
    }

    buildFailureUserData(){
        if(this.migrationSummaryData.failureData !== undefined){
            this.logger.debug("buildFailureUserData :: failureData :",this.migrationSummaryData.failureData);
            this.migrationSummaryData.failureData = JSON.parse(this.migrationSummaryData.failureData);
           
            this.failureUserData = [];
            this.migrationSummaryData.failureData.forEach(userError => {
                if(userError.error !== undefined && userError.error.name === MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                    && (userError.error.data.httpStatusCode >= 400) 
                    && (userError.error.data.httpStatusCode != 429)
                    && (userError.error.data.httpStatusCode < 500)){
                    //put specific error message from error.data.message
                    this.failureUserData.push({
                        'extn': userError.extn,
                        "message":userError.error.data.message
                    })
                }else if(userError.errors !== undefined ){
                    let extn = "";
                    let matchedFound = this.extensionMap.find(x=>x.extId === parseInt(userError.extensionId));
                    if(matchedFound){
                        extn = matchedFound.extn;
                    }
                    this.failureUserData.push({
                        'extn': extn,
                        "message":userError.errors[0].message
                    })
                }else{
                    //put generic error - Connection Error
                    this.failureUserData.push({
                        'extn': userError.extn,
                        "message":MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC
                    })
                }
            });
        }
        this.overlay.closeSpinner();
    }

    ngOnDestroy() {
        this.logger.debug("ngOnDestroy");
        if(this.subscription !== undefined && this.subscription !== null){
            this.subscription.unsubscribe();
        }
        this.clearStatusCheckTimer();
    }

    retryMigration(){
        this.logger.debug("retryMigration")
        this.startMigration("retry");
    }

    close(){
        this.logger.debug("close")
        this.closeMigrationActivity();
    }

    closeErrorWarningBlock(){
        this.logger.info("closeErrorWarningBlock");
        this.showFailureData = false;
        this.cdRef.detectChanges();
    }

    viewFailureUserData(){
        this.logger.info("viewFailureUserData");
        if(this.failureUserData.length !== 0){
            this.showFailureData = true;
            this.cdRef.detectChanges();
        }else{
            this.logger.warn("Migration user failure data is empty")
        }
    }

    handleStatusCheck(toUpdate = false) {
        this.logger.debug("handleStatusCheck");
        if(this.isOnline && this.isMigrationStarted && 
            this.migrationStatus === RESULT.NOTAVAILABLE && !this.isTaskInQueue){

            if(toUpdate){
                this.logger.warn("Migration task elapsed estimated duration, marking as failed");
            }
            
            firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
                let passArgs = {
                    cardId: this.id,   
                    updateStatus: toUpdate,         
                    idToken
                };

                var statusCheck = firebase.functions().httpsCallable('migrationStatusCheck');
                statusCheck(passArgs).then((res: any) => {
                    // Read result of the Cloud Function.
                    this.logger.debug('Received result -' + JSON.stringify(res.data));          
                    this.isTaskInQueue = res.data.activeTask;
                    this.iterationCount++;
                    if(this.iterationCount == this.MAX_CHECK_ITERATION){
                        this.logger.warn("Max iteration check reached, update as failed");
                        this.clearStatusCheckTimer();
                        this.statusCheckTimer = setTimeout(() => { 
                            this.handleStatusCheck(true);
                        }, this.TIMER_INTERVAL);
                    }
                    else if(!toUpdate){
                            this.logger.debug("Status check iteration: ", this.iterationCount);
                            this.clearStatusCheckTimer();
                            this.statusCheckTimer = setTimeout(() => { 
                                this.handleStatusCheck();
                            }, this.TIMER_INTERVAL);
                    }
                    else{
                        this.logger.debug("Migration status marked as failed by audit"); 
                    }
                })
                .catch((error) => {
                    var message = error.message;
                    this.logger.error("migrationStatusCheck failed: ", message);
                });
            })
            .catch((error) => {
                this.logger.error("handleStatusCheck failed: ", error);
            });
        }
        else if(this.isOnline && this.isMigrationStarted && 
            this.migrationStatus === RESULT.NOTAVAILABLE && this.isTaskInQueue){
            this.logger.debug("Task in queue already, reset status check"); 
            this.iterationCount = 0;
            this.clearStatusCheckTimer();
            this.isTaskInQueue = false;
            this.statusCheckTimer = setTimeout(() => { 
                this.handleStatusCheck();
            }, this.TIMER_INTERVAL);
        }
        else{
            this.iterationCount = 0;
            this.isTaskInQueue = false;
            this.clearStatusCheckTimer();
            this.logger.debug("Migration status check complete already"); 
        }
    }

    loginToACOPortal(){
        this.logger.debug("loginToACOPortal");
        let acoPortalWindow =  window.open('https://service.cloudoffice.avaya.com/','_blank');
    }

    markAsComplete(){
        this.logger.debug("markAsComplete");
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.updateMarkAsComplete(this.id)
        .then((res) =>{
          //TODO:
          //update the status in ACOExport status , progress to 100%   OR
          //in startMigration page , check for markascomplete field and update progress directly 
          return this.firestoreService.updateMarkAsCompleteToAnalytics(this.id);
        }).then((res) =>{
            this.overlay.closeSpinner();
            this.preFetchAcoExportData();
        }).catch((error) =>{
          this.overlay.closeSpinner();
          this.logger.error("Upadating markascomplete failed: ",this.id)     
        })
    }

    timeupCallbackfunc(ol){
        //this.logger.debug("Time up");
        ol.showSpinner = false;
        ol.closeSpinner();
    }

    getMigrationReportData(){
        this.logger.debug("getMigrationReportData :", this.failureUserData);
        //this.failureUserData = [];
        let migrationReportData = "";
        /* if(this.migrationSummaryData.length !== 0){
            if(this.migrationSummaryData.failure !== 0 && this.failureUserData.length !== 0){
                this.failureUserData.forEach(item => {
                    migrationReportData = 'Extension : '+ item.extn+ ' Fail reason : '+item.message;
                    this.failureUserData.push(migrationReportData);
                });
            }
        } */

        this.firestoreService.getFeatureStatusDoc(this.id)
        .then((doc:any)=>{
            this.featureDocData = [];
            let tempFeatureDocData = [];
            tempFeatureDocData= JSON.parse(doc.data);
            tempFeatureDocData.forEach(feature => {
                let failedErrorData = [];
                switch(feature.status){
                    case RESULT.ABORTED:
                        feature['status'] = "Aborted";
                        break;
                    case RESULT.FAILURE:
                        feature['status'] = "Failed";
                        break;
                    case RESULT.SUCCESSFUL:
                        feature['status'] = "Success";
                        break;
                    case RESULT.NOTAPPLICABLE:
                        feature['status'] = "Not Applicable"
                        break;
                    default:
                        feature['status'] = "Not Available"
                }

                if(Array.isArray(feature.failedDesc) && feature.failedDesc.length !== 0){
                    feature.failedDesc.map((errorItem) =>{
                        let errorString;
                        if(errorItem.error.name === MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                            && (errorItem.error.data.httpStatusCode >= 400)
                            && (errorItem.error.data.httpStatusCode !== 429)
                            && (errorItem.error.data.httpStatusCode < 500)){
                                if(errorItem.extn){
                                    errorString = 'Extension : '+ errorItem.extn + ', Fail reason : ' + errorItem.error.data.message;
                                }else{
                                    let matchedFound = this.extensionMap.find(x=>x.extId === errorItem.extId);
                                    if(matchedFound){
                                        errorString = 'Extension : '+ matchedFound.extn + ', Fail reason : ' + errorItem.error.data.message;
                                    }else if(errorItem.name){
                                        errorString = errorItem.name +' - Fail reason : ' + errorItem.error.data.message;
                                    }else{
                                        errorString = 'Fail reason : ' + errorItem.error.data.message;
                                    }
                                }
                        }else{
                            if(errorItem.extn){
                                errorString ='Extension : '+ errorItem.extn + ', Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                            }else{
                                let matchedFound = this.extensionMap.find(x=>x.extId === errorItem.extId);
                                if(matchedFound){
                                    errorString ='Extension : '+ matchedFound.extn + ', Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }else if(errorItem.name){
                                    errorString = errorItem.name +' - Fail reason : ' + errorItem.error.data.message;
                                }else{
                                    errorString = 'Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                }
                            }
                        }
                        failedErrorData.push(errorString);
                    }) 
                }
                feature.failedDesc = failedErrorData;
                this.featureDocData.push(feature);
            });
            this.cdRef.detectChanges();
            this.overlay.closeSpinner();
        })
        .catch((error)=>{
            this.logger.error("error while fetching document from firestore :",error);
        });
    }

    triggerDownloadReport() {
        this.logger.debug("triggerDownloadReport");
        if(this.isMorrisonProject){
            this.downloadCSVFile();
        }else{
            this.generatePDF();
        }
    }

    generatePDF() {
        this.logger.debug("generatePDF");
        html2canvas(document.getElementById("migrationStatusReport"), {
            onclone: function (clonedDoc) {
            }
        }).then(canvas => {

            var doc = new jsPDF('p', 'mm', "a4");
            var imgData = canvas.toDataURL('image/png');
            let img = new Image();
            img.src = imgData;
            var pageHeight= doc.internal.pageSize.getHeight();
            var pageWidth= doc.internal.pageSize.getWidth();

            var imgheight = document.getElementById('migrationStatusReport').offsetHeight * 25.4 / 96; //px to mm
            var pagecount = Math.ceil(imgheight / pageHeight);

            doc.addImage(img, 'PNG', 5, 0, pageWidth-10, 0);

            //add extra pages if the div size is larger than a a4 size
            if (pagecount > 0) {
                var j = 1;
                while (j != pagecount) {
                    doc.setFontSize(10);
                    doc.text("Page "+(j), 185, 6);
                    doc.addPage();
                    doc.addImage(img, 'PNG', 5, -(j * pageHeight), pageWidth-10, 0);
                    j++;
                }
                doc.text("Page "+(j), 185, 6);
            }
            let filename = this.companyName+"_"+this.migrationStatusDocData.startTime+".pdf";
            doc.save(filename);
        }).catch(err => {
            this.logger.error("generatePDF failed",err);
        });
    }

    downloadReport(){
        this.logger.debug("downloadReport");
        let migrationReportData = "";
        if(this.migrationSummaryData.length !== 0){
            if(this.migrationSummaryData.failure !== 0 && this.failureUserData.length !== 0){
                this.failureUserData.forEach(item => {
                    migrationReportData = migrationReportData + 'Extension : '+ item.extn+ ' Fail reason : '+item.message+'\n';
                });
            }
        }

        this.firestoreService.getFeatureStatusDoc(this.id)
        .then((doc:any)=>{
            var featureDocData = JSON.parse(doc.data);
            if(Array.isArray(featureDocData) && featureDocData.length !== 0){
                featureDocData.map((feature) =>{
                    if(Array.isArray(feature.failedDesc) && feature.failedDesc.length !== 0){
                        feature.failedDesc.map((errorItem) =>{
                            let tempData;
                            if(errorItem.error.name === MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_ACOERROR 
                                && (errorItem.error.data.httpStatusCode >= 400)
                                && (errorItem.error.data.httpStatusCode !== 429)
                                && (errorItem.error.data.httpStatusCode < 500)){
                                    if(errorItem.extn){
                                        tempData = 'Extension : '+ errorItem.extn + ' Fail reason : ' + errorItem.error.data.message;
                                    }else{
                                        tempData = 'Extension : '+ errorItem.extId + ' Fail reason : ' + errorItem.error.data.message;
                                    }
                            }else{
                                if(errorItem.extn){
                                    if(errorItem.userAssignFailure){
                                        tempData ='Extension : '+ errorItem.extn + ' Fail reason : User Migration failed for this user';
                                    }else{
                                        tempData ='Extension : '+ errorItem.extn + ' Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                    }
                                }else{
                                    if(errorItem.userAssignFailure){
                                        tempData ='Extension : '+ errorItem.extId + ' Fail reason : User Migration failed for this user';
                                    }else{
                                        tempData ='Extension : '+ errorItem.extId + ' Fail reason : ' + MIGRATION_SUMMARY_STRINGS.FAILURE_USER_DATA_GENERIC;
                                    }
                                }
                            }
                        })  
                    }
                })   
            }
        }).catch((error)=>{
            this.logger.error("download report failed :",error);
        })
    }

    public async downloadCSVFile() {
        this.logger.info("downloadCSVFile");
        let csv :any= [];
        this.firestoreService.readDoc(this.id)
        .then((curdata:any) => {
            let row = [ "AccessCode", curdata.accesscode].join(",");
            csv += row + "\r\n";
            row = [ "Extension", "Status"].join(",");
            csv += row + "\r\n";
            return this.firestoreService.readUnconditionalForwardingResDataDoc(this.id);
        }).then(async (curdata:any) => {  
            
            //get extn list from db 
            if(curdata !== null){
                this.logger.debug("readUnconditionalForwardingResDataDoc success "+curdata.data);
                let unconditionalFowardingResData = JSON.parse(curdata.data);  
                if(unconditionalFowardingResData.length !== 0){
                    let csvData = await this.buildCSVContent(unconditionalFowardingResData);
                    csv += csvData;
                } 
            }
            return this.firestoreService.readTextToSpeechResDataDoc(this.id);  
        }).then(async (curdata:any) => {
            if(curdata !== null){
                this.logger.debug("readUnconditionalForwardingResDataDoc success "+curdata.data);
                let textToSpeechResData = JSON.parse(curdata.data);  
                if(textToSpeechResData.length !== 0){
                    csv += "HQUsers\r\n"
                    let csvData = await this.buildCSVContent(textToSpeechResData);
                    csv += csvData;
                }
            }
           
            const csvAsBlob = new Blob([csv], { type: 'text/csv' });
            const fileNameToSaveAs = this.companyName+'.csv';
            var url = window.URL.createObjectURL(csvAsBlob);
            saveAs(csvAsBlob, fileNameToSaveAs);
            this.overlay.closeSpinner();
        }).catch((err) => {
            this.logger.debug("getAssignedExtensionList failed: "+err);
            this.overlay.closeSpinner();
        });
      }

    async buildCSVContent(data){
        let csv:any = [];
        data.forEach(element => {
            let status = ""
            if(element.status === RESULT.SUCCESSFUL){
                status = "Success";
            }else{
                status = "Failed";
            }
            let row = [ element.extn, status].join(",");
            csv += row + "\r\n";
        });
        return csv;
    }
}


