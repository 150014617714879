export const environment = {
    production: false,
    firebaseConfig : {
      apiKey: "AIzaSyB38IWy5q2QAQqh_xhCPq-5ePbiTbK-W7E",
      authDomain: "aco-services-dev.firebaseapp.com",
      databaseURL: "https://aco-services-dev-default-rtdb.firebaseio.com/",
      projectId: "aco-services-dev",
      storageBucket: "aco-services-dev.appspot.com",
      messagingSenderId: "391760228851",
      appId: "1:391760228851:web:a38b7bb84c9826d53da286",
      measurementId: "G-6ERJKWYNVH"
    },
    client_id : "391760228851-rc0isfs1a9spqrg04ilrtiik9cp7lot4.apps.googleusercontent.com" ,
    cookie_policy: 'single_host_origin',
    scope: 'profile email',
    well_known_endpoint: 'https://storage.googleapis.com/aco-services-dev.appspot.com/.well-known/mt',
    bucket_name: 'aco-services-dev.appspot.com',
    avaya_domain: 'avaya.com',
    app_version: '0.6.4.6'
};