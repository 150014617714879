import { Component, OnInit, NgZone } from '@angular/core';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { TablePreviewService, AA_ERROR_MSG } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { GREETINGS_TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';

const TIMER_LIMIT=100000;
const ZINDEX=1000;
@Component({
  selector: 'app-system-data',
  templateUrl: './system-data.component.html',
  styleUrls: ['./system-data.component.css']
})
export class SystemDataComponent implements OnInit {
  id:any;
  public greetingsTriggerStatus = GREETINGS_TRIGGER_STATUS;
  numberOfHuntGroups:number=0;
  numberOfPagingGroups:number=0;
  numberOfAutoAttendants:number=0;
  numberOfTimeProfiles:number=0;
  numberofIncomingNumbers:number=0;
  isDataReady:boolean=false;
  isCompanyNumberAssigned:boolean= false;
  isGreetingsFileUploaded=GREETINGS_TRIGGER_STATUS.NOTYET;
  isMixedTPFound:boolean;
  isAAWarnFound:boolean;
  isPGWarnFound:boolean;
  isHGWarnFound:boolean;
  subscription:Subscription;
  greetingsSubscription:Subscription;
  aaToolTipText:string;
  wait:any;
  zIdx=ZINDEX;
  

  constructor(private tablePreviewService:TablePreviewService,
    private router: Router,public zone: NgZone,
    private session:SessionHandlerService,
    private spinner:NgxSpinnerService, private logger:LogService) {
      this.logger.debug("System Data component constructor");
      this.id = this.session.cardId;
      this.isDataReady = false;
      this.isCompanyNumberAssigned = false;
      this.spinner.show();
      this.greetingsSubscription = this.tablePreviewService.getGreetingsDocStatus().subscribe((status)=>{
        if(status ==  GREETINGS_TRIGGER_STATUS.START){          
          this.wait = setTimeout(()=>{
            clearTimeout(this.wait);
            this.isGreetingsFileUploaded = GREETINGS_TRIGGER_STATUS.UNKNOWN;
          },TIMER_LIMIT)
        }else if(status ==  GREETINGS_TRIGGER_STATUS.DONE) {
          clearTimeout(this.wait); 
          this.updateFeatureDetails();
        }
        this.isGreetingsFileUploaded = status;
      })      
     }

  ngOnInit(): void {
    this.subscription = this.tablePreviewService.getTableDataInitializedObs().subscribe((res)=>{
      this.isDataReady = res;
      this.numberOfHuntGroups = this.tablePreviewService.tableHuntGroupList.filter(el=>!el.isPagingEnabled).length;
      this.numberOfPagingGroups = this.tablePreviewService.tableHuntGroupList.length- this.numberOfHuntGroups;
      this.numberOfTimeProfiles = this.tablePreviewService.tableTimeProfileList.length;
      this.isMixedTPFound = this.tablePreviewService.timeProfilesWarningsList.length>0?true:false;            
      this.tablePreviewService.isTableUpdated(this.id);
      this.isCompanyNumberAssigned = this.tablePreviewService.isCompanyNumberUpdated;
      this.updateFeatureDetails();
    }) 
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.greetingsSubscription.unsubscribe();
    this.spinner.hide();
    clearTimeout(this.wait);
  }

  showHuntGroups(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/hunt-group-info']); });
  }

  showAutoAttendants(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/auto-attendants-info']); });
  }
 
  showTimeProfiles(){
    if(this.isDataReady)
        this.zone.run(() => { this.router.navigate(['/home/migration/time-profiles-info']); });
  }
  
  showIncomingNumbers(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/incoming-numbers-info']); });
  }
  getAutoAttendantToolTipText(){
    var toolTipwarnList=[];
    let aaWarnings = this.tablePreviewService.autoAttendantsWarningsList;
    if(aaWarnings.find(entry=>entry.includes(AA_ERROR_MSG.UNMATCHED_KEY_ACTIONS)) != undefined){
      toolTipwarnList.push(AA_ERROR_MSG.UNMATCHED_KEY_ACTIONS);
    }
    if(aaWarnings.find(entry=>entry.includes(AA_ERROR_MSG.PROMPTS_MISSING))!= undefined) {
      toolTipwarnList.push(AA_ERROR_MSG.PROMPTS_MISSING);
    }    
    return toolTipwarnList.join('\n');
  }


  updateFeatureDetails(){
    this.numberOfAutoAttendants = this.tablePreviewService.tableAutoAttendantList.length;
    this.numberofIncomingNumbers = this.tablePreviewService.incomingNumbersCount;
    this.isAAWarnFound = this.tablePreviewService.autoAttendantsWarningsList.length>0?true:false;
    this.aaToolTipText = this.getAutoAttendantToolTipText();
    this.isHGWarnFound = this.tablePreviewService.huntGroupWarningsList.length>0?true:false;
    this.isPGWarnFound = this.tablePreviewService.pagingGroupsWarningsList.length>0?true:false;
  }
}
