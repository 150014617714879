import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { LogService } from '../logging/log.service';

@Injectable({
  providedIn: 'root'
})
export class MigrationDataService {

    private customerCompanyName:string;
    private csvFileName:string;

    //Upload csv component dependent properties
    private ipoSummaryData:any;

    private phoneTypeData:any;
    private acoSummaryData:any;

    //TablePreview component dependent properties
    private tablePreviewData:any;
    private acoImportData:any;

    //Upload csv, TablePreview component dependent properties
    private ipoUserData:any;
    private ipoCsvData:any;
    private ipoTimeProfile:any;
    private ipoHuntGroup:any;
    private autoAttendant:any
    private incomingCallRoute:any;
   // private pagingGroup: any;   
    private shortCodes: any;
    private steeringCodes: any;
    private morrisonData:any ;

    constructor(private firestoreService: FirestoreService,
        private logger:LogService) {
        this.logger.info("MigrationDataService constructor"); 
    }

    set ipoCSVFileName(data: string) {
        this.csvFileName = data;
    }

    get ipoCSVFileName(): string {
        return this.csvFileName;
    }

    get IPOUserData(): any {
        return this.ipoUserData;
    }

    set IPOUserData(data: any) {
        this.ipoUserData = data;
    }

    set IPOTimeProfile(data: any){
        this.ipoTimeProfile = data;
    }

    get IPOTimeProfile(): any{
        return this.ipoTimeProfile;
    }

    set IPOHuntGroup(data: any){
        this.ipoHuntGroup = data;
    }

    get IPOHuntGroup(): any{
        return this.ipoHuntGroup;
    }

    set AutoAttendant(data: any){
        this.autoAttendant = data;
    }

    get AutoAttendant(): any {
        return this.autoAttendant;
    }

    set IncomingCallRoute(data: any){
        this.incomingCallRoute = data;
    }

    get IncomingCallRoute():any {
        return this.incomingCallRoute;
    }

    // get PagingGroup(): any {
    //     return this.pagingGroup;
    // }

    // set PagingGroup(value: any) {
    //     this.pagingGroup = value;
    // }

    get ShortCodes(): any {
        return this.shortCodes;
    }

    set ShortCodes(value: any) {
        this.shortCodes = value;
    }

    get SteeringCodes(): any {
        return this.steeringCodes;
    }

    set SteeringCodes(value: any) {
        this.steeringCodes = value;
    }

    get Morrison(): any {
        return this.morrisonData;
    }

    set Morrison(value: any) {
        this.morrisonData = value;
    }


    get IPOCsvData(): any {
        return this.ipoCsvData;
    }

    set IPOCsvData(data: any) {
        this.ipoCsvData = data;
    }

    get ACOImportData(): any{
        return this.acoImportData;
    }

    set ACOImportData(data: any){
        this.acoImportData = data;
    }

    get TablePreviewData(): any{
        return this.tablePreviewData;
    }

    set TablePreviewData(data: any){
        this.tablePreviewData = data;
    }

    get IPOSummaryData(): any {
        return this.ipoSummaryData;
    }

    set IPOSummaryData(data: any) {
        this.ipoSummaryData = data;
    }

    get ACOSummaryData(): any {
        return this.acoSummaryData;
    }

    set ACOSummaryData(acoSummaryData: any) {
        this.acoSummaryData = acoSummaryData;
    }

    get getCustomerCompanyName(): string {
        return this.customerCompanyName;
    }

    set setCustomerCompanyName(customerCompanyName: string){
        this.customerCompanyName = customerCompanyName;
    }   

    public readIPOData(cardId): any {
        var promise = new Promise((resolve, reject) => {
            if(this.ipoCsvData === undefined || this.ipoCsvData === null){
                this.logger.debug("getIpoUserData from DB")
                this.firestoreService.readIPOCfgData(cardId)
                .then((result:any) => {
                    this.ipoCsvData = JSON.parse(result);
                    this.ipoUserData = this.ipoCsvData['IPOUsers'];
                    resolve();
                })
                .catch(error => reject(error));
            }
            resolve();
        });

        return promise;
    }
   
    public readACOImportData(cardId): any {
      
        var promise = new Promise((resolve, reject) => {
            if(this.acoImportData === undefined || this.acoImportData === null){
                this.logger.debug("readACOImportData from DB")
                this.firestoreService.getAcoImportData(cardId)
                .then((result:any) => {
                    resolve(result);
                })
                .catch(error => reject(error));
            }
            resolve();
        });

        return promise;
    }

    public readTablePreviewData(cardId): any {
        var promise = new Promise((resolve, reject) => {
            if(this.tablePreviewData === undefined || this.tablePreviewData === null){
                this.logger.debug("readTablePreviewData from DB")
                this.firestoreService.readTableData(cardId)
                .then((result:any) => {
                    resolve(result);
                })
                .catch(error => reject(error));
            }
            reject("Table data unavailable from DB");
        });
        
        return promise;
    }

    public getPhoneTypeData(): any {
        return this.phoneTypeData;
    }

    public setPhoneTypeData(phoneTypeData: any): void {
        this.phoneTypeData = phoneTypeData;
    } 
    

    public clearData(){
        //reset all local variables to null upon triggering of migration / aborting the migration process
        this.customerCompanyName = null;
        this.csvFileName = null;
        this.ipoSummaryData = null;
        this.phoneTypeData = null;
        this.acoSummaryData = null;
        this.tablePreviewData = null;
        this.acoImportData = null;
        this.ipoUserData = null;
        this.ipoCsvData = null;
        this.ipoTimeProfile = null;
        this.ipoHuntGroup = null;
        this.autoAttendant = null;    
        this.incomingCallRoute = null;
      //  this.pagingGroup = null;
        this.shortCodes = null;
        this.steeringCodes = null;
        this.morrisonData = null;
    }   

}
