<div class = "tabInfo">
        <p class = "intro">
            Shows all the user data that are going to be migrated. Use Edit option to prepare migration data and View option to view migration data.
        </p>
        <div style="display:flex;flex-direction:column;margin-right:-15%;">
            <div style="margin-top:3%;">
                <span style="display:flex;">
                    <mat-label class="labelField"  style="margin-right:54%;">Basic information :</mat-label>
                    <img class="editBtnImg" [style]="{'opacity':isDataReady?'1':'0.5'}" src="../../../../../assets/images/config-summary/Icon_Edit_data.svg" matTooltip="Click to view and edit the user data"
                     (click)="showBasicUserData()">
                    <img *ngIf = "isDataReady &&  isReadyToMigrate" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                    <img  *ngIf = "isDataReady && !isReadyToMigrate" src="../../../../../assets/images/config-summary/Icon_user_migration_data_missing.svg" matTooltip="Missing mandatory data">
                    <div style="position:relative;margin-left:2%" *ngIf="!isDataReady">
                        <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                          <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                        </ngx-spinner>
                    </div>
                </span>
                <p class="descrLabel">(First name, Last name, Email, Phone Model, DID)</p>
                <mat-divider class="hozDivider"></mat-divider>
            </div>
    
            <div style="margin-top:2%;">
                <span style="display:flex;">
                    <mat-label class="labelField" style="margin-right:47%;">Advanced information :</mat-label>
                    <img class="viewBtnImg" [style]="{'opacity':isDataReady && isGreetingsFileUploaded != greetingsTriggerStatus.START?'1':'0.5'}" (click)="showAdvancedUserData()" src="../../../../../assets/images/config-summary/Icon_View_data.svg" matTooltip="Click to view the user data">
                    <img *ngIf="isDataReady && !isAdvUserInfoWarnFound && isGreetingsFileUploaded != greetingsTriggerStatus.START" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                    <img *ngIf="isDataReady && isAdvUserInfoWarnFound && isGreetingsFileUploaded != greetingsTriggerStatus.START" style="vertical-align:top" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_with_warning.svg" matTooltip="{{advInfoToolTipText}}" [matTooltipClass]="'multiLine'" >
                    <div style="position:relative;margin-left:2%" *ngIf="!isDataReady || isGreetingsFileUploaded == greetingsTriggerStatus.START">
                        <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                          <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                        </ngx-spinner>
                    </div>
                </span>
                <p class="descrLabel">(Timezone, Personal Contacts, BLF, Voicemail greetings, Twinning number)</p>
                <mat-divider class="hozDivider"></mat-divider>
            </div>
        </div>
</div>
