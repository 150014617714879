import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { LogService } from '../logging/log.service';

@Injectable({
  providedIn: 'root'
})
export class SessionHandlerService {

    readonly MIGRATIONCARD:string = 'activeMigrationId';
    readonly MIGRATIONCOMPANYNAME:string = 'migrationCompanyName';
    readonly DEFAULT_PROMPT_MSG:string = 'You are about to close the migration card.'
    private _cardId: string;
    private _companyName:string;

    public get cardId(): string{
        if(this._cardId == undefined || this._cardId == null){
            this._cardId = sessionStorage.getItem(this.MIGRATIONCARD);
        }
        
        return this._cardId;
    }

    public set cardId(cardId: string) {
        this.logger.info("Active cardId: ", cardId);
        sessionStorage.setItem(this.MIGRATIONCARD, cardId);
        this._cardId = cardId;
    }

    public get companyName(): string{
        if(this._companyName == undefined || this._companyName == null){
            this._companyName = sessionStorage.getItem(this.MIGRATIONCOMPANYNAME);
        }
        
        return this._companyName;
    }

    public set companyName(company: string) {
        this.logger.info("active migration company name: ", company);
        sessionStorage.setItem(this.MIGRATIONCOMPANYNAME, company);
        this._companyName = company;
    }
    
    constructor(private logger:LogService, public dialog: MatDialog) { }

    public alertModal(callbackFn:Function, msg = this.DEFAULT_PROMPT_MSG, okBtnTxt = 'Ok', cancelBtnTxt = 'Cancel'){

        const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
            data:{
                message: msg,
                buttonText: {
                ok: okBtnTxt,
                cancel: cancelBtnTxt
                }
            }
            });
        
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if(confirmed){
                callbackFn();
            }
        });
    }
}
