import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AdminTableDisplayService {

  constructor() { }

      printApproveButton(cell, formatterParams, onRendered){ 
          var data = cell.getRow().getData();
          return  '<button name="approveButton" class="actionButton" style="min-width:5.5vw;justify-content:center">\
                        <span class="material-icons"   style="color:green;font-size:initial">check</span>\
                        <span class="buttonTitle">Approve</span>\
                </button>';
        };
      printRejectButton(cell, formatterParams, onRendered){ 
            var data = cell.getRow().getData();
            return  '<button name="rejectButton" class="actionButton" style="margin-left:10%;padding-right:5%">\
                          <span class="material-icons" style="color:red;font-size:initial">close</span>'+ 
                        '<span class="buttonTitle">Reject</span>\
                  </button>';
        };

      printUserDetails(cell, formatterParams , onRendered){
        var data = cell.getRow().getData();
        var displayStyle = "inline-flex";
        if(data.company == null || data.company.trim() === "")
          displayStyle = "none";
        return  "<div class='userInfoBlock'> <span class='tableUserName'>"
                +data.name+"</span><span class='tableUserEmail'>"
              +data.email+"</span><span class='tableUserCompany' style='display:"+displayStyle+";'>, "+data.company+"</span>";
      }
      printImg(cell, formatterParams , onRendered){
          var data = cell.getRow().getData();
          cell.getElement().style.margin = "-10%"; 
          let defaultImg = "../../../../../assets/default-google-user-img.png";
          return  '<img src= '+data.photoUrl+'  onerror="if (this.src != \''+defaultImg+'\') this.src = \''+defaultImg+'\'" class="tableAccountImage">';
      }

      
}
