import { Component, OnInit } from '@angular/core';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { FileUploadService } from 'src/app/shared/services/file-upload/file-upload.service';
import { Subscription } from 'rxjs';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { firestore } from 'firebase';
import { FirestoreService, GREETINGS_TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';
import { timingSafeEqual } from 'crypto';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';

const LAST_STATUS = {
      UNKNOWN: 'unknown',
      NOTSTARTED: 'notstarted',
      STARTED: 'started',
      INPROGRESS: 'inprogress',
      COMPLETED: 'completed',
      FAILURE: 'failure',
      ABORTED: 'aborted'
  };


const MAX_FILE_SIZE = 2147483648;//2 GB

const ALLOWED_FILE_EXTN_LIST = [ ".zip",".tar.gz"];

@Component({
  selector: 'app-upload-greetings-file',
  templateUrl: './upload-greetings-file.component.html',
  styleUrls: ['./upload-greetings-file.component.css']
})

export class UploadGreetingsFileComponent implements OnInit {

  greetingsfilename:string;
  greetingsFile:any;
  lastUploadedFileName:string;
  lastUploadedFileSize:string;
  greetingsFileSize:number;
  uploadProgress:number;
  errorMsg:string;
  subscription:Subscription;
  public status = LAST_STATUS;
  fileUploadStatus = LAST_STATUS.NOTSTARTED;
  id:any;
  private TIMER_INTERVAL = 30000;
  public allowedFileExtns = ALLOWED_FILE_EXTN_LIST;
  fileExtnList=ALLOWED_FILE_EXTN_LIST.map(e=>e.slice(1)).join(' or ');
  
  constructor(private logger:LogService,private uploader:FileUploadService,
    private session:SessionHandlerService, private firestoreService:FirestoreService,
    private spinner:OverlayService) {
      this.fileUploadStatus = LAST_STATUS.NOTSTARTED;
      this.id = this.session.cardId;
      this.spinner.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
      this.firestoreService.getGreetingsFileDetails(this.id).then((data:any)=>{
        this.lastUploadedFileName = "--";
        this.lastUploadedFileSize = "";
        if(data){
          this.logger.debug("data: "+ JSON.stringify(data));
          let size = data.size;
          if(size && size != 0){
            this.lastUploadedFileName = data.name ;
            this.lastUploadedFileSize =  " (" + this.formatFileSize(size,2)+")" ;
          }else{
            this.lastUploadedFileName = data.name ;
          }
          this.spinner.closeSpinner();
        }else{
          this.spinner.closeSpinner();
        }
      }).catch(err=>{
        this.logger.error("Error while getting file name:"+ err);
        this.spinner.closeSpinner();
      });
      this.firestoreService.getGreetingsDataAvlblMsg().subscribe((data)=>{
        if(data.trigger == GREETINGS_TRIGGER_STATUS.DONE || data.trigger === GREETINGS_TRIGGER_STATUS.START){
         this.updateLastUploadedFileName(data);
        }
      })
     }

  updateLastUploadedFileName(data){      
    if(data !== undefined || data !== null){
      try{
        var zipFileName = data.zipFilePathName , fileSize = data.zipFileSize;
        if( zipFileName !== undefined && zipFileName !== null && zipFileName !== "" && this.lastUploadedFileName !== zipFileName)
        {
          this.lastUploadedFileName = zipFileName;
          this.lastUploadedFileSize = fileSize;
        }
      }catch(error){
        this.logger.error("update last updated file name: Error while parsing greetings data: "+error);
      }
    }
    this.logger.debug("update last uploaded file name: "+this.lastUploadedFileName+"\tsize: "+this.lastUploadedFileSize);
  }

  ngOnInit(): void {
    this.subscription = this.uploader.getProgress().subscribe(
      (progress) => {
        if(progress)
          this.fileUploadStatus = LAST_STATUS.INPROGRESS;
        this.uploadProgress = progress;
        if(progress == 100)
        {
          if(this.greetingsFile !== undefined && this.greetingsFile.name !== undefined && this.greetingsFile.name !== ""){
              this.lastUploadedFileName = this.greetingsfilename;
              this.lastUploadedFileSize = " ("+this.formatFileSize(this.greetingsFile.size,2)+")";
          }
          this.logger.debug("File upload completed: "+ this.lastUploadedFileName + "\tsize: "+this.lastUploadedFileSize);
          setTimeout(()=>{
            this.greetingsfilename = null;
            this.fileUploadStatus = LAST_STATUS.COMPLETED;
          }, 500);
        }        
      },
      (error)=>{
        this.logger.error("Error while uploading file: "+ error);
        this.handleUploadFailure(error,LAST_STATUS.FAILURE);        
      }/*,
      ()=>{
        console.log("File upload completed");
        this.isFileUploading = false;
        this.uploadProgress = 0;
        this.subscription.unsubscribe();
       }*/
      );
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  private formatFileSize = (bytes,decimalPoint)=> {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }

  onGreetingsFileChanged(event){
    this.fileUploadStatus = LAST_STATUS.NOTSTARTED;
    this.logger.debug("onFileChanged: " + JSON.stringify(event));
    this.greetingsFile = event.target.files[0];
    this.greetingsfilename = this.greetingsFile.name;
    this.logger.debug("The file selected: ", this.greetingsfilename);
    let ext = ALLOWED_FILE_EXTN_LIST.find(entry=> this.greetingsfilename.endsWith(entry));
    if(ext !== undefined){
      let size = this.greetingsFile.size;
      if(size === 0){
        this.handleUploadFailure("File is empty",LAST_STATUS.ABORTED);
      }else if( size < MAX_FILE_SIZE){
        this.uploadProgress = 0;
        this.fileUploadStatus = LAST_STATUS.STARTED;
        this.uploader.upload(this.greetingsFile,this.id,this.greetingsfilename,size);
      }else{
        this.handleUploadFailure("File size exceeds the maximum file size limit.",LAST_STATUS.ABORTED);
      }
            
    }else{
      let extnListLen = ALLOWED_FILE_EXTN_LIST.length;
      if(extnListLen>0){
        let error = " File should be in ";
        if(extnListLen > 1 ){
          error += ALLOWED_FILE_EXTN_LIST.slice(0,-1).map(
            el=>
            {
              return "\'"+el+"\'";
            }
            ).join(" / ") + " / ";
        }
        error += "\'"+ ALLOWED_FILE_EXTN_LIST[extnListLen-1] + "\'" + " format only"
        this.handleUploadFailure(error,LAST_STATUS.ABORTED);
      }
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  private handleUploadFailure(err,status):void{
    this.fileUploadStatus = status;
    this.errorMsg = err;
  }

}
