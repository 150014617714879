<div class="contentBlock">
    <div class= "accountInfoBlock">
        <div class="accountImageBlock" >
            <img [src]=user_image  class="accountImage" >
        </div>
        <div class="profileInfo parent-flex">
            <mat-form-field class="example-form-field" >
                <mat-label>User Name</mat-label>
                <input matInput type="text" value={{user_name}} readonly>
                <button mat-button  matSuffix mat-icon-button  >
                    <mat-icon>account_circle</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field class="example-form-field">
                <mat-label>User Email</mat-label>
                <input matInput type="text" value={{user_email}} readonly>
                <button mat-button  matSuffix mat-icon-button >
                    <mat-icon>email</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field class="example-form-field">
                <mat-label>Company Name</mat-label>
                <input matInput type="text" [(ngModel)]="user_company" required [readonly]="requestSent">
                <button mat-button matSuffix mat-icon-button >
                    <mat-icon>weekend</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    
    <div class="errorBlock" *ngIf="!isAuthorized">
        <p style="color: #DA291C;text-align: center;margin:0px;">
            <mat-icon style="font-size: 1vw;color: #DA291C;">
                lock
            </mat-icon>
            {{userDisplayMsg}} 
            <a style="text-decoration: underline;cursor: pointer;" (click) = "requestUserPermission()" *ngIf= "requestAccessError">{{requestAccessError}}</a>
        </p>
    </div>
    <div class="navigation" *ngIf = "isAuthorized">
        <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateNext()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>

