import { GSignInService } from './../../services/googleAPI/g-sign-in.service';
import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { FireAuthService } from '../../services/firebaseAuth/fire-auth.service';
import { FirestoreService ,STATUS, MAILTYPE } from '../../services/firestore/firestore.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OverlayService } from '../../services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from '../mat-spinner-overlay/mat-spinner-overlay.component';

const ERRORMSG = {
  NO_ACCESS :"Access to Migration Tool has been restricted," ,
  REQUEST_ACCESS : "Click here for requesting access" ,
  REQUEST_SENT: "Thank you for requesting access to Migration Tool. On approval, you will be receiving email",
  CONTACT_ADMIN : " Please Contact Adminstrator"
 }


@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent implements OnInit {
  user_name:string;
  user_email:string;
  user_company:string;
  user_image:string;
  UserProfileInfo : any;
  isAuthorized = false;
  requestAccessError:string;
  userDisplayMsg:String;
  userDetails:any;
  userProfieDetails:any;
  requestSent = false;
  authStateChange: Subscription;

  private TIMER_INTERVAL = 30000;

  constructor(private googleService:GSignInService ,  private fireauthService:FireAuthService,
    private firestoreService:FirestoreService , private router: Router , public zone: NgZone ,
    private cdRef: ChangeDetectorRef, private logger:LogService, private overlay:OverlayService ) { 
    this.logger.info("ProfileInfoComponent constructor");
    this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
      this.authStateChange.unsubscribe();
      if(data !== null){
        this.fetchUserDetails();
      }else{
        this.zone.run(() => { this.router.navigate(['/']); });
      }
    });
    this.fireauthService.authStateChangeListener();
    
  }

  ngOnInit(): void {
  }

  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  fetchUserDetails(){
    this.logger.debug("fetchUserDetails");
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.firestoreService.getUserDetails()
    .then((userDetails:any) => {
      this.userDetails = userDetails;
      this.user_name = userDetails.displayName;
      this.user_email = userDetails.email;
      if(userDetails.authorized == 1){
        this.isAuthorized = true;
      }
      return this.firestoreService.getUserProfileDetails();
    }).then((doc:any) => {
      this.zone.run(() => {
        this.logger.debug(doc);
        this.userProfieDetails = doc;
        this.user_company = doc.company;
        this.user_image = doc.photoUrl;
        this.logger.debug("user has request access set to :" ,doc.requestAccess);
        if(doc.requestAccess == 0){
          if(!this.isAuthorized){
            this.getCompanyNameFromDomain();
          } 
          this.userDisplayMsg = ERRORMSG.NO_ACCESS;
          this.requestAccessError = ERRORMSG.REQUEST_ACCESS;
          this.requestSent = false;
        }else if(doc.requestAccess == 1){
          this.requestAccessError = "";
          this.userDisplayMsg = ERRORMSG.REQUEST_SENT;
          this.requestSent = true;
        }else{
          this.userDisplayMsg = ERRORMSG.NO_ACCESS + ERRORMSG.CONTACT_ADMIN;
          this.requestAccessError = "";
          this.requestSent = true;
        }
        this.overlay.closeSpinner();
      })
      
    }).catch((error) => {
      this.logger.error("firebase User is Null")
      this.overlay.closeSpinner();
    });   
  }

  getCompanyNameFromDomain(){
    if(this.user_email !== null && this.user_email !== undefined){
      let tempArray = this.user_email.split("@");
      if(tempArray.length !== 0){
          let emailDomain = tempArray[tempArray.length-1].split(".");
          if(emailDomain.length !== 0){
            this.user_company = emailDomain[0];
          }
      }
    }
  }

  navigateNext(){
    this.zone.run(() => {this.router.navigate(['/home/migration']); });
  }

  requestUserPermission(){
    this.logger.debug("requestUserPermission");
    if(this.user_company != null && this.user_company != undefined && this.user_company != ""){
      this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
      this.firestoreService.requestUserPermission(this.user_company)
      .then(() => {
        let userData = [];
        userData['name'] = this.user_name;
        userData['email'] = this.user_email;
        this.requestSent = true;
        return this.firestoreService.triggerEmailNotification(MAILTYPE.NOTIFY_REQUEST , userData);
      }).then((res) => {
        this.logger.debug("requestUserPermission:: User Request Email Sent");
        this.requestAccessError = "";
        this.userDisplayMsg = ERRORMSG.REQUEST_SENT;
        this.cdRef.detectChanges();
        this.overlay.closeSpinner();
      }).catch((error) => {
        this.logger.error("Requesting for access failed: ",error)
        this.overlay.closeSpinner();
      });
    }else{
        this.logger.error("Company name is empty")
    }
  }

}
