import { AboutComponent } from './shared/components/about/about.component';
import { DashboardComponent } from './shared/components/dashboard/dashboard.component';
import { StartMigrationComponent } from './user/components/start-migration/start-migration.component';

import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ProfileInfoComponent } from './shared/components/profile-info/profile-info.component';
import { MigrationCardsComponent } from './user/components/migration-cards/migration-cards.component';
import { CustomerCompanyInfoComponent } from './user/components/customer-company-info/customer-company-info.component';
import { UploadCSVComponent } from './user/components/upload-csv/upload-csv.component';
import { RCAuthComponent } from './user/components/rcauth/rcauth.component';
import { MigrationSummaryComponent } from './user/components/migration-summary/migration-summary.component';
import { MigrationPreviewComponent } from './user/components/migration-preview/migration-preview.component';
import { HomeComponent } from './shared/components/home/home.component';
import { TablePreviewComponent } from './user/components/table-preview/table-preview.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { MembersHomeComponent } from './admin/components/members/members-home/members-home.component';
import { AdminHomeComponent } from './admin/components/admin-home/admin-home.component';
import { NewRequestsComponent } from './admin/components/members/new-requests/new-requests.component';
import { ApprovedRequestsComponent } from './admin/components/members/approved-requests/approved-requests.component';
import { RejectedRequestsComponent } from './admin/components/members/rejected-requests/rejected-requests.component';
import { UploadGreetingsFileComponent } from './user/components/config-summary/upload-greetings-file/upload-greetings-file.component';
import { ConfigSummaryComponent } from './user/components/config-summary/config-summary/config-summary.component';
import { SystemDataComponent } from './user/components/config-summary/system-data/system-data.component';
import { UserDataComponent } from './user/components/config-summary/user-data/user-data.component';
import { AdvancedInfoTablePreviewComponent } from './user/components/advanced-info-table-preview/advanced-info-table-preview.component';
import { HuntGroupsPreviewComponent } from './user/components/hunt-groups-preview/hunt-groups-preview.component';
//import { PagingGroupsPreviewComponent } from './user/components/paging-groups-preview/paging-groups-preview.component';
import { TimeProfilesPreviewComponent } from './user/components/time-profiles-preview/time-profiles-preview.component';
import { AutoAttendantsPreviewComponent } from './user/components/auto-attendants-preview/auto-attendants-preview.component';
import { IncomingNumbersPreviewComponent } from './user/components/incoming-numbers-preview/incoming-numbers-preview.component';
import { CanDeactivateGuardService } from './shared/services/deactivate-guard/can-deactivate-guard.service';
import { MorrisonDataComponentComponent } from './user/components/morrison-data-component/morrison-data-component.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'admin', component: AdminHomeComponent, children: [
      { path: 'members', component:  MembersHomeComponent, children: [
              { path: 'newrequests', component: NewRequestsComponent },
              { path: 'approved', component: ApprovedRequestsComponent },
              { path: 'rejected', component: RejectedRequestsComponent }
      ] }
  ]},
  { path: 'home', component: HomeComponent, children: [
    { path: 'profile', component: ProfileInfoComponent },
    { path: 'about', component: AboutComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'migration', children: [
      { path: '', component: MigrationCardsComponent},
      { path: 'company-info', component: CustomerCompanyInfoComponent },
      { path: 'upload-csv', component: UploadCSVComponent },
      { path: 'summary', component: MigrationSummaryComponent },
      { path: 'preview', component: MigrationPreviewComponent },
      { path: 'rcauth', component: RCAuthComponent },
      { path: 'basic-info', component: TablePreviewComponent, canDeactivate: [CanDeactivateGuardService] },
      { path: 'advanced-info', component: AdvancedInfoTablePreviewComponent },
      { path: 'hunt-group-info', component: HuntGroupsPreviewComponent ,canDeactivate: [CanDeactivateGuardService] },
    //  { path: 'paging-group-info', component: PagingGroupsPreviewComponent },
      { path: 'auto-attendants-info', component: AutoAttendantsPreviewComponent },
      { path: 'incoming-numbers-info', component: IncomingNumbersPreviewComponent,canDeactivate: [CanDeactivateGuardService] },
      { path: 'time-profiles-info', component:TimeProfilesPreviewComponent },
      { path: 'startMigration', component: StartMigrationComponent},
      { path: 'config-summary', component: ConfigSummaryComponent, children: [
        { path: '', component: UploadGreetingsFileComponent },
        { path: 'upload-greetings-files', component: UploadGreetingsFileComponent },        
        { path: 'system-data', component: SystemDataComponent },
        { path: 'user-data', component: UserDataComponent }
      ]},
      { path: 'morrison-summary' , component: MorrisonDataComponentComponent }
    ]}
  ]}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
