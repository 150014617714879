<div class="contentBlock faqDataBlock">
        <div class = "errorDisplayDiv" *ngIf="isFaqListEmpty">
                {{userDisplayMsg}}
        </div>
        <div class="faqDisplayDiv" *ngIf="!isFaqListEmpty">
            <!-- <div>
                <button id="toggleBtn" (click)="toggleExpansion()">Expand All</button>
            </div>  -->
            <mat-accordion multi  class="faqListBlock">
                <mat-expansion-panel *ngFor = 'let faq of faqList'>
                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            {{faq.question}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-label innerHTML={{faq.answer}}></mat-label>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
</div>