<div class="contentBlock migrationDataBlock">
    <div id="contentInfo" class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
    </div>
    <div class="contentMain">  
        <div class="tabulatorSettings" > 
            <div style="width: 50%;display: flex;height: 100%;">

                <mat-form-field class="searchFormField"
                    [ngClass]="showErrorWarningBlock ? 'searchHide' : 'searchShow'">

                    <button mat-button matPrefix mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-label>{{searchOption}}</mat-label>
                    <input matInput type="text" [(ngModel)]="searchInput" (keyup)="updateFilter()">
                    <button mat-button  matSuffix mat-icon-button (click)=search.open()>
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-select [(ngModel)]="searchOption" #search class="langSelect">
                        <mat-option value="Entire Table">Entire Table</mat-option>
                        <mat-option value="name">Name</mat-option>
                        <mat-option value="timeProfile">Time profile</mat-option>
                        <mat-option value="icrNumber">IP Office Incoming Call Route</mat-option>
                        <mat-option value="companyNumbers">Company Numbers</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
        <div id="incoming-numbers-tabulator-div" class="tableDiv">

        </div>
    <button id="tableDoneBtn" class="tableButton" (click)="done()">Done</button>
    </div> 
</div>