<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 6/6 : Data Migration</span>
            <span class="migrationCompany">{{companyName}}</span>
        </div>
    </div>
    <div class="contentMain">
        <div class="migrationSummary" *ngIf="!showFailureData">
            <p class="migrationInfo" *ngIf="migrationStatus == status.NOTAVAILABLE">{{migrationInfo}}</p>
            <p class="migrationInfo migrationSuccessStatus" *ngIf="migrationStatus == status.SUCCESSFUL">
                {{migration_strings.SUCCESSFUL}}
                <!-- <a (click)="getSummary()">{{migration_strings.MORE_OPTIONS}}</a> -->
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="migrationStatus === status.FAILURE && isMarkAsComplete">{{migration_strings.FAILURE}}
                <!-- <a (click)="getSummary()">{{migration_strings.MORE_OPTIONS}}</a> -->
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="migrationStatus === status.FAILURE && !isMarkAsComplete">{{migration_strings.FAILURE_RETRY}}
                <!-- <a (click)="getSummary()">{{migration_strings.MORE_OPTIONS}}</a> -->
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="migrationStatus == status.TASK_ABORTED">
                {{migration_strings.TASK_ABORTED}}
            </p>

            <mat-progress-bar class="progressBar" mode="determinate" value={{migrationProgress}}></mat-progress-bar>

            <p class="migrationProgressPercentage">
                {{migrationProgress}}% completed
            </p>
            <button id="startMigration" class="tableButton" (click)="startMigration('start')"
                [disabled]="isMigrationStarted" *ngIf="migrationStatus == status.NOTAVAILABLE ">
                Start Migration
            </button>

            <button class="tableButton" (click)="close()" *ngIf=" migrationStatus === status.SUCCESSFUL ">Close</button>
            <button class="tableButton" (click)="loginToACOPortal()"
                *ngIf=" migrationStatus === status.SUCCESSFUL ">Login to ACO Portal</button>

            <button class="tableButton" (click)="startMigration('retry')" [disabled]="isMigrationStarted "
                *ngIf="migrationStatus !== status.NOTAVAILABLE && migrationStatus !== status.SUCCESSFUL && !isMarkAsComplete">Retry</button>
            <button class="tableButton" (click)="markAsComplete()" [disabled]="isMigrationStarted"
                *ngIf="migrationStatus !== status.NOTAVAILABLE && migrationStatus !== status.SUCCESSFUL && !isMarkAsComplete">Mark
                as Complete</button>

            <div class="summaryBlock" *ngIf="showMigrationSummary">
                <div class="migrationStatusIcon" style="background-color: white; border-top-left-radius:1.3vw;border-bottom-left-radius: 1.3vw; height: 100%;width: 7%;">
                    <img src="../../../../assets/images/migration_status/Icon_Green_check_mark.svg" *ngIf="migrationStatus === status.SUCCESSFUL && !isMarkAsComplete">
                    <img src="../../../../assets/images/migration_status/Icon_Green_check_mark_with_warning.svg" *ngIf="isMarkAsComplete">
                    <img src="../../../../assets/images/migration_status/Icon_user_migration_data_missing.svg"  *ngIf="migrationStatus === status.FAILURE && !isMarkAsComplete">
                </div>
                <div class="migrationStatusSummary" style="background-color: white;margin-left: 0.6%; border-top-right-radius:1.3vw;border-bottom-right-radius: 1.3vw; height: 100%;width: 92.4%;">
                    <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 1%;">
                        <span class="migrationStatusText" *ngIf="migrationStatus === status.SUCCESSFUL && !isMarkAsComplete"> {{migration_strings.SUCCESSFUL}}</span>
                        <span class="migrationStatusText" *ngIf="migrationStatus === status.FAILURE  && !isMarkAsComplete">{{migration_strings.FAILURE}}</span>
                        <span class="migrationStatusText" *ngIf="isMarkAsComplete">{{migration_strings.COMPLETED_BY_USER}}</span>
                        <span  class="migrationDurationText" *ngIf="showMigrationSummary">Duration : {{migrationStatusDocData.duration}} Minutes</span>
                    </div>
                    <a (click)="triggerDownloadReport()">Download Report</a>
                </div>
            </div>
            <div style="position: absolute; opacity: 0; pointer-events:none;width: 70%;height: 0%!important;overflow: hidden;" *ngIf="showMigrationSummary && !isMorrisonProject">
                    <div id="migrationStatusReport" style="width: 70%;margin-top: 0.5vw;">
                        <br><br><br><br>
                        <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                            <span style="justify-self: center;align-self: center; font-size: 1.8vw;">Migration Report</span>
                        </div>
                        <br>
                        <!-- Basic migration details like Company name , migration status , migration end time -->
                        <div class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" style="text-align: left;display: flex;flex-direction: column;">
                            <span class="downloadReportMargin_0_7vw">Company Name : {{companyName}}</span>
                            <span class="downloadReportMargin_0_7vw" *ngIf="migrationStatus === status.SUCCESSFUL">Migration Status : {{migration_strings.SUCCESSFUL}}</span>
                            <span class="downloadReportMargin_0_7vw" *ngIf="migrationStatus === status.FAILURE">Migration Status : {{migration_strings.FAILURE}}</span>
                            <span class="downloadReportMargin_0_7vw">Migration Completed on : {{migrationStatusDocData.endTime}}</span>
                        </div>
                        <br>
                        <!-- This Div will list all the feature executed for this migration -->
                        <div style="border-top: 1px solid black;display: flex;flex-direction: column;justify-content: left;align-items: center;">
                            <span style="font-size: 1.4vw;margin-top: 1.8vw;margin-bottom: 1vw;">Migration Summary</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure === 0">Assign Users : Successful</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure > 0">Assign Users : Failed</span>
                            <span *ngFor="let featureItem of featureDocData" class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">{{featureItem.name}} : {{featureItem.status}}</span>
                        </div>
                        <!-- Adding Assign Users feature as separate div since its not part of feature status Doc-->
                        <div style="display: flex;flex-direction: column;align-items: flex-start;border-bottom: 1px solid black;">
                            <span class="downloadReportFont_1vw" style="margin-top:1.6vw;">AssignUsers</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_1vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure === 0">Status : Success</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_1vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure > 0">Status : Failed</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Total : {{migrationSummaryData.total}}</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Successfully migrated : {{(migrationSummaryData.total - migrationSummaryData.failure)}}</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Failed to migrate : {{migrationSummaryData.failure}}</span>
                                <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngIf="failureUserData.length !== 0">Failures</span>
                                <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngFor="let failureItem of failureUserData">
                                    <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" >Extension :{{failureItem.extn}} , Fail reason : {{failureItem.message}}</span>
                                    <!-- <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" >{{failureItem}}</span> -->
                                </span>
                                <br>
                                <br>
                        </div>
                        <!-- Adding all feature as one div , all data is available in feature status DOc -->
                        <div *ngFor="let featureItem of featureDocData">
                            <div style="display: flex;flex-direction: column;align-items: flex-start;border-bottom: 1px solid black;">
                                <span class="downloadReportFont_1vw" style="margin-top:1.6vw;">{{featureItem.name}}</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_1vw">Status : {{featureItem.status}}</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Total : {{featureItem.total}}</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Successfully migrated : {{(featureItem.total - featureItem.totalFailed)}}</span>
                                <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Failed to migrate : {{featureItem.totalFailed}}</span>
                                <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngIf="featureItem.failedDesc.length !== 0">Failures</span>
                                <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngFor="let failureItem of featureItem.failedDesc">
                                    <!-- <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw"  *ngIf="failureItem.extn">{{failureItem.extn}} : {{failureItem.error.data.message}}</span>
                                    <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" *ngIf="failureItem.extId">{{failureItem.extId}} : {{failureItem.error.data.message}}</span> -->
                                    <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" >{{failureItem}}</span>
                                </span>
                                <br>
                                <br>
                            </div>
                        </div>
                        <br>
                    </div>
            </div>
        </div> 
        <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showFailureData>
            <div style="width: 100%;height: 88%; overflow-x: hidden;overflow-y: auto;">
                <table style="width: 90%;max-height: 100%;margin-left: 5%;">
                    <tr class="errorWarningRow" *ngFor="let item of failureUserData">
                        <td>
                            Extension :{{item.extn}} , Fail reason : {{item.message}}
                        </td>
                    </tr>
                </table>
            </div>
            <button class="tableButton " style="margin-top: 1%;" (click)="closeErrorWarningBlock()">
                Close
            </button>
        </div>
    </div>
    <div class="navigation" *ngIf="!isMigrationStarted && !isMarkAsComplete">
        <button mat-button class="arrowBack" matSuffix mat-icon-button (click)="navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
</div>