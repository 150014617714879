import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FirestoreService, REQUEST } from '../firestore/firestore.service';
import { isNumber } from 'util';
import { LogService } from '../logging/log.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private firestore:FirestoreService,private logger :LogService) {    
  }

  private newReqCountChange = new BehaviorSubject<number>(0);
  getnewReqCount(){
    return this.newReqCountChange.asObservable();
  }
  setNewUsersCount(count:number){
    this.newReqCountChange.next(count);
  }

  private approveUsersCountChange = new BehaviorSubject<number>(0);
  getApprovedUsersCount(){
    return this.approveUsersCountChange.asObservable();
  }  
  setApprovedUsersCount(count:number){
    this.approveUsersCountChange.next(count);
  }

  private rejectedUsersCountChange = new BehaviorSubject<number>(0);
  getRejectedUsersCount(){
    return this.rejectedUsersCountChange.asObservable();
  }
  setRejectedUsersCount(count:number){
    this.rejectedUsersCountChange.next(count);
  }

  updateUsersCount(type){
      if(type == REQUEST.NEWREQ){
        this.firestore.getMergedUserList(type).then((users:[])=>{
          this.setNewUsersCount(users.length);
        })
      }else{
          this.firestore.getUserCount(type).then((userCount)=>{
            if(isNumber(userCount)){
              if(type == REQUEST.APPROVE)
                this.setApprovedUsersCount(userCount);
              else if(type == REQUEST.REJECT)
                this.setRejectedUsersCount(userCount);
              else
                this.logger.error("Invalid request type : "+REQUEST[type]);
            }else{
              this.logger.error("The users count is not a number");
            }
          }).catch((err)=>{
            this.logger.error("Users count is not a number,query failed for request type: "+ REQUEST[type]);
          })
    }  
  }


  
 
}
