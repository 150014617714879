<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
      <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
        <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
      </button>
  
     <div class="migrationStepInfo">
        <span class="migrationStep">Step 4/5 : System Summary</span>
        <span class="migrationCompany">{{companyName}}</span>
      </div>
    </div>
    <div class="contentMain " style="display: flex;align-items: center;justify-content: center;">
        <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showSummary>
            <div style="width: 100%;height: 70%; overflow-x: hidden;overflow-y: auto;">
                <table style="width: 90%;max-height: 100%;margin-top: 2%;">
                  <tr class="errorWarningRow" *ngFor="let item of notifications">
                    <td *ngIf="item.severity == SEVERITY.WARNING">
                        <mat-icon style=" font-size: 1.1vw;color: #FF8200F7;">
                          warning
                        </mat-icon>
                    </td>
                    <td *ngIf="item.severity == SEVERITY.ERROR">
                    <mat-icon style=" font-size: 1.1vw;color: #DA291C;">
                        error
                    </mat-icon>
                    </td>
                    <td>
                      {{item.msg}}
                    </td>
                  </tr>
                </table>
              </div>
            <button class="tableButton " (click)="doACOPrerequisiteInfo()">
                Refresh
            </button>
            <!-- <button class="tableButton " (click)="proceed()">
                Proceed
            </button> -->
        </div>
    </div>
  
    <div class="navigation" *ngIf=showSummary>
      <!--button mat-button class="arrowBack" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateBack()">
              <mat-icon>arrow_back</mat-icon>
          </button-->
      <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)="navigateNext()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>