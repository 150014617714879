import { Component, OnInit , NgZone } from '@angular/core';
import { FirestoreService } from '../../../shared/services/firestore/firestore.service';
import { FireAuthService } from 'src/app/shared/services/firebaseAuth/fire-auth.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GSignInService } from 'src/app/shared/services/googleAPI/g-sign-in.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  
  accountImage:string='';
  userName:string;
  userCompany:string;
  userRole:string;
  authStateChange: Subscription;

  constructor(private fireauthService:FireAuthService,
    private logger:LogService, private firestoreService : FirestoreService,
    private router: Router , public zone: NgZone ,private googleService:GSignInService,
    public dialog: MatDialog) {
    this.logger.info("Admin home Constructor");
    
    this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
      this.authStateChange.unsubscribe();
      if(data !== null){
        this.fetchUserDetails(data);
      }else{
        this.zone.run(() => { this.router.navigate(['/']); });
      }
    });
    this.fireauthService.authStateChangeListener();
   }

  ngOnInit(): void {
  }

  fetchUserDetails(userDetails) {
    if(userDetails != null){ 
      this.userName = userDetails.displayName;
      this.accountImage = userDetails.photoURL;
      this.firestoreService.getUserProfileDetails()
      .then((usr:any) => {
          this.userCompany = usr.company;
          this.userRole = "Admin";
      }).catch((error) => {
        this.logger.debug("firebase User is Null")
      });
    }
  }

  openLogOutDialog(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: 'Logging out from admin tab logs you out from Migration window as well. Do you want to continue?',
        buttonText: {
          ok: 'Ok',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if(confirmed){
        this.doLogout();
      }
    });
  }

  doLogout(){
    this.logger.info("doLogout");
    this.fireauthService.logout()
    .then((res) =>{
      return this.googleService.signOut();
    }).then((res) =>{
      this.zone.run(() => {
        this.router.navigate(['']);
        window.opener.close();
     });
    }).catch((err) =>{
      this.logger.error("doLogout failed : ",err);
    });
  }

}
