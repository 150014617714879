<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 1/6 : Migration Basic Information</span>
        </div>
    </div>
    <div class="contentMain parent-flex">
        <mat-form-field class="migrationInputFormField">
            <mat-label>Company Name</mat-label>
            <input matInput type="text" [(ngModel)]="migration_company_name" matTooltip="Enter the name of the company for migration"  required=false>
            <button mat-button matSuffix mat-icon-button aria-label="Clear">
                <mat-icon>weekend</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div class="navigation">
        <!--button mat-button class="arrowBack" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button-->
        <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)="navigateNext()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>