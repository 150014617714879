import { Injectable } from '@angular/core';
import { Observable, Subject} from "rxjs";
import * as firebase from 'firebase';
import { LogService } from '../logging/log.service';
import { FirestoreService, GREETINGS_TRIGGER_STATUS } from '../firestore/firestore.service';

const GREETINGS_FOLDER_PATH = "Greetings/";

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  private progressSource = new Subject<number>();
  cardId:any;

  constructor(private logger: LogService, private firestoreService:FirestoreService) {
    this.logger.debug("File Upload service");
   }

  upload(file: File, id: string, filename: string, fileSize: number) {
    let uploadPath = GREETINGS_FOLDER_PATH+id+"/"+filename;
    var storageRef = firebase.storage().ref();
    var uploadTask = storageRef.child(uploadPath).put(file);
    this.cardId = id;

    var  next = (snapshot) => {
      var progress= snapshot.bytesTransferred / snapshot.totalBytes * 100;
      progress = Math.ceil(progress);
      this.sendProgress(progress);
    }
    var error = (err) => {
      unsubscribe();
      this.progressSource.error(err.message);
      this.clearProgress();
    }

    var complete = () => {
      // handle successful upload on complete
      unsubscribe();
      this.firestoreService.updateGreetingsTriggerStatus(this.cardId,GREETINGS_TRIGGER_STATUS.START,filename,fileSize).then(()=>{
        this.logger.debug("Updated the greetings status document to START");
      }).catch((err)=>{
         this.logger.error("Error updating the greeting status document to start: "+err);
          error(err);
      })
      // setTimeout(()=>{this.clearProgress();},1000);      
      /* uploadTask.snapshot.ref.getDownloadURL()
         .then((downloadURL) => {
           this.logger.debug('File available at', downloadURL);
           unsubscribe();
           //this.progressSource.complete();
         })*/
    }
    var unsubscribe = uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      next,error,complete);
  }

  getProgress(): Observable<number> {
     return this.progressSource.asObservable();
  }

  private sendProgress(data) {
    this.progressSource.next(data);
  }

  private clearProgress(){
    this.progressSource.next();
  }

}
