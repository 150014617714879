import { Injectable,NgZone } from '@angular/core';
import { FirestoreService, TASK_NAME, GREETINGS_TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';
import { LogService} from '../logging/log.service';
import * as firebase from 'firebase';
import {  Observable, BehaviorSubject, Subject } from 'rxjs';
import { resolve } from 'dns';
import { MigrationDataService } from '../migrationDataService/migration-data.service';
const NOT_FOUND:any = -1;
const IPO_MENU_KEYS = 13;
const PAGE_DEV_LIMIT = 25;
const ACO_RING_COUNT_UNIT = 5;
const DEFAULT_RING_COUNT = 3;
const PHONE_MODEL = {
    SOFTPHONE: "softphone",
    MOBILE: "Mobile"
}
const tldjs = require('tldjs');

const LOCATION = {
    AUTOMATIC: "0",
    CLOUD: "1"
}

const EXTNTYPE = {
    USEREXTN: "User",
    HUNTGROUPEXTN: "HuntGroup",
    IVRMENUEXTN: "AutoAttendant",
    PAGINGGROUPEXTN: "PagingGroup"
}


const FEATURE_NAME = {
    USERS: "Users",
    USER_CONTACTS: "UserContacts",
    USER_BHOURS: "UserBHours",
    USER_BLF: "UserBLF",
    CALL_FORWARD: "CallForward",
    HUNT_GROUP: "HuntGroup",
    AUTO_ATTENDANT_PROMPTS: "AutoAttendantPrompts",
    AUTO_ATTENDANT: "AutoAttendant",
    USER_VM_WORKHOURS: "UserVmWorkHours",
    USER_VM_AFTERHOURS: "UserVmAfterHours",
    USER_VM_CUSTOMHOURS: "UserVmCustomHours",
    PAGING_GROUP: "PagingGroup",
    //USER_UPDATERINGONMONITOREDCALLDATA: "ringOnMonitoreCallData",
    COMPLEX_IVR_PROMPTS:"ComplexIvrPrompts",
    POST_MIGRATION: "PostMigration"
}

const IPOMENU_KEYACTIONS = {
    CENTREX_TRANSFER: {
        action:"CentrexTransfer",
        description:"Centrex transfer"
    },
    DIALBYNAME: {
        action:"DialByName",
        description:"Dial by name"
    },
    DIALBYNUMBER: {
        action:"DialByNumber",
        description:"Dial by number"
    },
    NORMALTRANSFER: {
        action:"NormalTransfer",
        description:"Normal transfer"
    },
    NOT_DEFINED: {
        action:"Not_defined",
        description:"Not defined"
    },
    PARK_AND_PAGE: {
        action:"ParkAndPage",
        description:"Park and page"
    },
    REPLAY_GREETING: {
        action:"ReplayGreeting",
        description:"Replay greeting"
    },
    BLINDTRANSFER: {
        action:"BlindTransfer",
        description:"Blind transfer"
    },
    TRANSFER_TO_AA: {
        action:"TransferToAutoAttendant",
        description:"Transfer to auto attendant"
    },
    FAX: {
        action:"Fax",
        description:"Not Applicable on ACO"
    },
    STAR: {
        action:"*",
        description:"Mapped to Replay Greeting on ACO"
    },
    HASH: {
        action:"#",
        description:"Mapped to Replay Greeting on ACO"
    }
} 

export const AA_ERROR_MSG = {
    PROMPTS_MISSING : "Prompts missing",
    UNMATCHED_KEY_ACTIONS : "No matching key actions",
    INVALID_DESTINATION : "Destination extension no longer exists",
    COMPLEX_CALL_FLOW : "Complex IP Office call flows with no one to one feature match with ACO for call flow(s), only menu ivr extensions migrated for keys : ",
    TIMEOUT_PRESENT: "Call flows with timeout entries: ",
    NO_KEYS_TO_MATCH: "No keys to match"
}

export const VM_ERROR_MSG ={
    GREETINGS_MISSING : "Voicemail greetings missing",
    PARTIAL_USERS : "Voicemail greetings partially uploaded",
    IGNORED_USERS : "Users with greetings file size greater than max limit of 20MB are ignored"
};

export const PG_ERROR_MSG =  {
    SINGLE_EXTN_NOT_ASSIGNED :"Page capable device is not assigned for extension ",
    MULTIPLE_EXTNS_NOT_ASSIGNED:"Page capable devices are not assigned for extensions ",
    LIMIT_RCHD : "Max limit of "+PAGE_DEV_LIMIT+" paging devices reached in the paging group. Not migrating ",
    ALL_MEMBERS_DELETED : "Migrating empty paging group as all members are deleted"
}
export const HG_ERROR_MSG = {
    ALL_MEMBERS_DELETED : "Migrating empty hunt group as all members are deleted",
    ANN_MISSING : "Announcements missing for IP Office hunt group(s): ",
    UNSUPPORTED_CALL_FLOW : "Skipping unsupported call flow announcement for IP Office hunt group(s): ",
    MUL_ANN_FILES : "Multiple announcement files for VMPro call flows for IP Office hunt group(s): ",
    ANN_BOX_UNCHECKED: "Announcements skipped as Announcements On is unchecked in IP Office configuration for hunt group(s): ",
    VM_GREETING_MISSING: "Missing Voicemail greetings for IP Office hunt group(s): ",
    VM_COMPLEX_CALLFLOW: "Skipping unsupported call flow Voicemail greetings for IP Office hunt group(s): "
}
export const TWIN_CONTACT_NUMBERS = {
    STEERING_CODES_REMOVAL : "Personal Contacts and Twinned Mobile Numbers matching with steering codes will be trimmed: "
}

//Should be maintained as TASK QUEUE ORDER
const TASK_QUEUE_PRIORITY = [
    FEATURE_NAME.USERS,
    FEATURE_NAME.USER_CONTACTS,
    FEATURE_NAME.USER_BHOURS,
    FEATURE_NAME.USER_BLF,
    FEATURE_NAME.CALL_FORWARD,
    FEATURE_NAME.HUNT_GROUP,
    FEATURE_NAME.AUTO_ATTENDANT_PROMPTS,
    FEATURE_NAME.AUTO_ATTENDANT,
    FEATURE_NAME.USER_VM_WORKHOURS,
    FEATURE_NAME.USER_VM_AFTERHOURS,
    FEATURE_NAME.USER_VM_CUSTOMHOURS,
    FEATURE_NAME.PAGING_GROUP,
   // FEATURE_NAME.USER_UPDATERINGONMONITOREDCALLDATA,
    FEATURE_NAME.COMPLEX_IVR_PROMPTS,
    FEATURE_NAME.POST_MIGRATION
]

//Templates
interface IPOUserData {
    US_Name:string,
	US_KName:string,
	US_Fullname:string,
	US_VoiceMailEmail:string,
	US_UniqueIdentity:string,
	Extension:{
		US_Extension:string,
		EX_Type:string,
		EX_IsExtnCentralized:string,
		EX_CentralizedDDINumber:string,
        Ex_Location:string,
        Location_Timezone: string,
        Location_Name: string,
		US_UserCLI:string,
        EX_VoipFax:string,
        US_UserRightsTimeProfile: string		
	},
	ICR_Number:string,
    US_Locale:string,
    US_AssignedPackage:string,	
	Phone: {
		EX_PhoneType:string,
		EX_Classification:string,
		US_SystemPhone:string,
	},
	US_ContactsList:{
	    Name:string,
		Number:string,
		SpeedDial:string
	}[],
	Ringing: {
		US_OCS:string,
		US_ICS:string,
		US_RBS:string
	},
	Forwarding: {
		US_ForwardOnBusy:string,
		US_ForwardOnNoAnswer:string,
		US_ForwardUnconditional:string,
		US_ForwardHuntGroup:string,
		US_ForwardNumber:string,
		US_ForwardBusyNumber:string,
	},
	DND: {
		US_DoNotDisturb:string,
		US_DoNotDisturbList:string
	},
	Absent: {
		US_AbsentMessage:string,
		US_AbsentSet:string,
		US_AbsentText:string
    },
    Twinning: {
        US_TwinningType:string,
        US_TwinMobileNumber:string,
        US_TwinTimeProfile:string
    },
    User_BLF :any,
    US_NoAnswerTime:number
};

interface tablePreviewData {
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: any,
    did: string,
    extension: number,
    profileType: string,
    ipoPhoneModel: string,
    acoPhoneModel: string,
    extensionId: string,
    acoPhoneStatus: any,
    migrationReady: boolean,
    blfList: any,
    contactList:any,
    workHoursProfile: string,
    //User features data
    timeZoneId: string,
    timeZoneName: string,
    tzOffset: string,
    twinMob: string,
    twinProfile: string,
    vmGreetings: boolean,
    //ipo user name 
    ipoUserName: string,
    pageCapable: boolean,
    ringCount: number,
    isSelected: boolean
};
interface incomingNumbers
{
    icrNumber:string,
    name:string,
    timeProfile:string,
    companyNumbers:string
}

interface AAICR
{
    name:string,
    phoneId:number,
    phoneNumber:string,
    timeProfile:string
}


interface tableTimeProfileData {
    name: string,
    calendar: boolean,
    recurrence: boolean
}
interface tableHuntGroupData{
    name: string,
    extension: number,
    totalMembers: number,
    overflowGroup: string,
    ringMode: string,
    isPagingEnabled: boolean,
    devices: Array<string>
};

interface tableAutoAttendantData{
    name: string,
    menuAvailability: boolean,
    ivrPrompt: boolean,
    companynumbers: Array<string>,
};

interface tablePagingGroupData{
    name: string,
    extension: number,
    members: Array<string>
}

interface ExtensionMap{
    name: string,
    extn: number,
    extId: string,
    type: string
}

interface ACOExportUserData{
    extId: string,
    data:{
        contact:{
            firstName:string,
            lastName:string,
            email: string,
            emailAsLoginName:boolean
        },
        regionalSettings:{
            timezone:{
                id:string
            }
        },
        status:string,
        extensionNumber:number
    }
}

/*interface ACOExportFwdData{
    extId: string,
    data:{
        flipNumber:number,
        phoneNumber:string,
        label:string,
        type:string
    }
}*/
interface ACOExportNonTwinData{
    extId: string,
    ringCount: number
}

interface ACOExportHGUserData{
    extn: number,
    data:{
        contact:
            {
                firstName:string,
                email:string
            },
        extensionNumber:number,
        type: string 
    },
    membersData: {
        addedExtensionIds: any
    }
}

interface ACOExportPGUserData{
    ipoExtn: number,
    data:{
        contact:
        {
            firstName:string
        },
        type: string 
    },
    membersData: {
        addedDeviceIds: any
    }
}

interface SteeringCodeData{
    Steering_Code: string,
    SC_Telephone: string
}

interface HGWarningData{
    missingHGAnnList:any,
    unsupportedHGAnnList:any,
    chainedAnnHGList:any,
    annUnCheckedHGList:any,
    hgVMMissingList:any,
    hgVMComplexList:any
}

export enum autoAssignName {
    Fname_Lname,
    Lname_Fname,
    Fname,
    Lname
}


export enum autoAssignPhone{
    PhoneType,
    UserType
}

export enum phoneStatus{
    unassigned,
    assigned
}

export enum AutoAssignmentConfig{
    email,
    phoneAssignment,
    NameAssignment,
    Fname,
    Lname,
    All
}

enum TwinRule{
    default,
    TPForTwinNumberOnly,
    TPForPrimaryNumerOnly,
    TPForBoth
}

export enum SEVERITY{
    WARNING,
    ERROR
}

export const PAGINATION_SIZE = 10;

const REJECT = {
    INVALID_NAME_ASSIGNMENT: "Invalid choice selected for name assignment",
    INVALID_PHONE_ASSIGNMENT_CHOICE: "Invalid choice selected for phone assignment",
    NO_TABLE_DATA: "Empty table data",
    INVALID_IPO_PHONE_MODEL: "Selected IP Office phone model unavailable",
    INVALID_ACO_PHONE_MODEL: "Selected ACO phone model unavailable",
    INVALID_IPO_USER_TYPE: "Selected IP Office license type unavailable",
    PHONE_ASSIGNMENT_FAILED: "Unable to complete phone assignment",
    REMOVE_PHONE_INVALID_EXTENSION: "Extension unavailable to change phone model",
    ASSIGN_PHONE_INVALID_EXTENSION: "Extension unavailable to assign new phone model",
    ASSIGN_PHONE_INVALID_MODEL: "Selected new phone model unavailable",
    INCOMPLETE_PHONE_ASSIGNMENT: "Phone auto assignment partially completed"
};

const PHONE = {
    UNASSIGN: "unassign",
    UNASSIGNED: "unassigned"
}

const reRecordMsg = "IVR prompt needs to be re-recorded";
export var assignedExtnList:any = [];
export var assignedEmailList: any= [];
@Injectable({
    providedIn: 'root'
})
export class TablePreviewService {

    private _tableUserList: tablePreviewData[] = [];
    private _tableTimeProfileList: tableTimeProfileData[] = [];
    private _tableHuntGroupList: tableHuntGroupData[] = [];
    private _tableAutoAttendantList: tableAutoAttendantData[] = [];
   // private _tablePagingGroupList: tablePagingGroupData[] = [];    
    private _ipoUserList: IPOUserData[] = [];
    private _ipoUsersByPhone = [];
    private _ipoUsersByProfile = [];
    private _extnsMap: ExtensionMap[] = [];
    private _acoExportUserList: ACOExportUserData[] = [];
    private _acoExportNonTwinUserList: ACOExportNonTwinData[] = [];
    private _acoExportHGUserList: ACOExportHGUserData[] = [];
    private _acoExportUserBHourList = [];
    private _acoExtnByPhoneGroup = [];
    private _pageCapableDeviceList = [];
    private _acoExportFwdList = [];//: ACOExportFwdData[]
    private _cardId: string;
    private _timeZoneList = [];
    private _extnBLFList = [];
    private _extncontactList = [];
    private _extnPresenceTobeSet = [];
    private _taskChainList = [];
    private _acoExportPGUserList: ACOExportPGUserData[] = [];
    private _tableIncomingNumbersList: incomingNumbers[] = [];
    private _originaltableIncomingNumbersList = [];
    private _assignedEmailList = [];
    private _assignedExtnList = [];
    public _modifiedCompanyNumberAssignementCount;
    private _originalICRData;
    private _modifiedICRData;
    private _tableIncomingNumbersListCount ;
    //DB data
    private _acoImportData: any = [];
    private _ipoCsvData: any = [];
    private _ipoTimeProfile: any = [];
    private _ipoHuntGroup: any = [];
    private _ipoPagingGroup: any = [];    
    private _advncdUserInfoWarningsList: any = [];
    private _timeProfilesWarningsList: any = [];
    private _autoAttendantsWarningsList: any = [];
    private _pagingGroupsWarningsList: any = [];
    private _huntGroupWarningsList: any = [];
    private _deletedUsersList: tablePreviewData[] = [];
    private countryName;
    private _emailGroup: any;
    private _invalidEmailList: any = [];

    public _notifications: any = [];

    private _tableDataInitialized:BehaviorSubject<boolean>;
    private _greetingsDocStatus = new BehaviorSubject<string>(GREETINGS_TRIGGER_STATUS.UNKNOWN);
    private _cardClosureStatus = new Subject<boolean>();
    private _acoPhData:any = [];
    private _incomingNumbers: any = [];
    private _acoTimeZone:any;
    private isUpdatedICR:any;
    private AAList= [];
    private steeringCodes: SteeringCodeData[] = [];
    public reservedExtensionsList = [];
    private _hgWarnObj:HGWarningData;
    // All write to DB to be done through Firestore service
    // All read for the session will be done through migrationdata service
    constructor(private firestoreService: FirestoreService,
        private logger:LogService,private ngzone: NgZone, private migrationDataService:MigrationDataService) {
        this._tableUserList = [];
        this._tableTimeProfileList = [];
        this._tableIncomingNumbersListCount = 0;
        this.firestoreService.getGreetingsDataAvlblMsg().subscribe((data)=>{
            this.updateTableData(data);
        })
    }

    get tableUserList(): tablePreviewData[] {
        return this._tableUserList;
    }

    set tableUserList(value: tablePreviewData[]) {
        this._tableUserList = value;
    }

    get tableTimeProfileList(): tableTimeProfileData[] {
        return this._tableTimeProfileList;
    }

    set tableTimeProfileList(value: tableTimeProfileData[]) {
        this._tableTimeProfileList = value;
    }

    get tableHuntGroupList(): tableHuntGroupData[] {
        return this._tableHuntGroupList;
    }

    set tableHuntGroupList(value: tableHuntGroupData[]) {
        this._tableHuntGroupList = value;
    }
    
    get tableAutoAttendantList(): tableAutoAttendantData[] {
        return this._tableAutoAttendantList;
    }

    set tableAutoAttendantList(value: tableAutoAttendantData[]) {
        this._tableAutoAttendantList = value;
    }

    // public get tablePagingGroupList(): tablePagingGroupData[] {
    //     return this._tablePagingGroupList;
    // }

    // public set tablePagingGroupList(value: tablePagingGroupData[]) {
    //     this._tablePagingGroupList = value;
    // }

    get ipoUserList(): IPOUserData[] {
        return this._ipoUserList;
    }
    get acoPhData(): any{
        return this._acoPhData;
    }

    get steeringCodeData(): SteeringCodeData[] {
        return this.steeringCodes;
    }

    set ipoUserList(value: IPOUserData[]) {
        this._ipoUserList = value;
    }

    get acoExtnByPhoneGroup(): any {
        return this._acoExtnByPhoneGroup;
    }

    get ipoUsersByPhoneModel(): any {
        return this._ipoUsersByPhone;
    }

    get acoImportData():any {
        return this._acoImportData;
    }
    get ipoUsersByProfile(): any {
        return this._ipoUsersByProfile;
    }

    get AcoExportData(): ACOExportUserData[] {
        return this._acoExportUserList;
    }

    get AcoExportNonTwinUserList(): ACOExportNonTwinData[] {
        return this._acoExportNonTwinUserList;
    }

    get ACOExportFwdData(): any[]{// ACOExportFwdData[] {
        return this._acoExportFwdList;
    }

    get notificationsList(): any {
        return this._notifications;
    }

    get advncdUserInfoWarningsList(): any {
        return this._advncdUserInfoWarningsList;
    }

    get timeProfilesWarningsList(): any {
        return this._timeProfilesWarningsList;
    }

    get autoAttendantsWarningsList(): any{
        return this._autoAttendantsWarningsList;
    }

    get huntGroupWarningsList(): any{
        return this._huntGroupWarningsList;
    }

    get pagingGroupsWarningsList(): any{
        return this._pagingGroupsWarningsList;
    }

    get emailGroup(): any {
        return this._emailGroup;
    }

    get invalidEmailList(): any {
        return this._invalidEmailList;
    }

    get assignedEmailList(): any {
        return this._assignedEmailList;
    }

    get assignedExtnList(): any {
        return this._assignedExtnList;
    }

    public get ipoPagingGroup(): any {
        return this._ipoPagingGroup;
    }

    public set pagingGroup(value: any) {
        this._ipoPagingGroup = value;
    }
    public get incomingNumbers(): any {
        return this._tableIncomingNumbersList;
    }
    public set incomingNumbers(value: any){
        this._tableIncomingNumbersList = value;
    }
    public get incomingNumbersCount(): any {
        return this._tableIncomingNumbersListCount;
    }
    public set incomingNumbersCount(value: any){
        this._tableIncomingNumbersListCount= value;
    }
    public get isCompanyNumberUpdated(): any {
        return this.isUpdatedICR;
    }
    public set isCompanyNumberUpdated(value: any){
        this.isUpdatedICR = value;
    }
    private getExtensionGroupByPhoneModel(){
        var extensionByPhone = { phoneModel: "", count:0, extensionList: []};
        return extensionByPhone;
    }

    private getExtensionGroupByProfile(){
        var extensionByProfile = { profileType: "", count:0, extensionList: []};
        return extensionByProfile;
    }

    private getExtnMapTemplate(){
        let extnDetail = { 
            extId: "",
            extn: 0,
            name: "",
            tzOffset: "",
            type: EXTNTYPE.USEREXTN
        }
        return extnDetail;
    }

    private getAcoExportDataTemplate(){
        var userExtn = { extId: "",
            data:{
                contact:{
                    firstName: "",
                    lastName: "",
                    email: "",
                    emailAsLoginName:false
                },
                regionalSettings:{
                    timezone:{
                        id:""
                    }
                },
                status: "Disabled",
                transition:{
                    sendWelcomeEmailsToUsers:false,
                    sendWelcomeEmail:false
                },
                extensionNumber: 0
            }
        }
        return userExtn;
    }

    private getAcoUserPersonalContactTemplate(){
        let userContact = { 
            extId: "",
            data:{
                records: [],
            }            
        } 
        return  userContact;
    } 

    private getAcoUserHuntGroupTemplate(){
        let hgUser = {
            extn: 0,
            data:{
                contact:
                    {
                        firstName:"",
                        email: ""
                    },
                extensionNumber:0,
                type: "Department" 
            },
            membersData: {
                addedExtensionIds:[]
            }
        }
        return hgUser;
    }

    private getBLFDataTemplate(){
        let userBLF = {
            data:{
                id: "",
                extension:{
                    id: ""
                }
            }
        }
        return userBLF;
    }

    private getCallForwardTwinUserDataTemplate(){
        var callFwdData = { 
            extId: "",
            data:{
                "flipNumber":2,
                "phoneNumber":"",
                "label":"Twin",
                "type":"Mobile"
            },
            twinRule: TwinRule.default,
            twinTProfileLabel : "",
            ringCount : DEFAULT_RING_COUNT
        }
        return callFwdData;
    }

    private getPagingGroupDataTemplate():ACOExportPGUserData{
        let pagingGroup = {
            ipoExtn: 0,
            data:{
                contact:
                    {
                        firstName:""
                    },
                extensionNumber:0,
                type: "PagingOnly" 
            },
            membersData: {
                addedDeviceIds:[]
            }
        }
        return pagingGroup;
    }

    private getHGWarnTemplate():HGWarningData {
        let obj = {
            missingHGAnnList:[],
            unsupportedHGAnnList:[],
            chainedAnnHGList:[],
            annUnCheckedHGList:[],
            hgVMMissingList:[],
            hgVMComplexList:[]
        }
        return obj;
    }

    private fillPagingDevList(){
        this._pageCapableDeviceList = [];
        let pagingDev = this._acoImportData['paging_dev'];
        try{
            if(pagingDev != undefined)
                this._pageCapableDeviceList = JSON.parse(pagingDev);
        }catch(error){
            this.logger.error("Error while parsing ACO Import paging group data: ",error);
        }
        
    }

    private fillIPOUsersByPhoneAndProfile(){
        this.logger.info("fillIPOUsersByPhoneAndProfile");
        var phModelGroup: any;
        var ProfileGroup: any;
        this._ipoUsersByPhone = [];
        this._ipoUsersByProfile = [];
        for(let i=0;i<this._tableUserList.length;i++){

            if(this._tableUserList[i].acoPhoneStatus === phoneStatus.assigned){
                //this.logger.debug("Already assigned phone - ignore ", this._tableUserList[i].extension);
                continue;
            }

            //this.logger.debug("Not assigned, extn ", this._tableUserList[i].extension);

            //Fill _ipoUsersByPhone
            phModelGroup = this._ipoUsersByPhone.find(entry => entry.phoneModel === this._tableUserList[i].ipoPhoneModel);
            if (phModelGroup) {
                phModelGroup.extensionList.push(this._tableUserList[i].extension);
                phModelGroup.count++;
            }
            else{
                phModelGroup = this.getExtensionGroupByPhoneModel();
                phModelGroup.extensionList.push(this._tableUserList[i].extension);
                phModelGroup.phoneModel =  this._tableUserList[i].ipoPhoneModel;
                phModelGroup.count++;
                this._ipoUsersByPhone.push(phModelGroup);
            }

            //Fill _ipoUsersByProfile
            ProfileGroup = this._ipoUsersByProfile.find(entry => entry.profileType === this._tableUserList[i].profileType);
            if (ProfileGroup) {
                ProfileGroup.extensionList.push(this._tableUserList[i].extension);
                ProfileGroup.count++;
            }
            else{
                ProfileGroup = this.getExtensionGroupByProfile();
                ProfileGroup.extensionList.push(this._tableUserList[i].extension);
                ProfileGroup.profileType =  this._tableUserList[i].profileType;
                ProfileGroup.count++;
                this._ipoUsersByProfile.push(ProfileGroup);
            }
        }
        this.logger.debug("Updated list of ipo users by phone and profile");
/*         this.logger.debug("_ipoUsersByPhone: ", this._ipoUsersByPhone);
        this.logger.debug("_ipoUsersByProfile: ", this._ipoUsersByProfile); */
    }

    private fillACOUsersByPhone(){
        this.logger.info("fillACOUsersByPhone");
        var acoImportData = this._acoImportData;
        this._acoExtnByPhoneGroup = [];
        this._acoExtnByPhoneGroup = JSON.parse(acoImportData['extns_by_phone']);       

        this._tableUserList.forEach(element =>{
            if(element.acoPhoneStatus === phoneStatus.assigned){
               // this.logger.debug("fillACOUsersByPhone - assigned extension: ", element.extension);
                var matchPhoneGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === element.acoPhoneModel);
                if(matchPhoneGroup){
                    var index = matchPhoneGroup.extensionList.indexOf(element.extensionId);
                    if (index !== NOT_FOUND){
                        //this.logger.debug("MATCH found in aco extension by phone group");
                        matchPhoneGroup.extensionList.splice(index, 1);
                        matchPhoneGroup.count--;
                    }
                }
            }
        })
        //this.logger.debug("_acoExtnByPhoneGroup: ", this._acoExtnByPhoneGroup);
    }
    private getCompanyPhNumbers()
    {
        let tempArr = [];
        this.firestoreService.readComPhNumberData(this._cardId)
        .then((comPhData:any) => {
            let compData= (comPhData && comPhData.data)?JSON.parse(comPhData.data):[];  
            compData.forEach(element => {
                tempArr.push(element.phoneNumber);
            });
            this._acoPhData = [...new Set(tempArr)]
        })
    }

    public async fillSteeringCodesData() {
        let tempArr = [];
        await this.firestoreService.readSteeringCodeData(this._cardId)
        .then((steeringCodes:any) => {
            let steeringCodearray = (steeringCodes && steeringCodes.data)?JSON.parse(steeringCodes.data):[];  
            steeringCodearray.forEach(element => {
                let SteeringCode = {
                    Steering_Code: element.Steering_Code,
                    SC_Telephone: element.SC_Telephone
                }
                //tempArr.push(element);
                tempArr.push(SteeringCode);
            });
            this.steeringCodes = [...new Set(tempArr)];
        })
    }

    private getDID(extensionId){
        var matchFound = false;
        var result="";
        var acoImportData = this._acoImportData;
        //this.logger.debug("acoImportData: ", acoImportData);
        var dl_data = JSON.parse(acoImportData.dl_data);   
        //this.logger.debug("getDID: dl_data: ", dl_data);
        if(dl_data !== ""){  
            var dlData = [];
            dlData = dl_data.ua_exts;
            var user = dlData.find(entry => entry.extensionID === extensionId);
            if(user !== undefined && user.DL[0] !== "" && user.DL[0].DID !== ""){
                result = user.DL[0].DID.phoneNumber;
                matchFound = true;
            }
        }

        if(!matchFound){
            var did = JSON.parse(acoImportData.did_data);  
            if(did !== ""){
                var didData = [];
                didData = did.ua_exts;
                var user = didData.find(entry => entry.extensionID === extensionId);;   
                if(user !== undefined && user.DID !== "" && user.DID[0] !== ""){
                    result = user.DID[0].phoneNumber;
                    matchFound = true;
                }
            }
        }
        return result;
    }

    private isDevicePageCapable(extensionId){
        let pgDevEntry = this._pageCapableDeviceList.find(entry => entry.extensionId === extensionId);
        if(pgDevEntry !== undefined)
            return true;
        return false;
    }

    public initializeTableData(cardId){
        this.logger.debug("initializeTableData");
        this._extnsMap = [];
        this._tableUserList = [];
        this._taskChainList = [];
        this._tableTimeProfileList = [];
        this._tableHuntGroupList = [];
       // this._tablePagingGroupList = [];
        this._tableAutoAttendantList = [];
        this._acoExportUserList = [];
        this._acoExportUserBHourList = [];
        this._acoExportFwdList = [];
        this._acoExportHGUserList = [];
        this._acoExportPGUserList = [];
        this._emailGroup = {};
        this._cardId = cardId;
        this._timeProfilesWarningsList = [];
        this._autoAttendantsWarningsList = [];
        this._huntGroupWarningsList = [];
        this._pagingGroupsWarningsList = [];
        this._advncdUserInfoWarningsList = [];
        this._assignedExtnList = [];
        this._assignedEmailList =[];
        this._acoTimeZone = null;
        let ExtnDetails = [];
        let mailDetails = [];
        var extnData;
        this._tableIncomingNumbersListCount = 0;
        this._tableDataInitialized = new BehaviorSubject<boolean>(false);
        this._deletedUsersList = [];
        this._hgWarnObj = this.getHGWarnTemplate();
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.getAssignedExtensions(cardId)
               .then((doc:any) => {                
                extnData = doc.data;
                ExtnDetails  = JSON.parse(extnData);
                ExtnDetails.forEach(extnId => {
                    this._assignedExtnList.push(extnId.extensionNumber);
                    mailDetails.push(extnId.contact);
                })
                    mailDetails.forEach(mail => {
                        this._assignedEmailList.push(mail.email);
                    });

               })
               .catch((error) => {
                this.logger.error(error);
                reject(error);
            });
            
            let aaMenuData,vmGreetingsData,ignoredUsersList,partialUsersList;            
            let aaComplexCallFlowList, isRootVmpFileFound = false,vmproAAParsedFlows, aaTimeoutList;
            this.firestoreService.getFeaturePrerequisite(cardId)
            .then((result:any) => {
                if(typeof result !== 'undefined' && typeof result.timeZoneList !== 'undefined'
                && result.timeZoneList !== ''){
                    this._timeZoneList = JSON.parse(result.timeZoneList);
                }
                return this.firestoreService.readIPOCfgData(cardId);
            })
            .then((result:any) => {
                this._ipoCsvData  = JSON.parse(result);
                this._ipoUserList = this._ipoCsvData['IPOUsers'];
                this.logger.debug("IPO Users list length: ", this._ipoUserList.length);
                return (this.firestoreService.getAcoImportData(cardId));
            })
            

            .then((doc:any) => {
                if (doc !== undefined && doc.exists) {
                    this.logger.debug("ACO Import data");
                    this._acoImportData = doc.data();
                    return (this.firestoreService.readDeletedUsersCfgData(cardId)
                    .then((doc:any)=>{
                        this._deletedUsersList = (doc !== null)? JSON.parse(doc.data): [];
                       // this.logger.debug("Deleted users: ", JSON.stringify(this._deletedUsersList));
                        return;
                    })
                    .then(()=>{
                        return this.firestoreService.getACOSummaryData(cardId)
                        .then((doc:any)=>{
                            this._acoTimeZone = (doc.data !== null && JSON.parse(doc.data).timezone !== undefined) ? JSON.parse(doc.data).timezone: null;
                            return;
                        })
                        .catch((error)=>{
                            this.logger.error("Error while reading ACO summary data: "+ error);
                            return;
                        })
                    })
                    .then(()=> {
                        return (this.generateInitialTableUserList()
                        .then(() => {
                            //this.logger.debug("Initial table list: ", this._tableUserList);
                            this.fillIPOUsersByPhoneAndProfile();
                            this.fillACOUsersByPhone();
                            this.fillPagingDevList();
                            this.validateTableData();
                            this.fillSteeringCodesData();
                            return;
                        })
                        .catch((error) => {
                            reject(error);
                        }));
                    })
                    .catch((error)=>{
                        this.logger.error(error);
                        reject(error);
                    }));
                }
                else{
                    reject();
                }
            })
            .then(()=>{
                this.firestoreService.readTimeProfilesData(cardId)
                .then((doc:any)=>{
                    this._ipoTimeProfile = (doc !== null)? JSON.parse(doc.data): [] ;
                    var notifyMsg;
                    this._ipoTimeProfile.map(entry=>{
                        let timeProfEntry:tableTimeProfileData = {} as any;
                        timeProfEntry.name = entry.name;
                        timeProfEntry.calendar =  entry.profiles.data.schedule.ranges?true:false;
                        timeProfEntry.recurrence = entry.profiles.data.schedule.weeklyRanges?true:false;
                        this._tableTimeProfileList.push(timeProfEntry);
                        if(timeProfEntry.calendar && timeProfEntry.recurrence){
                            notifyMsg = timeProfEntry.name + " has mixed time profiles";
                            this._timeProfilesWarningsList.push(notifyMsg);
                        }
                    })
                    this.logger.debug("completed time profiles");
                    return;
                })
                .catch((error) => {
                    return;
                });
            })
            .then(()=>{
                return this.firestoreService.getVMProDocData(cardId)
                .then((data:any)=>{
                    if(data !== null && data !== undefined){
                        isRootVmpFileFound = (data.rootVmpFileFound !== undefined && data.rootVmpFileFound !== null && 
                            data.rootVmpFileFound !== "") ? data.rootVmpFileFound : false;
                        this.logger.debug("completed vmpro data : ");
                    }
                    return;
                })
                .catch((error)=>{
                    this.logger.error(error);
                    return;
                })
            })
            .then(() =>{
                return this.firestoreService.getGreetingsData(cardId)
                .then((data:any)=>{
                    try{
                        let parsedData = data.greetingsData !== undefined ?JSON.parse(data.greetingsData):undefined;
                        if(parsedData){
                            aaMenuData = parsedData.aaMenuOptions;
                            vmGreetingsData = parsedData.userGreetings;                            
                        }
                        ignoredUsersList = data.ignoredUsers !== undefined ? JSON.parse(data.ignoredUsers):undefined;
                        partialUsersList = data.partialUsers !== undefined ? JSON.parse(data.partialUsers):undefined;
                        this._hgWarnObj.missingHGAnnList = data.missingHGAnnList !== undefined ? JSON.parse(data.missingHGAnnList):undefined;
                        this._hgWarnObj.unsupportedHGAnnList = data.unsupportedHGAnnList !== undefined ? JSON.parse(data.unsupportedHGAnnList):undefined;
                        this._hgWarnObj.chainedAnnHGList = data.chainedAnnHGList !== undefined ? JSON.parse(data.chainedAnnHGList):undefined;
                        this._hgWarnObj.annUnCheckedHGList = data.annUnCheckedHGList !== undefined ? JSON.parse(data.annUnCheckedHGList):undefined 
                        this._hgWarnObj.hgVMMissingList = data.hgVMMissingList !== undefined ? JSON.parse(data.hgVMMissingList) : undefined;
                        this._hgWarnObj.hgVMComplexList = data.hgVMComplexList !== undefined ? JSON.parse(data.hgVMComplexList) : undefined;
                        aaComplexCallFlowList = (isRootVmpFileFound && data.aaComplexCallFlowList !== undefined) ? JSON.parse(data.aaComplexCallFlowList):undefined;
                        vmproAAParsedFlows = (isRootVmpFileFound && data.vmproAAParsedFlows !== undefined) ? JSON.parse(data.vmproAAParsedFlows):undefined;
                        aaTimeoutList = (isRootVmpFileFound && data.aaTimeoutList !== undefined) ? JSON.parse(data.aaTimeoutList):undefined;
                        this.logger.debug("completed greetings data");
                        return;
                    }catch(error){
                        this.logger.debug(error);
                        return;
                    }
                }).catch((error)=>{
                    this.logger.debug(error);
                })
            })
            .then(()=>{
                
            })
            .then(() => { 
                return this.firestoreService.readHuntGroupData(this._cardId)
                    .then((doc:any) => {
                        (doc !== null)?this._ipoHuntGroup=JSON.parse(doc.data):this._ipoHuntGroup=[];
                        this.generateInitialHGnPGTableData().then(()=>{
                            this.updateHuntGroupWarnings();
                            this.updatePGNotificationMsg();
                            this.logger.debug("completed hunt group");
                            return;
                        }).catch((error)=>{
                            this.logger.debug(error);
                            return;
                        })
                    })
                .catch((error)=>{
                    this.logger.error(error);
                    return;
                   // reject(error);
                })     
            })
            // .then(() => {
            //     return this.firestoreService.readPagingGroupData(cardId)
            //     .then((doc:any) => {
            //         (doc !== null)?this._ipoPagingGroup=JSON.parse(doc.data):this._ipoPagingGroup=[];
            //         let pgLength = this._ipoPagingGroup.length;
            //         if(pgLength > 0 && this._tableHuntGroupList.length > 0){
            //             for(let i=0;i<this._ipoPagingGroup.length;i++){
            //                 let hgIdx = this._tableHuntGroupList.findIndex((el)=>{
            //                     return this._ipoPagingGroup[i]["Extension"] === el.extension;
            //                 });
            //                 if(hgIdx !== -1){
            //                     this._tableHuntGroupList[hgIdx].isPagingEnabled = true;                                
            //                 }                            
            //             } 
            //         }                    
            //         this.updatePGNotificationMsg(this._ipoPagingGroup);
            //         this.logger.debug("completed paging group");
            //         return;
            //     })
            //     .catch((error) => {
            //         return;
            //     });
            // })
            
            .then(() => {
                let notifyMsg;
                this.AAList = [];
                this._tableIncomingNumbersList = [];
                this._tableAutoAttendantList = [];
                this._autoAttendantsWarningsList = [];
                if(vmGreetingsData === undefined || vmGreetingsData === null ||
                    ignoredUsersList === undefined || ignoredUsersList === null ||
                    partialUsersList === undefined || partialUsersList === null){
                        notifyMsg = VM_ERROR_MSG.GREETINGS_MISSING;
                        this.advncdUserInfoWarningsList.push(notifyMsg);
                    }
                    this.updateAdvUserInfoTableData(vmGreetingsData,ignoredUsersList,partialUsersList);
                    if(!isRootVmpFileFound){
                         return this.firestoreService.readAutoAttendantData(cardId)
                        .then((doc:any) => {
                            let _ipoAutoAttendantList = [];
                            (doc !== null)?_ipoAutoAttendantList=JSON.parse(doc.data):_ipoAutoAttendantList=[];
                            _ipoAutoAttendantList.map(entry => {
                                let aaEntry: tableAutoAttendantData = {} as any;;
                                aaEntry.name = entry.AA_Name;
                                let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                aaEntry.menuAvailability = (menuLen === IPO_MENU_KEYS)?false:true;
                                aaEntry.ivrPrompt = false;
                                this._tableAutoAttendantList.push(aaEntry);
                                this.validateAAkeyInputs(entry) ;
                                this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                            })
                            this._tableAutoAttendantList.forEach(element => {
                                this.AAList.push(element.name)
                                 }) 
                            this.updateIVRPromptsData(aaMenuData);
                            this.logger.debug("completed auto attendant");
                            return 
                        })
                        .catch((error) => {
                            return 
                        });
                    }else{
                        this.updateComplexCallFlowWarnings(aaComplexCallFlowList, aaTimeoutList);
                        if(vmproAAParsedFlows !== undefined && vmproAAParsedFlows !== null){
                            vmproAAParsedFlows.forEach(element => {
                                let splkeys = [];
                                let numkeys = [];                                    
                                element.AA_Actions.forEach(data => {
                                    if(data.Key == "*" || data.Key == "#" || data.Key == "Fax")
                                        splkeys.push(data);
                                    else
                                        numkeys.push(data);
                                });
                                    numkeys.sort((n1,n2) => {
                                       return n1.Key - n2.Key;
                                    });
                                   numkeys = numkeys.concat(splkeys);
                                   element.AA_Actions = numkeys;   
                                });

                                vmproAAParsedFlows.map(entry => {
                                let aaEntry: tableAutoAttendantData = {} as any;
                                aaEntry.name = entry.AA_Name;
                                let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                aaEntry.menuAvailability = (menuLen === entry.AA_Actions.length)?false:true;
                                aaEntry.ivrPrompt = false;
                                this._tableAutoAttendantList.push(aaEntry);
                                this.validateAAkeyInputs(entry) ;
                                this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                            })
                            this._tableAutoAttendantList.forEach(element => {
                                this.AAList.push(element.name)
                                 }) 
                            this.updateIVRPromptsData(aaMenuData);
                            this.updateIPOSummaryDoc({No_of_AutoAttendants:this.tableAutoAttendantList.length});
                            this.logger.debug("completed auto attendant vmpro call flows");
                            return 
                        }
                    } 
                    
                             
                })
                .then(() => {
                    let notifMsg;
                    let stCodes = [];
                    if(this.steeringCodeData !== undefined && this.steeringCodeData !== null 
                        && this.steeringCodeData.length > 0){
                        this.steeringCodeData.forEach(function(stCodeData) {
                            if (!stCodes.includes(stCodeData.Steering_Code)) {
                                stCodes.push(stCodeData.Steering_Code);
                            }
                        });
                        notifMsg = TWIN_CONTACT_NUMBERS.STEERING_CODES_REMOVAL + stCodes.join(", ");
                        this.advncdUserInfoWarningsList.push(notifMsg);
                    }
                })
               
                .then(() => {
                   return this.firestoreService.readAA_ICRData(cardId)
                    .then((doc:any) => {
                        if(this.AAList.length > 0)
                        {
                            let isUpdated = doc.isUpdated;
                        this._originaltableIncomingNumbersList = (doc !== null)? JSON.parse(doc.data): [];
                        this._tableIncomingNumbersList = [];
                        this._originaltableIncomingNumbersList.forEach(entry => {
                           entry.forEach(element => {
                              if(this.AAList.includes(element.name))
                              {
                                let incomingNumberEntry: incomingNumbers ={} as any;
                                incomingNumberEntry.icrNumber=element.icrNumber;
                                incomingNumberEntry.name = element.name;
                                incomingNumberEntry.timeProfile = element.timeProfile;
                                incomingNumberEntry.companyNumbers = element.phoneNumber;
                                this._tableIncomingNumbersList.push(incomingNumberEntry);
                              }
                           })
                        });
                        this._tableIncomingNumbersListCount = this._tableIncomingNumbersList.length;
                        this.setIncomingData();                         
                        this.firestoreService.updateAA_ICRData(this._cardId,this._originaltableIncomingNumbersList,isUpdated)
                        .then(() => {
                            this.logger.debug("Updated Incoming Numbers successfully");
                        })
                        this.getCompanyPhNumbers();
                        this.logger.debug("completed incoming Numbers");

                    }
                        return resolve();
                        
                    })
                    .catch((error) => {
                        return resolve(); 
                    });
                    
                })
                .then(() => {
                    return this.firestoreService.getCardDetails(this._cardId)
                    .then((curdata:any) => {
                        this.countryName = curdata.country;
                    })
                 })
                 
                .then(() => {
                            this.reservedExtensionsList = [999,933];
                            var storage = firebase.storage();
                            var storageRef= storage.ref('reservedExtensions/reservedExtensions.json');
                            storageRef.getDownloadURL().then((url)=>{
                            var xhr = new XMLHttpRequest();
                            xhr.onload = (event) => {
                                this.ngzone.run(() => {
                                    let result = JSON.parse(xhr.response);
                                     result.forEach(data=>{
                                        if(data.country == this.countryName)
                                        {
                                            data.extensions.forEach(element => {
                                            this.reservedExtensionsList.push(element);
                                        });                  
                                    }
                                    this.validateTableData();
                                 });

                            })         
                        };
                        xhr.open('GET', url);
                        xhr.send();
                        });
                    })
                    .catch((error) => {
                        this.logger.error(error);
                           reject(error);
                       });
                })                    
         return promise;
    }    
    
    public validateTableData(){
        //this.logger.debug("isMigrationDataValid : ", this._tableUserList);
        var notifyMsg;
        this._notifications = [];
        var emptyList:tablePreviewData[];
        
        //Errors
        emptyList = this._tableUserList.filter(entry => entry.email === "");
        if(emptyList.length){
            notifyMsg = emptyList.length + " Email address are not configured";
            this.updateIPOSummaryDoc({No_Of_Users_Without_email:emptyList.length});
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        }

        //ACOS-49:Validation for the email's in the iput csv is required
        this._tableUserList.filter(entry => (!this.validateEmailEntry(entry.email))).forEach(element => {
            notifyMsg = element.username +" has invalid email id: " + element.email;
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        });

        emptyList = this._tableUserList.filter(entry => (entry.firstName === "" && entry.lastName === ""));
        if(emptyList.length){
            notifyMsg = "First and last name is not configured for " + emptyList.length + " user(s)";
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        }

        emptyList = this._tableUserList.filter(entry => (entry.acoPhoneStatus === phoneStatus.unassigned));
        if(emptyList.length){
            notifyMsg = "Phone is not configured for " + emptyList.length + " user(s)";
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        }

        // Check for duplicate emails on RC
       let dupEmailList = [];
        this._tableUserList.filter(entry =>  
            {
            if(this._assignedEmailList.includes(entry.email))
            dupEmailList.push(entry)
            });
        if(dupEmailList.length){
            notifyMsg =  dupEmailList.length + " Email(s) are already in use by ACO";
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        }

        var uniqueEmail = [];
        this._tableUserList.filter(entry => (entry.email !== "")).forEach(element => {
            if(uniqueEmail.indexOf(element.email) !== NOT_FOUND){
                notifyMsg = "Remove duplicate email id  " + element.email;
                this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR}); 
            }
            else{
                uniqueEmail.push(element.email);
            }
        });

        let acoUnassignedExtnsCount = this._acoImportData.total;
        let diff = acoUnassignedExtnsCount - this._tableUserList.length ;
        if(diff < 0){
            notifyMsg = "You have exceeded the allowed users to be migrated by " + Math.abs(diff) + ". Remove users to proceed.";
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.ERROR});
        }

        if(this._tableUserList.length === 0){
            let notifyMsg = "Cannot proceed with Migration. Users list for migration is EMPTY";
            this._notifications.push({msg: notifyMsg,severity: SEVERITY.ERROR});
        }


        //Warnings
        emptyList = this._tableUserList.filter(entry => (entry.firstName === "" && entry.lastName !== ""));
        if(emptyList.length){
            notifyMsg = "First name is not configured for " + emptyList.length + " user(s)";
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
        }

        emptyList = this._tableUserList.filter(entry => (entry.firstName !== "" && entry.lastName === ""));
        if(emptyList.length){
            notifyMsg = "Last name is not configured for " + emptyList.length + " user(s)";
            this._notifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
        }
         // Check for duplicate ExtnId on RC 
         let duplicateExtnList = [];
         this._tableUserList.filter(entry =>  
             {
             if(this._assignedExtnList.includes(JSON.stringify(entry.extension)))
             duplicateExtnList.push(entry)
             });
         if(duplicateExtnList.length){
             notifyMsg =  duplicateExtnList.length + " Extension(s) are already in use by ACO";
             this._notifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
            }
        // Validate reserved Extensions
         let resExtnList = [];
        this._tableUserList.filter(entry =>  
            {
            if(this.reservedExtensionsList.includes(entry.extension))
            resExtnList.push(entry.extension);
            });
            if(resExtnList.length){
                notifyMsg = "Extension/s " + resExtnList + " is/are reserved for special functions in  "+ this.countryName;
                this._notifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING});
               }
          this.acoExtnByPhoneGroup.forEach(element => {
            if(element.extensionList.length){
                notifyMsg = "ACO has " + element.extensionList.length + " " + element.phoneModel + " extension(s) unassigned";
                this._notifications.push({msg: notifyMsg,severity:  SEVERITY.WARNING}); 
            }
        });
    }

    private updateIPOSummaryDoc(data){
        if(this.migrationDataService.IPOSummaryData === undefined || this.migrationDataService.IPOSummaryData === null)
            this.migrationDataService.IPOSummaryData = {};
        if(this.migrationDataService.IPOSummaryData.IPOSummary === undefined || this.migrationDataService.IPOSummaryData.IPOSummary === null)
        {
            this.firestoreService.getIPOSummaryData(this._cardId)
            .then((result:any) => {          
                this.migrationDataService.IPOSummaryData = JSON.parse(result);
                let count = data.length;
                if(count > 0){
                    for(let key in data){
                        if(data[key] !== this.migrationDataService.IPOSummaryData[key]){
                            break;
                        }
                        count--;
                    }
                    if(count !== 0){
                        this.migrationDataService.IPOSummaryData.IPOSummary = {...this.migrationDataService.IPOSummaryData.IPOSummary,
                            ...data};
                        this.firestoreService.setIPOSummaryData(this._cardId, this.migrationDataService.IPOSummaryData)
                        .catch(err=>{
                            this.logger.error("Error saving IPOSummary data: "+err);
                        })
                    }
                }else{
                    this.logger.debug("IPO summary data is empty.");
                }
            })
        }else{
            this.migrationDataService.IPOSummaryData.IPOSummary = {...this.migrationDataService.IPOSummaryData.IPOSummary,
                                                                   ...data};
            this.firestoreService.setIPOSummaryData(this._cardId, this.migrationDataService.IPOSummaryData)
            .catch(err=>{
                this.logger.error("Error saving IPOSummary data: "+err);
            })
        }     
    }

    private updateHuntGroupWarnings(){
        let missingHGAnnList = this._hgWarnObj.missingHGAnnList,
         unsupportedHGAnnList = this._hgWarnObj.unsupportedHGAnnList,
         chainedAnnHGList = this._hgWarnObj.chainedAnnHGList,
         annUnCheckedHGList = this._hgWarnObj.annUnCheckedHGList,
         hgVMMissingList = this._hgWarnObj.hgVMMissingList,
         hgVMComplexList = this._hgWarnObj.hgVMComplexList;
        let notifyMsg="";
        let hgNameList = this._tableHuntGroupList.filter((entry:any)=>{
            return !entry.isPagingEnabled;
        }).map((el)=>{return el.name});
        this._huntGroupWarningsList = this._huntGroupWarningsList.filter((entry:any)=>{
            return (!(entry.includes(HG_ERROR_MSG.ANN_MISSING) ||
                      entry.includes(HG_ERROR_MSG.UNSUPPORTED_CALL_FLOW) ||
                      entry.includes(HG_ERROR_MSG.MUL_ANN_FILES) ||
                      entry.includes(HG_ERROR_MSG.ANN_BOX_UNCHECKED) ||
                      entry.includes(HG_ERROR_MSG.VM_GREETING_MISSING) || 
                      entry.includes(HG_ERROR_MSG.VM_COMPLEX_CALLFLOW)))
        });
        if(missingHGAnnList !== undefined ){
            missingHGAnnList = missingHGAnnList.filter(entry=>entry);
            let missingHGAnnFinalList = missingHGAnnList.filter((entry:any)=>{
               if(hgNameList.includes(entry)){
                   return entry;
               }
            });
            if(missingHGAnnFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.ANN_MISSING +  
                (missingHGAnnFinalList.length > 1 ? [missingHGAnnFinalList.slice(0, -1).join(', '), 
                                                    missingHGAnnFinalList.slice(-1)[0] ].join(' and ') 
                                                : missingHGAnnFinalList[0]);
                this._huntGroupWarningsList.push(notifyMsg)
            }
        }
        if(unsupportedHGAnnList !== undefined ){
            unsupportedHGAnnList = unsupportedHGAnnList.filter(entry=>entry);
            let unsupportedHGAnnFinalList = unsupportedHGAnnList.filter((entry:any)=>{
               if(hgNameList.includes(entry)){
                   return entry;
               }
            });
            if(unsupportedHGAnnFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.UNSUPPORTED_CALL_FLOW +
                (unsupportedHGAnnFinalList.length > 1 ? [unsupportedHGAnnFinalList.slice(0, -1).join(', '), 
                                                        unsupportedHGAnnFinalList.slice(-1)[0] ].join(' and ') 
                                                : unsupportedHGAnnFinalList[0]);
                this._huntGroupWarningsList.push(notifyMsg)
            }
        }
        if(chainedAnnHGList !== undefined ){
            chainedAnnHGList = chainedAnnHGList.filter(entry=>entry);
            let chainedAnnHGFinalList = chainedAnnHGList.filter((entry:any)=>{
               if(hgNameList.includes(entry)){
                   return entry;
               }
            });
            if(chainedAnnHGFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.MUL_ANN_FILES+  
                (chainedAnnHGFinalList.length > 1 ? [chainedAnnHGFinalList.slice(0, -1).join(', '), 
                                                    chainedAnnHGFinalList.slice(-1)[0] ].join(' and ') 
                                                : chainedAnnHGFinalList[0]);
                this._huntGroupWarningsList.push(notifyMsg)
            }
        }
        if(annUnCheckedHGList !== undefined ){
            annUnCheckedHGList = annUnCheckedHGList.filter(entry=>entry);
            let annUnCheckedHGFinalList = annUnCheckedHGList.filter((entry:any)=>{
               if(hgNameList.includes(entry)){
                   return entry;
               }
            });
            if(annUnCheckedHGFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.ANN_BOX_UNCHECKED+  
                (annUnCheckedHGFinalList.length > 1 ? [annUnCheckedHGFinalList.slice(0, -1).join(', '), 
                                                      annUnCheckedHGFinalList.slice(-1)[0] ].join(' and ') 
                                                : annUnCheckedHGFinalList[0]);
                this._huntGroupWarningsList.push(notifyMsg)
            }
        }
        if(hgVMMissingList !== undefined ){
            hgVMMissingList = hgVMMissingList.filter(entry=>entry);
            let missingHGVMFinalList = hgVMMissingList.filter((entry:any)=>{
               if(hgNameList.includes(entry)){
                   return entry;
               }
            });
            if(missingHGVMFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.VM_GREETING_MISSING +  
                (missingHGVMFinalList.length > 1 ? [missingHGVMFinalList.slice(0, -1).join(', '), 
                                                    missingHGVMFinalList.slice(-1)[0] ].join(' and ') 
                                                : missingHGVMFinalList[0]);
                this._huntGroupWarningsList.push(notifyMsg)
            }
        }
        if(hgVMComplexList !== undefined ){
            hgVMComplexList = hgVMComplexList.filter(entry=>entry);
            let complexHGVMFinalList = hgVMComplexList.filter((entry:any)=>{
               if(hgNameList.includes(entry)){
                   return entry;
               }
            });
            if(complexHGVMFinalList.length>0){
                notifyMsg = HG_ERROR_MSG.VM_COMPLEX_CALLFLOW +  
                (complexHGVMFinalList.length > 1 ? [complexHGVMFinalList.slice(0, -1).join(', '), 
                                                    complexHGVMFinalList.slice(-1)[0] ].join(' and ') 
                                                : complexHGVMFinalList[0]);
                this._huntGroupWarningsList.push(notifyMsg)
            }
        }
    }

    private updateAdvUserInfoTableData(userGreetingsData,ignoredUsers,partialUsers){
        var usrsWOGreetings=[], usrsWithPartialGreetings=[],ignoredUsrsList=[];
        if(userGreetingsData !== undefined || ignoredUsers !== undefined || partialUsers !== undefined){
            this._advncdUserInfoWarningsList = [];
            this._tableUserList.forEach(tableEntry=>{

                let ipoUserName = tableEntry.ipoUserName;

                let isUploadSuccess = false, isUploadPartial = false,isUserIgnored = false;
                if(userGreetingsData !== undefined){
                    isUploadSuccess = (userGreetingsData.filter((entry=> entry.Name === ipoUserName)).length) > 0 ? true : false;
                }
                tableEntry.vmGreetings = isUploadSuccess;   
                if(ignoredUsers !== undefined){
                    ignoredUsers = ignoredUsers.filter(user=>user);
                    isUserIgnored = (ignoredUsers.filter((entry=> entry === ipoUserName)).length)> 0 ? true : false;
                }
                if(partialUsers !== undefined){
                    partialUsers = partialUsers.filter(user=>user);
                    isUploadPartial = (partialUsers.filter((entry=> entry === ipoUserName)).length) > 0 ? true : false;
                }

                if(!isUploadSuccess){
                    if(isUploadPartial){
                        usrsWithPartialGreetings.push(tableEntry.username);
                    }else if(isUserIgnored){
                        ignoredUsrsList.push(tableEntry.username);
                    }else{
                        usrsWOGreetings.push(tableEntry.username);
                    }
                }
            });
            let notifyMsg;
            if(usrsWOGreetings.length > 0){
                notifyMsg = VM_ERROR_MSG.GREETINGS_MISSING + " for ";
                notifyMsg += usrsWOGreetings.length > 1 ? [usrsWOGreetings.slice(0, -1).join(', '), 
                                                    usrsWOGreetings.slice(-1)[0]].join(' and '):usrsWOGreetings[0];
                this.advncdUserInfoWarningsList.push(notifyMsg);
            }
            if(usrsWithPartialGreetings.length > 0){
                notifyMsg = VM_ERROR_MSG.PARTIAL_USERS + " for ";
                notifyMsg += usrsWithPartialGreetings.length > 1 ? [usrsWithPartialGreetings.slice(0, -1).join(', '), 
                        usrsWithPartialGreetings.slice(-1)[0]].join(' and '):usrsWithPartialGreetings[0];
                this.advncdUserInfoWarningsList.push(notifyMsg);
            }
            if(ignoredUsrsList.length > 0){
                notifyMsg = VM_ERROR_MSG.IGNORED_USERS + " for ";
                notifyMsg += ignoredUsrsList.length > 1 ? [ignoredUsrsList.slice(0, -1).join(', '), 
                    ignoredUsrsList.slice(-1)[0]].join(' and '):ignoredUsrsList[0];
                this.advncdUserInfoWarningsList.push(notifyMsg);
            }
        }else{
            this._tableUserList.forEach(tableEntry => tableEntry.vmGreetings = false);
            //Warnings list is already updated while initializing the table
        }
    }

    private updateComplexCallFlowWarnings(aaComplexCallFlowList, aaTimeoutList){
        if(aaComplexCallFlowList !== undefined){
            aaComplexCallFlowList = aaComplexCallFlowList.filter(entry=>entry);
            if(aaComplexCallFlowList.length > 0){
                let notifyMsg = AA_ERROR_MSG.COMPLEX_CALL_FLOW + 
                (aaComplexCallFlowList.length > 1 ?  [aaComplexCallFlowList.slice(0,-1).join(', '),
                aaComplexCallFlowList.slice(-1)[0]].join(' and '): aaComplexCallFlowList[0]);
                this._autoAttendantsWarningsList.push(notifyMsg);
            }
        }
        if(aaTimeoutList !== undefined){
            aaTimeoutList = aaTimeoutList.filter(entry=>entry);
            if(aaTimeoutList.length > 0){
                let notifyMsg = AA_ERROR_MSG.TIMEOUT_PRESENT + 
                (aaTimeoutList.length > 1 ?  [aaTimeoutList.slice(0,-1).join(', '),
                aaTimeoutList.slice(-1)[0]].join(' and '): aaTimeoutList[0]);
                this._autoAttendantsWarningsList.push(notifyMsg);
            }
        }
    }

    private validateAAkeyInputs(aaEntry){
        let  tempMsg:string="";
        let  faxMsg:string="";
        let  starAndHashMsg:string="";
        let  invalidDestMsg:string="";
        for(let ipoActions of aaEntry.AA_Actions){
           if(ipoActions.Key == IPOMENU_KEYACTIONS.FAX.action && ipoActions.Action != IPOMENU_KEYACTIONS.NOT_DEFINED.action){
            faxMsg = "\n" + "\t" +ipoActions.Key + " - " +  IPOMENU_KEYACTIONS.FAX.description ;
            }
           else if(ipoActions.Key == IPOMENU_KEYACTIONS.STAR.action && ipoActions.Action != IPOMENU_KEYACTIONS.REPLAY_GREETING.action && ipoActions.Action != IPOMENU_KEYACTIONS.NOT_DEFINED.action){
            starAndHashMsg +="\n"+ "\t" +ipoActions.Key + " - " +  IPOMENU_KEYACTIONS.STAR.description ;
            }
            else if(ipoActions.Key == IPOMENU_KEYACTIONS.HASH.action && ipoActions.Action != IPOMENU_KEYACTIONS.REPLAY_GREETING.action && ipoActions.Action != IPOMENU_KEYACTIONS.NOT_DEFINED.action){
            starAndHashMsg +="\n"+ "\t" + ipoActions.Key + " - " +  IPOMENU_KEYACTIONS.HASH.description;
            }
            else if(ipoActions.Key != IPOMENU_KEYACTIONS.FAX.action && ipoActions.Key != IPOMENU_KEYACTIONS.STAR.action && ipoActions.Key != IPOMENU_KEYACTIONS.HASH.action)
            {
                if(ipoActions.Destination.trim() == "")
                 tempMsg += this.fetchInvalidDestinationWarnings(ipoActions);
                else{
                    let extnNum = parseInt(ipoActions.Destination.trim());
                    if(Number.isInteger(extnNum)){
                        if((!this.isUserNotDeleted(extnNum)|| (this.isHGExtn(extnNum) && this.checkIfHGExists(extnNum)))){
                            invalidDestMsg += ipoActions.Key + " - " + ipoActions.Action  + " <"+ ipoActions.Destination + "> , ";
                        }
                    }
                }
            }
        }
        var notifyKeyInputMismatchMsg:string="";
        invalidDestMsg = (invalidDestMsg !== "") ? AA_ERROR_MSG.INVALID_DESTINATION + " for "+ invalidDestMsg :"";
        if(tempMsg.trim() != ""){
            //To remove the unnecessary comma at the end of the list
            if (tempMsg.endsWith(" , ")) {
                tempMsg = tempMsg.substring(0, tempMsg.length-2);
            }
            if(invalidDestMsg.endsWith(" , ")){
                invalidDestMsg = invalidDestMsg.substring(0, invalidDestMsg.length-2);
                invalidDestMsg = "\n\t"+invalidDestMsg;
            }
            notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : " + AA_ERROR_MSG.UNMATCHED_KEY_ACTIONS + " for " + 
            tempMsg+starAndHashMsg+faxMsg+invalidDestMsg;
        }
        else if(tempMsg.trim() == "" && (faxMsg.trim() != "" || starAndHashMsg.trim() != "" || invalidDestMsg != "")){
            notifyKeyInputMismatchMsg =  starAndHashMsg+ faxMsg;
            notifyKeyInputMismatchMsg =  notifyKeyInputMismatchMsg.trim() !== ""? notifyKeyInputMismatchMsg + "\n\t" : "";
            notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : "+ notifyKeyInputMismatchMsg + invalidDestMsg;
        }
        else {
            if(aaEntry.AA_Actions === undefined || aaEntry.AA_Actions.length === 0) notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : " + AA_ERROR_MSG.NO_KEYS_TO_MATCH;
            else notifyKeyInputMismatchMsg = aaEntry.AA_Name + " : ";
        }
        if(notifyKeyInputMismatchMsg != "")    
             this._autoAttendantsWarningsList.push(notifyKeyInputMismatchMsg);
    }

    private fetchInvalidDestinationWarnings(ipoActions) {
        let tempMsg:string="";
        switch(ipoActions.Action){

            case IPOMENU_KEYACTIONS.PARK_AND_PAGE.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.PARK_AND_PAGE.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.DIALBYNUMBER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.DIALBYNUMBER.description + " , " ;
                break;
            case IPOMENU_KEYACTIONS.REPLAY_GREETING.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.REPLAY_GREETING.description + " , " ;
                break;
            case IPOMENU_KEYACTIONS.CENTREX_TRANSFER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.CENTREX_TRANSFER.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.NORMALTRANSFER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.NORMALTRANSFER.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.TRANSFER_TO_AA.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.TRANSFER_TO_AA.description + " , ";
                break;
            case IPOMENU_KEYACTIONS.BLINDTRANSFER.action:
                tempMsg += ipoActions.Key + " - " + IPOMENU_KEYACTIONS.BLINDTRANSFER.description + " , ";
                break;
            default:
                if (ipoActions.Action !== 'Not_defined' && !ipoActions.Key.toString().startsWith("?")){
                   tempMsg += ipoActions.Key + " - " + ipoActions.Action.toString() + " , ";
                }
                break;
        }
        return tempMsg;
    }

    private updateIVRPromptsData(aaMenuData){
        if(aaMenuData != undefined){
            this._tableAutoAttendantList.forEach(aaEntry=>{
                let ivrPromptLen = aaMenuData.filter((entry=> entry.Name == aaEntry.name)).length;
                aaEntry.ivrPrompt = ivrPromptLen == 1 ? true :false;
                //TODO: Warning list flow to be updated
                if(!aaEntry.ivrPrompt){
                    const alreadyAdded = this._autoAttendantsWarningsList.find((entry:any) => (entry.includes(aaEntry.name) && entry.includes(AA_ERROR_MSG.PROMPTS_MISSING)))
                    if (alreadyAdded === undefined) this.addPromptsMissingWarning(aaEntry.name);
                } else {
                   this.addIVRPromptsRecordWarning(aaEntry);
                }
            });
            this._autoAttendantsWarningsList = this._autoAttendantsWarningsList.filter(function(warning) {
                return warning;
            });
        }
        else{
            this._tableAutoAttendantList.forEach(aaEntry=>{
                if(!aaEntry.ivrPrompt){
                    //TODO: Warning list flow to be updated
                    this.addPromptsMissingWarning(aaEntry.name);
                }
            });
             //Warnings list is already updated while initializing the table
        }
    }

    private addPromptsMissingWarning(name) {
        let index = this._autoAttendantsWarningsList.findIndex(entry=>entry.includes(name + " : "));
        if(index !== -1){
            let warningString = this._autoAttendantsWarningsList[index];
            let nameOnlyStrLen = (name+" : ").length;
            let finalString = (warningString.length === nameOnlyStrLen) ? "" : "\n\t" ;
            finalString = warningString + finalString + AA_ERROR_MSG.PROMPTS_MISSING ;
            this._autoAttendantsWarningsList[index] = finalString;
        }
    }

    private addIVRPromptsRecordWarning(aaEntry) {
        let finalString:string="";
        let index = this._autoAttendantsWarningsList.findIndex(entry=>entry.includes(aaEntry.name + " : "));
        if (index !== -1){
            let warningString = this._autoAttendantsWarningsList[index];
            let tempWarningString = warningString.replace(aaEntry.name + " : ", "").replace(AA_ERROR_MSG.PROMPTS_MISSING, "");
            if (tempWarningString.length <= JSON.stringify("").length + 2) {
                finalString = "";
            } else {
                finalString = warningString + "\n" + "\t" + reRecordMsg;
            }
            this._autoAttendantsWarningsList[index] = finalString;
        }
    }

    public updateTableData(data){
        if(data){
            if(data.trigger == GREETINGS_TRIGGER_STATUS.DONE){
                var parsedData = JSON.parse(data.greetingsData);
                this._tableIncomingNumbersListCount = 0;
                this.updateAdvUserInfoTableData(parsedData.userGreetings,parsedData.ignoredUsers,parsedData.partialUsers);
                this._hgWarnObj = {
                    missingHGAnnList:data.missingHGAnnList !== undefined ? JSON.parse(data.missingHGAnnList): undefined,
                    unsupportedHGAnnList:data.unsupportedHGAnnList !== undefined ? JSON.parse(data.unsupportedHGAnnList):undefined,
                    chainedAnnHGList:data.chainedAnnHGList !== undefined ? JSON.parse(data.chainedAnnHGList):undefined,
                    annUnCheckedHGList:data.annUnCheckedHGList !== undefined ? JSON.parse(data.annUnCheckedHGList):undefined,
                    hgVMMissingList:data.hgVMMissingList !== undefined ? JSON.parse(data.hgVMMissingList) : undefined,
                    hgVMComplexList:data.hgVMComplexList !== undefined ? JSON.parse(data.hgVMComplexList) : undefined
                }
                this.updateHuntGroupWarnings();
               // this.updatePGNotificationMsg();
                this.firestoreService.getVMProDocData(this._cardId)
                .then((_vmproData: any)=>{
                    try{
                        if(data !== null && data !== undefined){
                            let isRootVmpFileFound = (_vmproData.rootVmpFileFound !== undefined && _vmproData.rootVmpFileFound !== null && 
                                _vmproData.rootVmpFileFound !== "") ? _vmproData.rootVmpFileFound : false;
                            if(isRootVmpFileFound){
                               
                                let vmproAAParsedFlows = JSON.parse(data.vmproAAParsedFlows);
                                this._tableAutoAttendantList = [];
                                vmproAAParsedFlows.map(entry => {
                                    let aaEntry: tableAutoAttendantData = {} as any;
                                    aaEntry.name = entry.AA_Name;
                                    let menuLen = entry.AA_Actions.filter((entry => entry.Action == 'Not_defined')).length;
                                    aaEntry.menuAvailability = (menuLen === entry.AA_Actions.length)?false:true;
                                    aaEntry.ivrPrompt = false;
                                    this._tableAutoAttendantList.push(aaEntry);
                                    this.validateAAkeyInputs(entry) ;
                                    this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT);
                                    this.updateIPOSummaryDoc({No_of_AutoAttendants:this.tableAutoAttendantList.length});
                                });
                                let aaComplexCallFlowList = JSON.parse(data.aaComplexCallFlowList);
                                let aaTimeoutList = JSON.parse(data.aaTimeoutList);
                                this.updateComplexCallFlowWarnings(aaComplexCallFlowList, aaTimeoutList);
                                return this.firestoreService.readAA_ICRData(this._cardId)
                               .then((doc:any) => {

                                    this._tableAutoAttendantList.forEach(element => {
                                    this.AAList.push(element.name)
                                    })
                                    if(this.AAList.length > 0)
                                    {
                                        let isUpdated = doc.isUpdated;
                                        this._tableIncomingNumbersListCount = 0;
                                        this._originaltableIncomingNumbersList = (doc !== null)? JSON.parse(doc.data): [];
                                        this._tableIncomingNumbersList = [];
                                        this._originaltableIncomingNumbersList.forEach(entry => {
                                            entry.forEach(element => {
                                                if(this.AAList.includes(element.name))
                                                    {
                                                        let incomingNumberEntry: incomingNumbers ={} as any;
                                                        incomingNumberEntry.icrNumber=element.icrNumber;
                                                        incomingNumberEntry.name = element.name;
                                                        incomingNumberEntry.timeProfile = element.timeProfile;
                                                        incomingNumberEntry.companyNumbers = element.phoneNumber;
                                                        this._tableIncomingNumbersList.push(incomingNumberEntry);
                                                    }
                                            })
                                        });
                                        this._tableIncomingNumbersListCount = this._tableIncomingNumbersList.length;   
                                        this.setIncomingData();                    
                                        this.firestoreService.updateAA_ICRData(this._cardId,this._originaltableIncomingNumbersList,isUpdated)
                                        .then(() => {
                                            this.logger.debug("Updated Incoming Numbers successfully");
                                        })                                        
                                    }
                                })                                
                            }
                            return;
                        }
                    }
                    catch(error){
                        this.logger.debug(error);
                        return;
                }
                finally
                {
                    let aaMenuData = parsedData.aaMenuOptions;
                    if(aaMenuData){
                            this.updateIVRPromptsData(aaMenuData);
                        }
                }
                })
                .then(()=>{
                    return this.sendGreetingsDocStatus(data.trigger);
                })
            }else
                {
                    this.sendGreetingsDocStatus(data.trigger);
                }
        }
    }

    public getGreetingsDocStatus(): Observable<string>{
        return this._greetingsDocStatus.asObservable();
    }

    public sendGreetingsDocStatus(data){
        this._greetingsDocStatus.next(data);
    }
    
    public getTableDataInitializedObs(): Observable<boolean>{
        return this._tableDataInitialized.asObservable();
     }

    public informTableInitialization(){
        this._tableDataInitialized.next(true);
    }

    public getCardClosureStatus(): Observable<boolean>{
        return this._cardClosureStatus.asObservable();
    }

    public sendCardClosureStatus(data){
        this._cardClosureStatus.next(data);
    }

    private isUserNotDeleted(extension){
        let extn = parseInt(extension);
        let found = this._deletedUsersList.find((entry:any)=>{
            return entry.extension === extn;
        })
        return found === undefined;
    }

    private checkIfHGExists(extn){
        let hgList = this.tableHuntGroupList.map((entry)=>{
            if(!entry.isPagingEnabled)
                return entry.extension;
        });
        hgList = hgList.filter(el=>el);
        let found = (hgList.find(entry=>entry===extn) !== undefined);
       // console.log("HGList: "+JSON.stringify(hgList)+"\nextn:"+extn+"\tfound: "+found !== undefined);
        return found;
    }

    private isHGExtn(extension){
        let found = this.tableHuntGroupList.find((entry)=>{
                return entry.extension === extension;
        }) !== undefined;
        //console.log("Is hunt group extension: "+ found);
        return found;
    }

    private generateInitialHGnPGTableData(){
        this.logger.info("generate initial hunt groups and paging groups table data");
        var promise = new Promise((resolve,reject)=>{
             this.firestoreService.readHGnPGTableData(this._cardId)
             .then((result:any)=>{
                 if(result !== ""){
                    this._tableHuntGroupList = JSON.parse(result);
                 }
                 if(Array.isArray(this._tableHuntGroupList) && !this._tableHuntGroupList.length){
                        this._ipoHuntGroup.map(entry => {
                            let hGroupEntry: tableHuntGroupData = {} as any;;
                            hGroupEntry.extension = entry.Group.GR_Extension;
                            hGroupEntry.name = entry.Group.GR_Name;
                            hGroupEntry.overflowGroup = entry.Overflow.GR_Overflow;
                            hGroupEntry.ringMode = entry.Group.GR_RingMode;
                            hGroupEntry.isPagingEnabled = entry.Paging;
                            let members = entry.Group.GR_Extensions.filter((extn:any)=>this.isUserNotDeleted(extn));
                            let deletedMembersCount = entry.Group.GR_Extensions.length - members.length ;
                            if(deletedMembersCount === entry.Group.GR_Extensions.length &&  entry.Group.GR_Extensions.length !== 0){
                                let notifyMsg = hGroupEntry.name + " : " + HG_ERROR_MSG.ALL_MEMBERS_DELETED;
                                this.huntGroupWarningsList.push(notifyMsg);
                            }
                            hGroupEntry.totalMembers = members.length;
                            let devices =  members.filter(memExtn => {
                                return this._tableUserList.find(el=>el.extension == memExtn && el.pageCapable);
                            })|| [];
                            hGroupEntry.devices = devices;
                            this._tableHuntGroupList.push(hGroupEntry);
                        })
                }       
                 resolve();
             }).catch((error)=>{
                 reject(error);
             })
        })
        return promise;
    }

    private generateInitialTableUserList(resetFromIPOCfg = false){
        this.logger.info("generateInitialTableUserList");
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.readTableData(this._cardId)
            .then((result:any) => {
                if(result !== ""){
                    this._tableUserList = JSON.parse(result);
                }
                //this.logger.debug("Table data from DB: ", this._tableUserList);
                if((Array.isArray(this._tableUserList) && !this._tableUserList.length) || resetFromIPOCfg){
                    if(resetFromIPOCfg){
                        this._tableUserList=[];
                    }
                    for(let i=0;i<this._ipoUserList.length;i++){
                        if(this.isUserNotDeleted(parseInt(this._ipoUserList[i].Extension.US_Extension))){
                            let tempTableUser: tablePreviewData = {} as any;
                            tempTableUser.isSelected = false;
                            tempTableUser.blfList = this._ipoUserList[i].User_BLF.filter((entry:any)=>{
                                return this.isUserNotDeleted(parseInt(entry.US_Extension));
                            });
                            tempTableUser.contactList=this._ipoUserList[i].US_ContactsList.filter((entry:any)=>{
                                return this.isUserNotDeleted(parseInt(entry.Number));
                            })
                            tempTableUser.username = this._ipoUserList[i].US_Fullname;
                            tempTableUser.ipoUserName = this._ipoUserList[i].US_Name;
                            if(tempTableUser.username === ""){
                                tempTableUser.username = this._ipoUserList[i].US_Name;
                            }
                            //this.logger.debug("US_Extension",i, ", extension: ", this._ipoUserList[i].Extension.US_Extension);
                            tempTableUser.extension = parseInt(this._ipoUserList[i].Extension.US_Extension);
                            tempTableUser.email = this._ipoUserList[i].US_VoiceMailEmail || this._ipoUserList[i].US_UniqueIdentity || "";
                            tempTableUser.acoPhoneStatus = phoneStatus.unassigned;
                            tempTableUser.phone = PHONE.UNASSIGNED;
                            tempTableUser.pageCapable = false;
                            tempTableUser.migrationReady = false;
                            tempTableUser.profileType = this._ipoUserList[i].US_AssignedPackage;
                            if(this.ipoUserList[i].Phone.EX_PhoneType !== null &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== undefined &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== "" &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== "unknownSip" &&
                                this.ipoUserList[i].Phone.EX_PhoneType !== "unknownVoip"){
                                    tempTableUser.ipoPhoneModel = this.ipoUserList[i].Phone.EX_PhoneType;
                                }
                                else{
                                    tempTableUser.ipoPhoneModel = PHONE_MODEL.SOFTPHONE;
                                }

                                tempTableUser.tzOffset = '';
                                if(typeof this._ipoUserList[i].Extension.Location_Timezone === 'undefined' ||
                                this._ipoUserList[i].Extension.Location_Timezone === null){
                                    if(this._acoTimeZone !== null && this._acoTimeZone.id !== 'undefined' ){
                                        tempTableUser.timeZoneId = this._acoTimeZone.id ;
                                        tempTableUser.timeZoneName = this._acoTimeZone.description ;
                                        if(this._acoTimeZone.bias !== 'undefined'){
                                            tempTableUser.timeZoneName += " "+this.getGMTTime(this._acoTimeZone.bias);
                                            tempTableUser.tzOffset =  this._acoTimeZone.bias;
                                        }
                                    }else{
                                        tempTableUser.timeZoneId = NOT_FOUND;
                                    }
                                }
                                else{
                                    let timeZone = this._ipoUserList[i].Extension.Location_Timezone;
                                    let matchTimeZone = this._timeZoneList.find(entry => (timeZone.includes(entry.description)));
                                    tempTableUser.timeZoneName = this._ipoUserList[i].Extension.Location_Name + " " 
                                    + timeZone.split(" ")[0];
                                    if(matchTimeZone !== undefined){
                                        tempTableUser.timeZoneId = matchTimeZone.id;
                                        tempTableUser.tzOffset = matchTimeZone.bias;
                                    }
                                    else{
                                        if(this._acoTimeZone !== null && this._acoTimeZone.id !== 'undefined' ){
                                            tempTableUser.timeZoneId = this._acoTimeZone.id ;
                                            tempTableUser.timeZoneName = this._acoTimeZone.description ;
                                            if(this._acoTimeZone.bias !== 'undefined'){
                                                tempTableUser.timeZoneName += " "+this.getGMTTime(this._acoTimeZone.bias);
                                                tempTableUser.tzOffset =  this._acoTimeZone.bias;
                                            }
                                        }else{
                                            tempTableUser.timeZoneId = NOT_FOUND;
                                        }
                                    }
                                }
                                
                                tempTableUser.firstName = "";
                                tempTableUser.lastName = "";
                                tempTableUser.workHoursProfile = (typeof this._ipoUserList[i].Extension.US_UserRightsTimeProfile == 'undefined')?
                                "":this._ipoUserList[i].Extension.US_UserRightsTimeProfile;
                                
                                //Twinning data
                                tempTableUser.twinMob = "";
                                tempTableUser.twinProfile = "";
                                //ACOS-294: Migration of "No Answer Timeout" from IP Office Configuration
                                //If NoAnswerTime is not defined , then default ringCount should be 3
                                let noAnswerTimeOut = this._ipoUserList[i].US_NoAnswerTime;
                                if(noAnswerTimeOut && noAnswerTimeOut != 0){
                                    tempTableUser.ringCount = Math.ceil(noAnswerTimeOut/ACO_RING_COUNT_UNIT);
                                }else{
                                    tempTableUser.ringCount = DEFAULT_RING_COUNT;
                                }
                                if(typeof this._ipoUserList[i].Twinning !== 'undefined' && 
                                typeof this._ipoUserList[i].Twinning.US_TwinningType !== 'undefined' &&
                                this._ipoUserList[i].Twinning.US_TwinningType === PHONE_MODEL.MOBILE){
                                    tempTableUser.twinMob = this._ipoUserList[i].Twinning.US_TwinMobileNumber;
                                    tempTableUser.twinProfile = this._ipoUserList[i].Twinning.US_TwinTimeProfile;
                                }
                                this._tableUserList.push(tempTableUser);
                            }
                        }
                }
                resolve();
                
            })
            .catch(error => {
                this.logger.debug(error);
                reject(error);
            })
            
        });

        return promise;
    }
    getGMTTime(bias: any) {
        let absBias = Math.abs(bias);
        let tempBiasStr = (bias < 0 ? '-': '+')+('0'+ Math.floor(absBias / 60)).slice(-2) +":"+('0'+(absBias %60)).slice(-2);
        return "(GMT"+  tempBiasStr +")";
    }


    public autoAssignFirstAndLastName(choice: autoAssignName){
        this.logger.info("autoAssignFirstAndLastName: ", choice);
        var promise = new Promise((resolve, reject) => {            
            switch (choice){
                case autoAssignName.Fname_Lname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){ 
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.split(" ");  
                            }
							else if(ipoUser.US_Name != null) {
                                nameArray = ipoUser.US_Name.split(" ");
                            }
                            
                            if(nameArray.length !== 0){
                                lname = nameArray.pop();
                                fname = nameArray.join(" ").trim();     
                                if(fname === "") {
                                    fname = lname;
                                    lname = " ";
                                }
                            }
                            this._tableUserList[i].firstName = fname;
                            this._tableUserList[i].lastName = lname;           
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                case autoAssignName.Lname_Fname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.split(" ");  
                            }
							else if(ipoUser.US_Name != null) {
								nameArray = ipoUser.US_Name.split(" ");                               
                            }
                            
                            if(nameArray.length !== 0){
                                fname = nameArray.pop();
                                lname = nameArray.join(" ").trim();   
                                if(lname === "") {
                                    lname = fname;
                                    fname = " ";
                                }
                            }
                            this._tableUserList[i].firstName = fname;
                            this._tableUserList[i].lastName = lname;           
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                case autoAssignName.Fname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.split(" ");       
                            }
							else if(ipoUser.US_Name != null) {
								nameArray = ipoUser.US_Name.split(" ");
                            }         
                            
                            if(nameArray.length !== 0){
                                lname = nameArray.pop();
                                fname = nameArray.join(" ").trim();    
                                if(fname === "") {
                                    fname = lname;
                                    lname = "";
                                }
                            }
                            this._tableUserList[i].firstName = fname;          
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                case autoAssignName.Lname:
                {
                    for(var i=0;i<this._tableUserList.length;i++){
                        var ipoUser = this._ipoUserList.find((entry:any)=>this._tableUserList[i].extension === parseInt(entry.Extension.US_Extension));
                        if(ipoUser !== undefined){
                            let fname = " " ,lname = " ", nameArray = [];
                            if(ipoUser.US_Fullname != null && ipoUser.US_Fullname !== ""){
                                nameArray = ipoUser.US_Fullname.split(" ");          
                            }
                            else if(ipoUser.US_Name != null) {
                                nameArray = ipoUser.US_Name.split(" ");
                            }
                            
                            if(nameArray.length !== 0){
                                lname = nameArray.pop();
                                fname = nameArray.join(" ").trim();    
                                if(fname === "") {
                                    fname = lname;
                                    lname = "";
                                }
                            }
                            this._tableUserList[i].lastName = lname;           
                        }
                    }
                    this.validateTableData();
                    resolve();
                    break;
                }
                default:
                {
                    this.logger.error("Invalid choice passed: ", choice);
                    reject({reason: REJECT.INVALID_NAME_ASSIGNMENT});
                }
            }
        });

        return promise;
    }

    public autoAssignPhone(choice: autoAssignPhone, ipoChoice, acoChoice, mapCount){
        this.logger.info("autoAssignPhone: ", choice);
        var promise = new Promise((resolve, reject) => {
            switch (choice){
                case autoAssignPhone.PhoneType:
                {
                    var idx = 0; var extn;
                    var matchedUser: any, matchedProfile: any;
                    var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === acoChoice);
                    var ipoPhones = this._ipoUsersByPhone.find(entry => entry.phoneModel === ipoChoice);
                    if(ipoPhones === undefined){
                        this.logger.error("No matching phones in ipo phone model list");
                        reject({reason: REJECT.INVALID_IPO_PHONE_MODEL});
                    }

                    if(acoExtensions === undefined){
                        this.logger.error("No matching phones in aco phone model list");
                        reject({reason: REJECT.INVALID_ACO_PHONE_MODEL});
                    }
                    
                    var assignmentIncomplete = false;

                    for(var idx=0; idx < mapCount; idx++){
                        extn = ipoPhones.extensionList.shift();
                        matchedUser = this._tableUserList.find(entry => (entry.ipoPhoneModel === ipoChoice && entry.acoPhoneStatus === phoneStatus.unassigned && entry.extension === extn));
                        if(matchedUser !== undefined){
                            matchedUser.extensionId = acoExtensions.extensionList.shift();
                            matchedUser.pageCapable = this.isDevicePageCapable(matchedUser.extensionId);
                            //this.updatePGWarningList(matchedUser);
                            matchedUser.did = this.getDID(matchedUser.extensionId);
                            matchedUser.acoPhoneStatus = phoneStatus.assigned;
                            matchedUser.acoPhoneModel = acoChoice;
                            matchedUser.phone = acoChoice
                            acoExtensions.count--;
                            ipoPhones.count--;
                            matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedUser.profileType);
                            if(matchedProfile !== undefined){
                                const index = matchedProfile.extensionList.findIndex(extn => extn === matchedUser.extension);
                                matchedProfile.extensionList.splice(index, 1);
                                matchedProfile.count--;
                            }
                        }
                        else {
                            //this.logger.warn("Incomplete assignment");
                            assignmentIncomplete = true;
                        }
                    }                    
                    this.validateTableData();
                    this.updateHGnPGData();
                    if(assignmentIncomplete){
                        this.logger.warn("Incomplete assignment");
                        reject({result: REJECT.INCOMPLETE_PHONE_ASSIGNMENT});
                    }
                    resolve();
                }
                break;            
                case autoAssignPhone.UserType:
                {
                    var idx = 0; var extn;
                    var matchedUser: any, matchedProfile: any;
                    var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === acoChoice);
                    var ipoPhones = this._ipoUsersByProfile.find(entry => entry.profileType === ipoChoice);
                    if(ipoPhones === undefined){
                        this.logger.error("No matching user license type exists");
                        reject({reason: REJECT.INVALID_IPO_USER_TYPE});
                    }

                    if(acoExtensions === undefined){
                        this.logger.error("No matching phones in aco phone model list");
                        reject({reason: REJECT.INVALID_ACO_PHONE_MODEL});
                    }

                    var assignmentIncomplete = false;
                    for(var idx=0; idx < mapCount; idx++){
                        extn = ipoPhones.extensionList.shift();
                        matchedProfile = this._tableUserList.find(entry => (entry.profileType === ipoChoice && entry.acoPhoneStatus === phoneStatus.unassigned && entry.extension === extn));
                        if(matchedProfile !== undefined){
                            matchedProfile.extensionId = acoExtensions.extensionList.shift();
                            matchedProfile.pageCapable = this.isDevicePageCapable(matchedProfile.extensionId);
                           // this.updatePGWarningList(matchedProfile);
                            matchedProfile.did = this.getDID(matchedProfile.extensionId);
                            matchedProfile.acoPhoneStatus = phoneStatus.assigned;
                            matchedProfile.acoPhoneModel = acoChoice;
                            matchedProfile.phone = acoChoice;
                            acoExtensions.count--;
                            ipoPhones.count--;
                            matchedUser = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedProfile.ipoPhoneModel);
                            if(matchedUser !== undefined){
                                const index = matchedUser.extensionList.findIndex(extn => extn === matchedProfile.extension);
                                matchedUser.extensionList.splice(index, 1);
                                matchedUser.count--;
                            }
                            else {
                                assignmentIncomplete = true;
                            }
                        }
                    }

                    this.validateTableData();
                    this.updateHGnPGData();
                    if(assignmentIncomplete){
                        this.logger.warn("Incomplete assignment");
                        reject({result: REJECT.INCOMPLETE_PHONE_ASSIGNMENT});
                    }
                    resolve();
                }
                break;
                default:
                {
                    this.logger.debug("Invalid choice passed: ", choice);
                    reject({result: REJECT.INVALID_PHONE_ASSIGNMENT_CHOICE});
                }
        }
        });

        return promise;
    }

 
    public clearConfiguration(autoAssignmentconfig:AutoAssignmentConfig[]){
        var clearAll = autoAssignmentconfig.find(element => (element == AutoAssignmentConfig.All));
        if(clearAll !== undefined){
            autoAssignmentconfig = [AutoAssignmentConfig.All];
        } 
        var promise = new Promise((resolve, reject) => {
            autoAssignmentconfig.forEach(element => {
                switch(element)
                {
                    case AutoAssignmentConfig.email:
                    {
                        this._emailGroup = '';
                        this._invalidEmailList = [];
                        this._tableUserList.forEach(entry => entry.email="");
                        this.validateTableData();
                        resolve();
                        break;
                    }
                    case AutoAssignmentConfig.NameAssignment:
                    {
                        this._tableUserList.forEach(entry => { 
                            entry.firstName = "";
                            entry.lastName = ""
                        });
                        this.validateTableData();
                        resolve();
                        break;
                    }
                    case AutoAssignmentConfig.phoneAssignment:
                    {
                        this._tableUserList.forEach(entry => {
                            this.removeAssignedPhone(entry.extension, true)
                            .catch((error) => {
                            })
                        })
                        this.validateTableData();
                        //this.updateHGnPGData();
                        resolve();
                        break;
                    }
                    case AutoAssignmentConfig.All:
                    {
                        this._emailGroup = [];
                        this._invalidEmailList = [];
                        this._tableUserList = [];
                        this._acoExtnByPhoneGroup = [];
                        this._ipoUsersByPhone = [];
                        this._ipoUsersByProfile = [];
                        this.generateInitialTableUserList(true)
                        .then(() => {
                             this.fillIPOUsersByPhoneAndProfile();
                             this.fillACOUsersByPhone();
                             this.validateTableData();
                             this.updateHGnPGData();
                             resolve();
                        })
                        .catch(error => {});
                        break;
                    }
                    default:
                        this.logger.error("Invalid choice for clearConfiguration: ", element);
                        break;
                }
            });
        });

        return promise;
    }
    updateHGnPGData(user = null) {
        if(user === null){
            this.tableHuntGroupList.map((tableEntry)=>{
                let ipoHGEntry = this._ipoHuntGroup.find(entry=>entry.Group.GR_Extension === tableEntry.extension);
                let devices = ipoHGEntry.Group.GR_Extensions.filter(extn=>{
                    let usr = this.tableUserList.find((usr)=>{
                        return usr.extension.toString() === extn
                       });
                    return usr !== undefined && usr.pageCapable;
                });
                tableEntry.devices = devices;
            })
        }else{            
            this._tableHuntGroupList.map((tableEntry:tableHuntGroupData)=>{
                let hgEntry = this._ipoHuntGroup.find((entry)=>{
                    return entry.Group.GR_Extension === tableEntry.extension &&
                     entry.Group.GR_Extensions.includes(user.extension.toString());
                })
                if(hgEntry !== undefined){
                    if(user.pageCapable){
                        tableEntry.devices.push(user.extension.toString());
                    }else{
                        tableEntry.devices = tableEntry.devices.filter((el)=>el != user.extension.toString());
                    }
                }                
            })
            
        }        
    }

    public removeUsersFromTable(delUsrList){
        var promise = new Promise((resolve,reject)=>{
            let newlyDelUsers = this._tableUserList.filter((entry:any) => {return delUsrList.includes(entry.extension)});
            this._deletedUsersList.push(...newlyDelUsers);
            this.firestoreService.setDeletedUsersCfgData(this._cardId,this._deletedUsersList).then(()=>{
                this._tableUserList = this._tableUserList.filter(entry => (!delUsrList.includes(entry.extension)));
                this.fillIPOUsersByPhoneAndProfile();
                this.updateCardDetailsWithUsersCount(this._cardId,this._tableUserList.length)
                .catch((error)=>{
                    this.logger.error("Error updating the users count while deleting users: "+ error);
                })
                this.removeUsersFromHG();
                this.removeUsersFromBLFData();
                this.removeUsersFromContactsList();
                this.saveTableData(this._cardId)
                .then(()=>{
                    resolve();
                })
                .catch((error)=>{
                    this.logger.error(error);
                    //TODO: Revert the deletion
                    resolve();
                })                
            })
        })
        return promise;
    }

    removeUsersFromBLFData(){
        this._tableUserList.forEach((entry:any)=>{
            if(entry.blfList.length !=0){
                entry.blfList = entry.blfList.filter((blfData:any)=>{
                    return this.isUserNotDeleted(blfData.US_Extension);
                })
            }
        })
        
    }

    removeUsersFromContactsList(){
        this._tableUserList.forEach((entry:any)=>{
            if(entry.contactList.length !=0){
                entry.contactList = entry.contactList.filter((contact:any)=>{
                    return this.isUserNotDeleted(contact.Number);
                })
            }
        })
    }

    removeUsersFromHG(){
        this._tableHuntGroupList.forEach((entry:any)=>{
            let hgEntry = this._ipoHuntGroup.find((el:any)=>el.Group.GR_Extension===entry.extension);
            if(hgEntry !== undefined){
                let members = hgEntry.Group.GR_Extensions.filter((extn:any)=>this.isUserNotDeleted(extn));
                entry.totalMembers = members.length;
                entry.devices = members.filter((extn)=>{
                    return this.tableUserList.find((user)=>{return user.extension.toString() === extn && user.pageCapable});
                }) 
            }
        });
    }

    changeHGRole(hgUserExtnList:any){
        //console.debug("change huntgroup role:"+hgUserExtnList.toString());
        var promise = new Promise((resolve,reject)=>{
            hgUserExtnList.map((hgUserExtn)=>{
                let idx = this.tableHuntGroupList.findIndex(el=>el.extension === hgUserExtn);
                this.tableHuntGroupList[idx].isPagingEnabled = ! this.tableHuntGroupList[idx].isPagingEnabled;
            });            
            this.updateHuntGroupWarnings();       
            this.updatePGNotificationMsg();    
            resolve();
        });
        return promise;        
        
    }

    updateCardDetailsWithUsersCount(cardId,usersCount){
        this.logger.debug("updateCardDetailsWithUsersCount: "+usersCount);
        let data = {totalIpoUsers : usersCount};
        return this.firestoreService.setMTUserCardData(cardId,null,null,data);     
    }

    public removeAssignedPhone(extension, skipValidation = false){
        //.logger.debug("removeAssignedPhone");
        var promise = new Promise((resolve, reject) => {
            var matchedTableUser = this._tableUserList.find(entry => (entry.acoPhoneStatus === phoneStatus.assigned && entry.extension === extension));
            
            if(matchedTableUser === undefined){
                //this.logger.error("Unable to locate extension in table user list, remove phone assignment failed");
                reject({result: REJECT.REMOVE_PHONE_INVALID_EXTENSION})
            }
            //Re-evaluate _acoExtnByPhoneGroup
            var acoPhModelGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === matchedTableUser.acoPhoneModel);
            if (acoPhModelGroup) {
                acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                acoPhModelGroup.count++;
            }
            else{
                acoPhModelGroup = this.getExtensionGroupByPhoneModel();
                acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                acoPhModelGroup.phoneModel =  matchedTableUser.acoPhoneModel;
                acoPhModelGroup.count++;
                this._acoExtnByPhoneGroup.push(acoPhModelGroup);
            }
            
            //Re-evaluate _ipoUsersByPhone
            var phModelGroup = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
            if (phModelGroup) {
                phModelGroup.extensionList.push(matchedTableUser.extension);
                phModelGroup.count++;
            }
            else{
                phModelGroup = this.getExtensionGroupByPhoneModel();
                phModelGroup.extensionList.push(matchedTableUser.extension);
                phModelGroup.phoneModel =  matchedTableUser.ipoPhoneModel;
                phModelGroup.count++;
                this._ipoUsersByPhone.push(phModelGroup);
            }      
            
            //Re-evaluate _ipoUsersByProfile
            var ProfileGroup = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
            if (ProfileGroup) {
                ProfileGroup.extensionList.push(matchedTableUser.extension);
                ProfileGroup.count++;
            }
            else{
                ProfileGroup = this.getExtensionGroupByProfile();
                ProfileGroup.extensionList.push(matchedTableUser.extension);
                ProfileGroup.profileType =  matchedTableUser.profileType;
                ProfileGroup.count++;
                this._ipoUsersByProfile.push(ProfileGroup);
            }     
            
            matchedTableUser.extensionId = "";
            matchedTableUser.acoPhoneModel = "";
            matchedTableUser.did = "";
            matchedTableUser.phone = PHONE.UNASSIGNED;
            matchedTableUser.acoPhoneStatus = phoneStatus.unassigned;
            matchedTableUser.pageCapable = false;
            //this.updatePGWarningList(matchedTableUser);            
            if(!skipValidation){
                this.validateTableData();
            }
            this.updateHGnPGData(matchedTableUser);
            resolve();
        });

        return promise;
    }

    public assignNewPhone(extension, acoChoice){
        var promise = new Promise((resolve, reject) => {
            var matchedUser: any, matchedProfile: any;
            var acoExtensions = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === acoChoice);
            var matchedTableUser = this._tableUserList.find(entry => entry.extension === extension);

            if(acoExtensions === undefined){
                this.logger.error("Selected ACO phone model unavailable, phone assignment failed");
                reject({result: REJECT.ASSIGN_PHONE_INVALID_MODEL});
            }

            if(matchedTableUser === undefined){
                this.logger.error("Extensio unavailable for phone assignment, phone assignment failed");
                reject({result: REJECT.ASSIGN_PHONE_INVALID_EXTENSION});
            }

            var extensionId = acoExtensions.extensionList.shift();
            matchedTableUser.extensionId = extensionId;
            matchedTableUser.pageCapable = this.isDevicePageCapable(extensionId);
            //this.updatePGWarningList(matchedTableUser);
            matchedTableUser.did = this.getDID(matchedTableUser.extensionId);
            matchedTableUser.acoPhoneStatus = phoneStatus.assigned;
            matchedTableUser.phone = acoChoice;
            matchedTableUser.acoPhoneModel = acoChoice;
            acoExtensions.count--;
            
            matchedProfile = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
            const index = matchedProfile.extensionList.findIndex(extn => extn === matchedTableUser.extension);
            matchedProfile.extensionList.splice(index, 1);
            matchedProfile.count--;

            matchedUser = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
            const idx = matchedUser.extensionList.findIndex(extn => extn === matchedTableUser.extension);
            matchedUser.extensionList.splice(idx, 1);
            matchedUser.count--;
            
            this.validateTableData();
            this.updateHGnPGData(matchedTableUser);
            resolve();
        });

        return promise;
    }


    public removeAutoAssignedPhones(ipoChoice, acoChoice, mapCount){
        this.logger.debug("removeAutoAssignedPhones - ipoChoice: ", ipoChoice, " acoChoice: ", acoChoice, " mapCount: ", mapCount);
        var promise = new Promise((resolve, reject) => {

            var matchedUsers = this._tableUserList.filter(entry => (entry.ipoPhoneModel === ipoChoice 
                && entry.acoPhoneModel === acoChoice
                && entry.acoPhoneStatus === phoneStatus.assigned));
            
            if(matchedUsers.length === 0){
                matchedUsers = this._tableUserList.filter(entry => (entry.profileType === ipoChoice 
                    && entry.acoPhoneModel === acoChoice
                    && entry.acoPhoneStatus === phoneStatus.assigned));
            }

            if(matchedUsers.length === 0){
                this.logger.error("Unable to match auto phone assignments length");
                mapCount = matchedUsers.length;
            }
            
            for(var idx=0; idx<mapCount; idx++) {
                var matchedTableUser = this._tableUserList.find(entry => (entry.extension === matchedUsers[idx].extension));
        
                if(matchedTableUser === undefined){
                    this.logger.error("Unable to locate extension in table user list, remove phone assignment failed: ", matchedUsers[idx].extension);
                    continue;
                }
                //Re-evaluate _acoExtnByPhoneGroup
                var acoPhModelGroup = this._acoExtnByPhoneGroup.find(entry => entry.phoneModel === matchedTableUser.acoPhoneModel);
                if (acoPhModelGroup) {
                    acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                    acoPhModelGroup.count++;
                }
                else{
                    acoPhModelGroup = this.getExtensionGroupByPhoneModel();
                    acoPhModelGroup.extensionList.push(matchedTableUser.extensionId);
                    acoPhModelGroup.phoneModel =  matchedTableUser.acoPhoneModel;
                    acoPhModelGroup.count++;
                    this._acoExtnByPhoneGroup.push(acoPhModelGroup);
                }
                
                //Re-evaluate _ipoUsersByPhone
                var phModelGroup = this._ipoUsersByPhone.find(entry => entry.phoneModel === matchedTableUser.ipoPhoneModel);
                if (phModelGroup) {
                    phModelGroup.extensionList.push(matchedTableUser.extension);
                    phModelGroup.count++;
                }
                else{
                    phModelGroup = this.getExtensionGroupByPhoneModel();
                    phModelGroup.extensionList.push(matchedTableUser.extension);
                    phModelGroup.phoneModel =  matchedTableUser.ipoPhoneModel;
                    phModelGroup.count++;
                    this._ipoUsersByPhone.push(phModelGroup);
                }      
                
                //Re-evaluate _ipoUsersByProfile
                var ProfileGroup = this._ipoUsersByProfile.find(entry => entry.profileType === matchedTableUser.profileType);
                if (ProfileGroup) {
                    ProfileGroup.extensionList.push(matchedTableUser.extension);
                    ProfileGroup.count++;
                }
                else{
                    ProfileGroup = this.getExtensionGroupByProfile();
                    ProfileGroup.extensionList.push(matchedTableUser.extension);
                    ProfileGroup.profileType =  matchedTableUser.profileType;
                    ProfileGroup.count++;
                    this._ipoUsersByProfile.push(ProfileGroup);
                }     
                
                matchedTableUser.extensionId = "";
                matchedTableUser.acoPhoneModel = "";
                matchedTableUser.did = "";
                matchedTableUser.phone = PHONE.UNASSIGNED;
                matchedTableUser.acoPhoneStatus = phoneStatus.unassigned;
                matchedTableUser.pageCapable = false;
               // this.updatePGWarningList(matchedTableUser);
            }

            this.updateHGnPGData();
            this.validateTableData();
            resolve();
        });

        return promise;
    }

    public validateEmailEntry(value){
        var result = false;
        if(value === ''){
            return true;
        }
        result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value);
        return result && tldjs.tldExists(value);
    }
    
    public addDummyEmailIds(domainStr) {
        let domain = domainStr.split("@")[1].replace(/[,;]*$/g, "");
        this.logger.debug("Add dummy email ids: " + domain);
        this.tableUserList.forEach((user)=>{
            if(user.email !== undefined && user.email !== null && user.email === ""){
                user.email = user.extension + "@"+ domain ;
                //this.logger.debug("New user email for user "+user.extension+" "+user.email);
            }
        });
    }
    //Outlook, Gmail formats 
    public autoAssignEmail(data,validateMail){
        this.logger.debug("autoAssignEmail");
            var result;
            if(data.indexOf(';') !== NOT_FOUND){
                var emailwithNameList = data.split(';');
                result = this.splitEmailAndName(emailwithNameList);
            }
            else if(data.indexOf(',') !== NOT_FOUND){
                var emailwithNameList = data.split(',');
                result = this.splitEmailAndName(emailwithNameList);
            }
            else{
                this.logger.warn("Unmatched delimiter");
                var emailwithNameList:any = [];
                emailwithNameList.push(data);
                result = this.splitEmailAndName(emailwithNameList);
            }
            if(validateMail){
                this._invalidEmailList = [];
                if(this.checkIfMailsAreValid(result)){
                    this._emailGroup = result;
                    return true;
                }else{
                    return false;
                }
            }else{
                this._emailGroup = result;
                return true;
            }
        //this.logger.debug("autoAssignEmail - result: ", result);
    }

    private checkIfMailsAreValid(data){
        var isValid = true;
        for(var key in data){
            if(!this.validateEmailEntry(key))
            {
             isValid = false;
             this._invalidEmailList.push(key);
            } 
        }
        return isValid;
    }

    private splitEmailAndName(emailwithNameList){
        var result = {};
        if(Array.isArray(emailwithNameList)){
            emailwithNameList.forEach(entry => {
                if(entry.indexOf('<') !== NOT_FOUND){
                    var emailId = entry.substring(
                        entry.lastIndexOf("<") + 1, 
                        entry.lastIndexOf(">")
                    );
                    var name = entry.substring(0, 
                        entry.indexOf("<")
                    );
                    result[emailId] = name;
                }
                else {
                //if(entry.indexOf('@') !== NOT_FOUND){
                    let email = entry.trim();
                    result[email] = email;
                }
            });
        }
        else{
            result[emailwithNameList] = emailwithNameList;
        }
        return result;
    }

    // private updatePGWarningList(user){
    //     //Need not call this function as warnings and tablePagingGroupList are updated during initializeTableData
    //     //Uncomment the code if initializeTableData is not called from Page group component init method
    //     let pgroupArr = this._ipoPagingGroup.filter(entry=>entry.Members.includes(user.extension.toString()));
    //     this.updatePGNotificationMsg(pgroupArr);
    // }

    private updatePGNotificationMsg(){

                this._pagingGroupsWarningsList = [];

                let pgGroup:tableHuntGroupData[]  = this._tableHuntGroupList.filter(el=>{
                    return el.isPagingEnabled;
                });

                pgGroup.map((pgGroupEntry)=>{
                    let ipoHGEntry = this._ipoHuntGroup.find((entry)=>{return entry.Group.GR_Extension === pgGroupEntry.extension})
                    let members = ipoHGEntry.Group.GR_Extensions
                    //list of members who are deleted
                    let deletedMemExtns = members.filter((memExtn:any) => {
                        return !this.isUserNotDeleted(memExtn);
                        })
                    let name = ipoHGEntry.Group.GR_Name;

                    if(deletedMemExtns.length === ipoHGEntry.Group.GR_Extensions.length){
                        let notifyMsg = name +" : " + PG_ERROR_MSG.ALL_MEMBERS_DELETED;
                        //this._pagingGroupsWarningsList = this._pagingGroupsWarningsList.filter(entry=>!entry.includes(pGroupEntry.name));
                        this._pagingGroupsWarningsList.push(notifyMsg);
                        return;
                    }
                    
                    //this._pagingGroupsWarningsList = this._pagingGroupsWarningsList.filter(entry=>!entry.includes(pGroupEntry.name));
                    if(pgGroupEntry.devices.length != ipoHGEntry.Group.GR_Extensions.length){
                        let  missingMem = members.filter(el => {
                            return (pgGroupEntry.devices.indexOf(el) <0) && (deletedMemExtns.indexOf(el) === -1)
                            && !(new RegExp('^\\*').test(el));
                        });
                        
                        if(missingMem.length > 0){
                            let notifyMsg = name +" : ";
                            if(missingMem.length > 1){
                                let extnsListStr = [missingMem.slice(0, -1).join(', '), missingMem.slice(-1)[0]].join(' and ');
                                notifyMsg += PG_ERROR_MSG.MULTIPLE_EXTNS_NOT_ASSIGNED + extnsListStr ;
                            }else{
                                notifyMsg += PG_ERROR_MSG.SINGLE_EXTN_NOT_ASSIGNED + missingMem[0];
                            }
                            this._pagingGroupsWarningsList.push(notifyMsg);
                        }
                    }
                    let membersCount = pgGroupEntry.devices.length;
                    if(membersCount > PAGE_DEV_LIMIT){
                        let notifyMsg = PG_ERROR_MSG.LIMIT_RCHD ;
                        if((membersCount - PAGE_DEV_LIMIT)>1){
                            let extnsListStr = [pgGroupEntry.devices.slice(PAGE_DEV_LIMIT,-1).join(', '),pgGroupEntry.devices.slice(-1)].join(' and ');
                            notifyMsg += "members "+ extnsListStr ;
                        }else{
                            notifyMsg += "member "+ pgGroupEntry.devices.slice(-1);
                        }
                        let index = this._pagingGroupsWarningsList.findIndex(entry=>entry.includes(name));
                        let finalWarnMsg;
                        if(index !== -1){
                            finalWarnMsg = this._pagingGroupsWarningsList[index]+"\n" + notifyMsg;
                            this._pagingGroupsWarningsList[index] = finalWarnMsg;
                        }else{
                            finalWarnMsg = name +" : " + notifyMsg;
                            this._pagingGroupsWarningsList.push(finalWarnMsg);
                        }
                    }
                })
    }



    private generateAcoExportData(acoTimeProfiles){
        this.logger.info("generate aco export data");

        let timeProfiles;
        if(acoTimeProfiles && acoTimeProfiles.data !== ''){
            timeProfiles = JSON.parse(acoTimeProfiles.data);
        }

        var promise = new Promise((resolve, reject) => {
            this._extnsMap = [];
            this._acoExportUserList = [];
            this._extnBLFList = [];
            this._extncontactList = [];
            this._acoExportUserBHourList = [];
            this._acoExportFwdList = [];
            this._acoExportNonTwinUserList = [];
            this._acoExportHGUserList = [];
            this._acoExportPGUserList = [];
            let blfPresenceData = [];
          //  this._ringOnMonitoredCall = [];
           // let ringData = [];

            for(var idx=0; idx<this._tableUserList.length; idx++){
                let user = this.getAcoExportDataTemplate();
                user['extId'] = this._tableUserList[idx].extensionId;
                user['data'].contact['firstName'] =  this._tableUserList[idx].firstName;
                user['data'].contact['lastName'] =  this._tableUserList[idx].lastName;
                user['data'].contact['email'] =  this._tableUserList[idx].email;
                user['data'].contact['emailAsLoginName'] =  false;
                user['data'].status =  'Disabled';
                user['data'].extensionNumber =  this._tableUserList[idx].extension;
                
                //Enable Users migrations task by default
                this.updateFeatureTaskChain(FEATURE_NAME.USERS);

                if(this._tableUserList[idx].timeZoneId !== NOT_FOUND){
                    user['data'].regionalSettings.timezone['id'] = this._tableUserList[idx].timeZoneId;
                }
                else{
                    //Remove regional settings if IP Office CSV data does not contain it
                    delete user['data'].regionalSettings.timezone.id;
                    delete user['data'].regionalSettings.timezone;
                    delete user['data'].regionalSettings;
                }
                this._acoExportUserList.push(user);

                let extnMapEntry:any = this.getExtnMapTemplate();
                extnMapEntry['name'] = this._tableUserList[idx].ipoUserName;
                extnMapEntry['extId'] = this._tableUserList[idx].extensionId;
                extnMapEntry['extn'] = this._tableUserList[idx].extension;
                extnMapEntry['tzOffset'] = this._tableUserList[idx].tzOffset;
                this._extnsMap.push(extnMapEntry);
                
                //USER BUSINESS HOURS
                if(timeProfiles){
                    let timeLists = timeProfiles.find(entry => entry.name === this._tableUserList[idx].workHoursProfile)
                    if(timeLists){
                        this._acoExportUserBHourList.push({
                            extId: this._tableUserList[idx].extensionId,
                            data: timeLists.profiles.data
                        });
                        this.updateFeatureTaskChain(FEATURE_NAME.USER_BHOURS);
                    }
                }

                //BLF FEATURE
                if(this._tableUserList[idx].blfList.length !=0){
                    let blfExtnList = [];
                    this._tableUserList[idx].blfList.forEach(blfData => {
                        let blfExtnId= this._tableUserList.find(entry => entry.extension === parseInt(blfData.US_Extension));
                        if (blfExtnId && (blfData.BLF_Key !== "1" && blfData.BLF_Key !== "2" && blfData.BLF_Key !== "3" && 
                            parseInt(blfData.US_Extension) !== this._tableUserList[idx].extension)) {    
                            blfPresenceData.push(blfExtnId.extensionId);
                            let duplicateBLFData = blfExtnList.find(entry => entry.extension.id === blfExtnId.extensionId.toString());
                            if(!duplicateBLFData){
                                blfExtnList.push({
                                    "id": blfData.BLF_Key,
                                    "extension": {
                                        "id": blfExtnId.extensionId.toString()
                                    }
                                });
                            }else{
                                this.logger.debug(`Duplicate BLFData found- Extension:${this._tableUserList[idx].extension} key: ${blfData.BLF_Key} BLFUser ${blfData.US_Extension}`);
                                /* let notifyMsg = `Duplicate BLFData for Extenison ${this._tableUserList[idx].extension} with key ${blfData.BLF_Key} and user ${blfData.US_Extension}`;
                                this.advncdUserInfoWarningsList.push(notifyMsg); */
                            }  
                        }
                    });
                     //RING ON MONITORED CALL SETTING
                    /* if(blfExtnList.length !== 0)
                     this._ringOnMonitoredCall.push(this._tableUserList[idx].extensionId);*/
                    this._extnBLFList.push({
                        "extnId" : this._tableUserList[idx].extensionId,
                        "data": blfExtnList
                    });
                   // this._ringOnMonitoredCall = ringData;
                    this.updateFeatureTaskChain(FEATURE_NAME.USER_BLF);

                    
                }



                //PERSONAL CONTACTS FEATURE                  
                if(this._tableUserList[idx].contactList != 0)
                {
                    let userContact = this.getAcoUserPersonalContactTemplate();                           
                    userContact['extId'] = this._tableUserList[idx].extensionId;
                    let personalContactslist = [];
                    this._tableUserList[idx].contactList.map(contactsEntry => {
                            personalContactslist.push({
                                firstName : contactsEntry.Name,
                                //businessPhone :contactsEntry.Number
                                businessPhone : this.removeSteeringCodes(contactsEntry.Number)
                            });
                    })
                    userContact.data['records'] = personalContactslist;
                    this._extncontactList.push(userContact);
                    this.updateFeatureTaskChain(FEATURE_NAME.USER_CONTACTS);
                }

                //Consider Twinning migration only if assigned phone has a phone number and twinning is enabled
                if(this._tableUserList[idx].twinMob !== "" && this._tableUserList[idx].did !== ""){
                    let mobileUser = this.getCallForwardTwinUserDataTemplate();
                    mobileUser['extId'] = this._tableUserList[idx].extensionId;
                    let phoneNumber = this.removeSteeringCodes(this._tableUserList[idx].twinMob);
                    let phoneNumberDigits = phoneNumber.replace(/[^a-zA-Z0-9]/g, '');
                    phoneNumber = phoneNumberDigits.length === 10 ? phoneNumber : this._tableUserList[idx].twinMob;
                    mobileUser['data'].phoneNumber = phoneNumber;
                    mobileUser['twinRule'] = this.updateMigrationTwinRule(this._tableUserList[idx]);
                    mobileUser['twinTProfileLabel'] = this._tableUserList[idx].twinProfile;
                    mobileUser['ringCount'] = this._tableUserList[idx].ringCount;
                    this._acoExportFwdList.push(mobileUser);
                    this.updateFeatureTaskChain(FEATURE_NAME.CALL_FORWARD);
                }else{
                    let user = {} as any;
                    user['extId'] = this._tableUserList[idx].extensionId;
                  
                    user['ringCount'] =  this._tableUserList[idx].ringCount || DEFAULT_RING_COUNT;
                    user['phone'] = this._tableUserList[idx].did !== "" ? true:false;
                    this._acoExportFwdList.push(user);
                    this.updateFeatureTaskChain(FEATURE_NAME.CALL_FORWARD);
                }
            }
            this._extnPresenceTobeSet = [...new Set(blfPresenceData)];
           //this._ringOnMonitoredCall = [...new Set(ringData)];
            this._ipoHuntGroup.map(hgEntry => {
                let hGTableEntry = this.tableHuntGroupList.find((entry)=>{
                   return entry.extension === hgEntry.Group.GR_Extension;
                })
                if(hGTableEntry !== undefined){
                    let isPagingEnabled = hGTableEntry.isPagingEnabled;
                    if(!isPagingEnabled){
                        let hgUser = this.getAcoUserHuntGroupTemplate();
                        hgUser['extn'] = hgEntry.Group.GR_Extension;
                        hgUser['data']['extensionNumber'] = hgEntry.Group.GR_Extension;
                        hgUser['data'].contact['firstName'] = hgEntry.Group.GR_Name;
                        if(hgEntry.Group.GR_VoicemailEmail != ""){
                            hgUser['data'].contact['email'] = hgEntry.Group.GR_VoicemailEmail;
                        }
                        else{
                            hgUser['data'].contact['email'] = 'hgUser' + Math.floor((Math.random() * 1000)) + '@example.com';
                        }
                        
                        let extnMapEntry:any = this.getExtnMapTemplate();
                        extnMapEntry['name'] = hgEntry.Group.GR_Name;
                        extnMapEntry['extId'] = "";
                        extnMapEntry['extn'] = hgEntry.Group.GR_Extension;
                        extnMapEntry['type'] = EXTNTYPE.HUNTGROUPEXTN;
                        this._extnsMap.push(extnMapEntry);
                        
                        let members = hgEntry.Group.GR_Extensions;
                        members.map(extnEntry => {
                            let userExtn =  this._tableUserList.find(entry => parseInt(extnEntry) === entry.extension);
                            if(userExtn){
                                hgUser['membersData'].addedExtensionIds.push(userExtn.extensionId);
                            }
                        })
                        this._acoExportHGUserList.push(hgUser);
                        this.updateFeatureTaskChain(FEATURE_NAME.HUNT_GROUP);
                    }else{
                        let pgGroup = this.getPagingGroupDataTemplate();
                        pgGroup['ipoExtn'] = hgEntry.Group.GR_Extension;
                        pgGroup['data']['extensionNumber'] = hgEntry.Group.GR_Extension;
                        pgGroup['data'].contact['firstName'] = hgEntry.Group.GR_Name;
                        let members = hgEntry.Group.GR_Extensions;
                        members.map(extnEntry =>{ 
                            if(pgGroup['membersData'].addedDeviceIds.length < PAGE_DEV_LIMIT){
                                let user = this._tableUserList.find(entry => 
                                    parseInt(extnEntry) === entry.extension);
                                if(user != undefined && user.pageCapable){
                                    let dev = this._pageCapableDeviceList.find(entry => entry.extensionId === user.extensionId);
                                    if(dev)
                                        pgGroup['membersData'].addedDeviceIds.push(dev.deviceId);
                                }
                            }
                        })
                        this._acoExportPGUserList.push(pgGroup);
                    }
                }else{
                    this.logger.debug("Cannot find the huntgroup in the table data");
                }

            })
            if(this._acoExportPGUserList.length > 0)
                this.updateFeatureTaskChain(FEATURE_NAME.PAGING_GROUP);

                resolve();
        });

        return promise;
    }

    private updateFeatureTaskChain(featureName){ 
        let featureFound  = this._taskChainList.find((entry:any) =>  entry == featureName);
        if(!featureFound){
            this._taskChainList.push(featureName);
        }
    }

    private prioritizeTaskQueue(){
        let newTaskChain = [];
        TASK_QUEUE_PRIORITY.filter((entry:any) => {
            let featureFound = this._taskChainList.find((featureName:any) => featureName === entry);
            if(featureFound){
                newTaskChain.push(featureFound);
            }
        })
        this._taskChainList = newTaskChain;
    }

    private updateMigrationTwinRule(tableUser){
        let _twinRule:TwinRule;
        if(tableUser.twinProfile === tableUser.workHoursProfile){
            _twinRule = TwinRule.default;
        }
        else if(tableUser.twinProfile !== "" && tableUser.workHoursProfile == ""){
            _twinRule = TwinRule.TPForTwinNumberOnly;
        }
        else if(tableUser.twinProfile == "" && tableUser.workHoursProfile !== ""){
            _twinRule = TwinRule.TPForPrimaryNumerOnly;
        }
        else{
            _twinRule = TwinRule.TPForBoth;
        }
        this.logger.info("updateMigrationTwinRule: result = ", _twinRule);
        return _twinRule;
    }

    public setACOMigrationData(cardId){
        this.logger.info("setACOMigrationData");
        let userVM = false;
        var promise = new Promise((resolve, reject) => {
            let found = this._notifications.find(entry => (
                entry.severity === SEVERITY.ERROR));    
            if(!found){
                this.firestoreService.readTimeProfilesData(cardId).
                then((acoTimeProfiles) => {
                    return this.generateAcoExportData(acoTimeProfiles);
                })
                .then(() => {
                    return this.firestoreService.getGreetingsData(cardId)
                    .then((data:any)=>{
                        if(data){
                            let greetingsList = JSON.parse(data.greetingsData);
                            if(greetingsList.userGreetings !== undefined && greetingsList.userGreetings.length){
                                userVM = true;
                                this.updateFeatureTaskChain(FEATURE_NAME.USER_VM_WORKHOURS);
                                if(this._acoExportUserBHourList.length){
                                    this.updateFeatureTaskChain(FEATURE_NAME.USER_VM_AFTERHOURS);
                                }
                                if(this._acoExportFwdList.length){
                                    this.updateFeatureTaskChain(FEATURE_NAME.USER_VM_CUSTOMHOURS);
                                }
                            }
                            if (greetingsList.aaMenuOptions !== undefined && greetingsList.aaMenuOptions.length){
                                this.updateFeatureTaskChain(FEATURE_NAME.AUTO_ATTENDANT_PROMPTS);
                            }
                            let complexIvrPromptList = JSON.parse(data.aaComplexPromptsList);
                            if (complexIvrPromptList !== undefined && complexIvrPromptList.length > 0){
                                this.updateFeatureTaskChain(FEATURE_NAME.COMPLEX_IVR_PROMPTS);
                            }
                        }
                    }).catch((error)=>{
                        this.logger.debug(error);
                    });
                })
                .then(() => {
                    return (this.firestoreService.getTasksTemplate(cardId));
                })  
                .then((tasks) =>{
                    this.updateFeatureTaskChain(FEATURE_NAME.POST_MIGRATION);
                    this.prioritizeTaskQueue();
                    tasks[TASK_NAME.CHAIN].data = this._taskChainList;
                    tasks[TASK_NAME.USER_ASSIGN].data = this._acoExportUserList;
                    tasks[TASK_NAME.USER_CONTACTS].data = this._extncontactList;
                    tasks[TASK_NAME.USER_BUSINESS_HOURS].data = this._acoExportUserBHourList;
                    tasks[TASK_NAME.USER_CALLFORWARD].data = this._acoExportFwdList;
                    tasks[TASK_NAME.USER_BLF_ENABLEPRESENCE].data = this._extnPresenceTobeSet;
                    tasks[TASK_NAME.USER_UPDATEBLFDATA].data = this._extnBLFList;
                    tasks[TASK_NAME.HUNT_GROUP_USERDATA].data = this._acoExportHGUserList;
                    tasks[TASK_NAME.EXTENSIONS_MAP].data = this._extnsMap;
                    tasks[TASK_NAME.PAGING_GROUP_USERDATA].data = this._acoExportPGUserList;
                   // tasks[TASK_NAME.USER_UPDATERINGONMONITOREDCALLDATA].data = this._ringOnMonitoredCall;
                    return (this.firestoreService.setACOMigrationData(tasks))
                })
                .then(() => {
                    this.updateStatsSummary(cardId);
                    this.logger.debug("setACOMigrationData task is completed");
                    resolve();
                }).catch(error => {
                    this.logger.error(error);
                    reject();
                }); 
            }
            else{
                this.logger.error("Unable to trigger aco export data as errors exist. See error notification badge");
                reject();
            }
            
        });

        return promise;
    }

    public saveTableData(cardId){
        this.logger.info("saveTableData");
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.setTableData(cardId, this._tableUserList)
            .then(() => {
                this.logger.debug("table data saved successful");
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });

        return promise;
    }

    public saveHGnPGTableData(cardId){
        this.logger.info("save hunt group and paging group table data");
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.setHGnPGTableData(cardId, this._tableHuntGroupList)
            .then(() => {
                this.logger.debug("hunt group and paging group table data saved successful");
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
        return promise;
    }

    public isTableUpdated(cardId){
        this.firestoreService.readAA_ICRData(cardId)
        .then((aaICRData:any) => {
            this.isUpdatedICR = JSON.parse(aaICRData.isUpdated);
            return ;
        })
    }
    public saveCompanyData(cardId){
        this.logger.info("saveCompanyData");
        this._modifiedCompanyNumberAssignementCount = 0;
        let i = 0;
        var isUpdated = 'false';
        this._originalICRData = [];
        this._modifiedICRData = [];
        this._originaltableIncomingNumbersList.forEach(element => {
            element.forEach(entry => {
               if(this.AAList.includes(entry.name))
               {
                if(entry.phoneNumber !== this._tableIncomingNumbersList[i].companyNumbers)
                {
                    entry.phoneNumber = this._tableIncomingNumbersList[i].companyNumbers;            
                }                
                i++;   
            }
                           
        });
    });
    this.setIncomingData();
    var promise = new Promise((resolve, reject) => {
    this.firestoreService.updateAA_ICRData(cardId,this._originaltableIncomingNumbersList,isUpdated)
    .then(() => {
        this.logger.debug("table data saved successful");
        this.firestoreService.readAA_ICRData(cardId)
        .then((doc:any) => {
            let UpdatedData = (doc !== null)? JSON.parse(doc.data): [];
            UpdatedData.forEach(entry => {
                entry.forEach(element => {
                    this._modifiedICRData.push(element.phoneNumber)
                });
            });
            this.firestoreService.readOriginalAA_ICRData(cardId)
            .then((doc:any) => {
                let OriginalData = (doc !== null)? JSON.parse(doc.data): [];
                OriginalData.forEach(entry => {
                    entry.forEach(element => {
                        this._originalICRData.push(element.phoneNumber)
                    });
                });
                for (let i = 0 ; i < this._originalICRData.length ; i++)
                {
                    if (this._originalICRData[i] != this._modifiedICRData[i])
                        this._modifiedCompanyNumberAssignementCount++;
                }
                if(this._modifiedCompanyNumberAssignementCount > 0)
                    isUpdated = 'true';
                else
                    isUpdated = 'false';
                this.firestoreService.updateAA_ICRData(cardId, this._originaltableIncomingNumbersList ,isUpdated)
                .then(() => {
                    this.logger.debug("table data saved successful");
                    resolve();
                })
            })
        })
        .catch((error) => {
               reject(error);
           });
    });
})
return promise;
}

    public resetTableData(cardId){
        this.logger.info("resetTableData");
        var promise = new Promise((resolve, reject) => {
            this.firestoreService.setTableData(cardId, [])
            .then(() => {
                this.logger.debug("table data reset successfully");
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });

        return promise;
    }

    private updateStatsSummary(cardId){
        this.logger.debug("updateStatsSummary");
        let ivrPrompts = this._tableAutoAttendantList.filter(entry => entry.ivrPrompt == true).length;
        let userVMs = this._tableUserList.filter(entry => entry.vmGreetings == true).length;
        let pagingGroups = this._tableHuntGroupList.filter((entry)=>{return entry.isPagingEnabled}).length;
        let huntGroups = this._tableHuntGroupList.length - pagingGroups;
        let _stats = {
            totalAutoAttendants: this._tableAutoAttendantList.length,
            totalIVRprompts: ivrPrompts,
            totalPagingGroups: pagingGroups,
            totalHuntGroups: huntGroups,
            totalUserVoiceMails: userVMs
        }
        this.firestoreService.setMTUserCardStats(cardId, _stats);
    }

    public fetchrefreshData(cardId)
    {
        let ExtnDetails = [];
        let mailDetails = [];
        this._assignedExtnList = [];
        this._assignedEmailList = [];
        var extnData;
        var promise = new Promise((resolve, reject) => {
        this.firestoreService.getAssignedExtensions(cardId)
            .then((doc:any) => {                
                extnData = doc.data;
                ExtnDetails  = JSON.parse(extnData);
                ExtnDetails.forEach(extnId => {
                    this._assignedExtnList.push(extnId.extensionNumber);
                    mailDetails.push(extnId.contact);
                })
                mailDetails.forEach(mail => {
                this._assignedEmailList.push(mail.email);
            });
            this.firestoreService.getFeaturePrerequisite(cardId)
                .then((result:any) => {
                     return (this.firestoreService.getAcoImportData(cardId));
                })
                .then((doc:any) => {
                    if (doc !== undefined && doc.exists) {
                    this.logger.debug("ACO Import data");
                    this._acoImportData = doc.data();  
                    if (this._acoImportData.total) { 
                        this.fillIPOUsersByPhoneAndProfile();
                        this.fillACOUsersByPhone();
                        resolve();
                        return; 
                      }                                             
                    }
                    else{
                        reject();
                    }
                })
               
               })
               .catch((error) => {
                this.logger.error(error);
                reject(error);
            });
            

             }) 

    return promise;
    
    }

    private setIncomingData()
    {
        for( let i = 0 ; i < this._originaltableIncomingNumbersList.length ; i ++)
        {
            this._originaltableIncomingNumbersList[i].forEach(element => {
                if(!this.AAList.includes(element.name))
                {
                    let index = this._originaltableIncomingNumbersList[i].indexOf(element);
                    if (index > -1)
                        this._originaltableIncomingNumbersList[i].splice(index,1);
                }
             })
        }                     
    }
  
    private removeSteeringCodes(telephoneNumber) {
        let phoneNumber = telephoneNumber;
        let steeringCodes = this.steeringCodeData;
        for(let i=0; i < this.steeringCodeData.length; i++) {
            let lengthOfX: any;
            let stCodes:any = [];
            let digitsInSCTelephone = this.fetchDigitsInSCTelephone(steeringCodes[i].SC_Telephone.replace(/[^a-zA-Z0-9]/g, ''));

            //Extract all combinations of steering Codes when optional part such as [8]1N is present
            stCodes = this.extractAndPopulateSteeringCodeSet(steeringCodes[i].Steering_Code, stCodes);
            
            //Check for pattern xxx... and remove the same from steering code
            stCodes.forEach((stCode, index) => {
                if(stCode.match(/x+/)) {
                    lengthOfX = stCode.match(/x+/).join("").length;
                    stCodes[index] = stCode.substring(0, stCode.indexOf("x")); 
                }
            });

            stCodes = this.extractSteeringCodeDigits(stCodes);
            stCodes = this.removeScTelephoneDigits(digitsInSCTelephone, stCodes, steeringCodes[i]); 

            let steeringCodeRemoved: any;
            [steeringCodeRemoved, phoneNumber] = this.removeSteeringCode(phoneNumber, stCodes);
            [steeringCodeRemoved, phoneNumber] = this.validatePhoneNumber(steeringCodeRemoved, phoneNumber, lengthOfX, digitsInSCTelephone, telephoneNumber);
            if (steeringCodeRemoved) {
               break;
            }
        }
        return phoneNumber;
    }

    private fetchDigitsInSCTelephone(scTelephone) {
        if (scTelephone.includes("N") && scTelephone.indexOf("N") > 0 
            && /^[0-9].*$/.test(scTelephone.substring(0, scTelephone.indexOf("N")))) {
            return scTelephone.substring(0, scTelephone.indexOf("N"));
        } else {
            return "";
        }
    }

    private extractAndPopulateSteeringCodeSet(steeringCode, stCodes) {
        if (steeringCode.includes("[") && steeringCode.includes("]") 
        && steeringCode.substring(steeringCode.indexOf("["), steeringCode.indexOf("]")).length >0) {

            let initialMandatoryStcode = steeringCode.substring(0, steeringCode.indexOf("["));
            let optionalPart = steeringCode.substring(steeringCode.indexOf("[") + 1, steeringCode.indexOf("]"));
            let finalMandatoryStCode = steeringCode.substring(steeringCode.indexOf("]") + 1);

            stCodes.push(initialMandatoryStcode.concat(optionalPart).concat(finalMandatoryStCode).replace(/[^a-zA-Z0-9]/g, ''));
            stCodes.push(initialMandatoryStcode.concat(finalMandatoryStCode).replace(/[^a-zA-Z0-9]/g, ''));
        } else {
            stCodes.push(steeringCode.replace(/[^a-zA-Z0-9]/g, ''));
        }
        return stCodes; 
    }

    private extractSteeringCodeDigits(stCodes) {
        stCodes.forEach((stCode, index) => {
            if (stCode !== undefined && stCode.length > 0) {
                stCodes[index] = stCode.match(/\d+/g).join("");
            }
        });
        return stCodes;
    }

    //remove digits present in scTelephone field from the steeringCodes if present at the end of it
    private removeScTelephoneDigits(scTelephoneDigits, stCodes, entry) {
        stCodes.forEach((stCode, index) => {
            if (scTelephoneDigits.length > 0 && entry.SC_Telephone.length < entry.Steering_Code.length 
                && stCode.includes(scTelephoneDigits) && stCode.endsWith(scTelephoneDigits)) {
                //replace the digits present in scTelephoneDigits at the end of steeringCode with empty string
                stCodes[index] = stCode.replace(new RegExp(scTelephoneDigits+'$'), "");
            }
        });
        return stCodes;
    }

    private removeSteeringCode(phoneNumber, stCodes) {
        let steeringCodeRemoved = false;
        for(let stCode of stCodes) {
            if (stCode != null && stCode.length > 0 && phoneNumber.startsWith(stCode)) {
                phoneNumber = phoneNumber.substring(0 + stCode.length);
                steeringCodeRemoved = true;
                break;
            }
        }
        return [steeringCodeRemoved, phoneNumber];
    }

    private validatePhoneNumber(steeringCodeRemoved, phoneNumber, lengthOfX, digitsInSCTelephone, telephoneNumber) {
        //if pattern xxx* is present, then lengthOfX pattern must match the length of phoneNumber, 
        //else reinitialize the phoneNumber to original telephoneNumber
        if (steeringCodeRemoved && lengthOfX !== undefined && lengthOfX > 0) {
            if (digitsInSCTelephone && digitsInSCTelephone !== undefined 
                && digitsInSCTelephone.length > 0 && phoneNumber.startsWith(digitsInSCTelephone) 
                && phoneNumber.substring(digitsInSCTelephone.length).length !== lengthOfX) {
                    phoneNumber = telephoneNumber;
                    steeringCodeRemoved = false;
            } else if (digitsInSCTelephone && digitsInSCTelephone !== undefined 
                && digitsInSCTelephone.length > 0 && !phoneNumber.startsWith(digitsInSCTelephone)) {
                    phoneNumber = telephoneNumber;
                    steeringCodeRemoved = false;
            } else if ((digitsInSCTelephone === undefined || digitsInSCTelephone.length <=0) && phoneNumber.length !== lengthOfX) {
                phoneNumber = telephoneNumber;
                steeringCodeRemoved = false;
            }
        }
        return [steeringCodeRemoved, phoneNumber];
    }
  }
