
<div class="contentBlock migrationDataBlock">
    <div class="migrationSummary">
        <div class="parent-flex headerBlock"  style="height: 20%;">
            <p class="headerText">Migration Summary</p>
        </div>
        <div style="display: flex;flex-direction: row;width: 100%;height: 80%;">
            <div class="parent-flex" style="height: 100%;width: 15%;">
                <p class="projectStatusCount" style="color:rgba(255, 130, 0, 0.97);">
                    {{inProgressProjects}}
                </p>
                <p class="projectStatus" >
                    Migrations In Progress
                </p>
            </div>
            <div class="parent-flex" style="height: 100%;width: 15%;">
                <p class="projectStatusCount" style="color:rgba(102, 102, 102, 0.97)">
                    {{completedProjects}}
                </p>
                <p class="projectStatus" >
                    Completed Migrations
                </p>
            </div>
            <div class="parent-flex" style="height: 100%;width: 15%;">
                <p class="projectStatusCount clickable" >
                    <mat-icon style="text-align: center;" (click)="addCard()">
                        add
                    </mat-icon>
                </p>
                <p class="projectStatus" >
                    Begin New Migration
                </p>
            </div>
        </div>
    </div>
    <div class="recentMigration">
        <div class="parent-flex headerBlock"  style="height: 12%;">
            <p class="headerText" >Recent Migrations</p>
        </div>

        <div style="width: 100%;height: 88%; display: flex;flex-direction: row;align-items: center;" *ngIf="migrationCardsDetails.length != 0">
            <mat-card class="migrationCard" *ngFor = "let card of migrationCardsDetails"  (click)="showCardSummary(card.id ,card.stage , card.company)">
            
                <div class="parent-flex-row" style="margin-top: 5%;">
                    <label class="companyTitle clickable">
                        {{card.company}}
                    </label>
                    <div class="dropdown parent-flex-row">
                        <!--  <button  (click) ="openDropDown(card.id)" class="dropbtn" mat-icon-button> -->
                            <mat-icon class="dropbtn" (click) ="openDropDown($event ,card.id)">more_horiz</mat-icon>
                        <!-- </button> -->
                        <div id={{card.id}} class="dropdown-content">
                            <!--  <a class="dropdownOptions" (click) = "markAsComplete($event,card.id)">Mark as Complete</a> -->
                            <a class="dropdownOptions" *ngIf="card.lastStatus === status.FAILURE && !card.markAsCompleted" (click) = "markAsComplete($event,card.id)">Mark as Complete</a>
                            <!-- <a class="dropdownOptions disabled" *ngIf="card.lastStatus !== status.FAILURE">Mark as Complete</a> -->
                            <a class="dropdownOptions" (click) = "deleteCard($event , card.id , card.company )" *ngIf="card.stage !== stage.COMPLETE">Delete</a>
                        </div>
                    </div>
                </div>
                <label class="cardInfo clickable" style="width: 85%;word-wrap: break-word;">
                {{card.users}} Users, {{card.date}}
                </label>
                 
                <div style="position: absolute;width: 100%;bottom: 1.5vh;">
                    <label class="status clickable" *ngIf="card.lastStatus !== status.SUCCESS && !card.markAsCompleted ">{{card.percentage}}</label>
                    <img class="status migrationStatusIcon" *ngIf="card.lastStatus === status.SUCCESS" src="../../../../assets/images/migration_status/completed.svg" >
                    <img class="status migrationStatusIcon" *ngIf="card.markAsCompleted" src="../../../../assets/images/migration_status/completed-manual.svg" >
                </div>
             </mat-card>
       </div>   
       <div>
        <p class="projectStatus" style="text-align:center" *ngIf="migrationCardsDetails.length === 0">No Migrations available</p>
       </div>
    </div>
</div>

