<div mat-dialog-content>
    <p>
      <mat-icon style=" font-size: 1.8vw;color: #FF8200F7;vertical-align: text-bottom;padding-right: 1.5%;" *ngIf="isWarning">warning</mat-icon>
      {{message}}
    </p>
    
  </div>
  <div mat-dialog-actions>
    
    <button mat-button  (click)="onConfirmClicked()" cdkFocusInitial>{{confirmButtonText}}</button>
    <button mat-button (click)="onCancelClicked()">{{cancelButtonText}}</button>
  </div>