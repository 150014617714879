import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.css']
})
export class PromptDialogComponent implements OnInit {

  message:string = "Are you sure?"
  confirmButtonText:string = "Ok"
  isWarning:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<PromptDialogComponent>) { 
    
    dialogRef.disableClose = true;
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      }
      if(data.isWarning){
        this.isWarning = data.isWarning;
      }
    }
  }

  ngOnInit(): void {
  }

  onConfirmClicked(): void {
    this.dialogRef.close(true);
  }

}
