<div class="appBody" >
    <mat-toolbar id="appBar">
        <img src="../../../../assets/cloud-ring-central-4color-01.png"  alt="" class="appIcon" >
        <a routerLink="members" routerLinkActive="active">Members</a>
        
        <div class="adminAccountsBlock">
                <img [src]=accountImage class="adminAccountImage" (click)=select.open()>
                <mat-select 
                    #select
                    class="langSelect">
                    <mat-option value="option" (click)="openLogOutDialog()" style="font-size: 1vw;"><img src="../../../../assets/images/account/logout.svg" class="accountIcons">Logout</mat-option>
                </mat-select>
                <div style="height:100%;margin-left: 0.5vw;display: flex ;flex-direction: column;margin-top:5%;align-items: flex-start;">
                    <label class="adminName" [style]="(userCompany!='')?'':'margin-top:10%'">{{userName}}</label>
                    <label class="adminDetails" [style]="(userCompany!='')?'':'display:none'">{{userCompany}}</label>
                    <label class="adminDetails" >{{userRole}}</label>
                </div>
        </div>        
    </mat-toolbar>
    <router-outlet></router-outlet>
</div>

