import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayService } from '../../services/overlay/overlay.service';

@Component({
  selector: 'app-mat-spinner-overlay',
  templateUrl: './mat-spinner-overlay.component.html',
  styleUrls: ['./mat-spinner-overlay.component.css']
})
export class MatSpinnerOverlayComponent implements OnInit {

  showSpinner:boolean = false;

  constructor(private overlay:OverlayService) { 
    this.overlay.subscribeMessage().subscribe((value)=>{
      this.showSpinner = value;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.overlay.clearMessage();
  }

}
