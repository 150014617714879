import { Component, OnInit, NgZone } from '@angular/core';
import Tabulator from 'tabulator-tables';
import { FirestoreService , Result, REQUEST, STATUS, MAILTYPE } from 'src/app/shared/services/firestore/firestore.service';
import { DataService } from 'src/app/shared/services/share-data/data.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { AdminTableDisplayService } from 'src/app/shared/services/admin-table-display/admin-table-display.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-rejected-requests',
  templateUrl: './rejected-requests.component.html',
  styleUrls: ['../../../../../assets/customcss/user-requests-tabs.css']//'./rejected-requests.component.css',
})
export class RejectedRequestsComponent implements OnInit {

    userList = [];
    rejectedTable :Tabulator;
    searchInput="";
    TIMER_INTERVAL=30000;
    lastViewedUserDoc = {};
    lastViewedProfileDoc = {};
    allRead = false;
  
    constructor(private firestore:FirestoreService,private dataService:DataService,private overlay:OverlayService, 
      private logger:LogService,private adminDisplay:AdminTableDisplayService, private zone:NgZone) 
    {   
      logger.info("Rejected request constructor");     
    }
  
    timeupCallbackfunc(ol){
      ol.showSpinner = false;
      ol.closeSpinner();
    }
  
    updateRejectedSearchFilter(){
        if(this.searchInput.trim() == "")
          this.rejectedTable.clearFilter();
        else
          this.rejectedTable.setFilter(this.customFilter,this.searchInput.toLowerCase());   
      }
    
      customFilter(data,searchString){
          return (data.name.toLowerCase().includes(searchString) ||
               data.email.toLowerCase() === searchString ||
               data.company.toLowerCase().includes(searchString))
      }
      ngOnInit(): void {
        this.rejectedTable = new Tabulator("#rejected-div", {	layout:"fitDataStretch",      //fit columns to width of table
                    headerVisible:false,
                    resizableColumns:false,
                    resizableRows:false, 
                    responsiveLayout:"hide",  //hide columns that dont fit on the table
                    tooltips:true,            //allow undo and redo actions on the table
                    pagination:"local",       //paginate the data
                    paginationSize:10,
                    columns:[
                      {title:"Avatar",formatter:this.adminDisplay.printImg,  hozAlign:"justify", width:"20%"},
                      {title:"userdetails", formatter:this.adminDisplay.printUserDetails, vertAlign:"center", width:"50%"},
                      {title:"Approve",formatter:this.adminDisplay.printApproveButton, hozAlign:"right",width:"20%" ,vertAlign:"center", cellClick:(e, cell)=>{
                        this.approveUserAccess(REQUEST.APPROVE,cell);
                    }}                
                    ]           
              });
        window.addEventListener('resize',()=>{
          this.zone.run(() => {
            this.rejectedTable.redraw(); 
          });
        })
      }

      ngAfterViewInit(){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.resetPaginateQuery();
        this.fetchUserDetails();            
      }
     
      approveUserAccess(reqType,cell){
        var data =cell.getRow().getData();
        this.logger.info(data);
        this.logger.info("Request type:"+REQUEST[reqType]);
        this.firestore.addressRequest(data,reqType,false)
        .then((res:Result)=>{
              this.logger.info("User: "+res.user["id"]+"\nReason:"+res["reason"]);
              let userData = [];
              userData['name'] = data.name;
              userData['email'] = data.email;
              this.firestore.triggerEmailNotification(MAILTYPE.NOTIFY_APPROVAL , userData)
              .then(()=>{
                //TO DO
                //Show a pop-up
              })
              .catch((err)=>{
                this.logger.error(err);
              })

              const index = this.userList.indexOf(res.user);
              if(index != -1){
                this.userList.splice(index,1);
                this.dataService.setRejectedUsersCount(this.userList.length);
                this.dataService.updateUsersCount(REQUEST.APPROVE);
                this.dataService.updateUsersCount(REQUEST.NEWREQ);
                cell.getRow().delete();
                if(this.userList.length == 0){ 
                  document.getElementById("displayDiv").style.display = "none";
                }
              }
        }).catch((err:Result)=>{
              this.logger.error("Type: "+err.type+"\nReason: "+err.reason);
        })
      }

      fetchUserDetails(){
        return new Promise((resolve,reject)=>{
        var lastViewedDocId =  "";
        if(this.userList.length != 0)
        {
          lastViewedDocId = this.userList[this.userList.length-1]["id"];
        }
        
        this.firestore.getMergedUserList(REQUEST.REJECT,lastViewedDocId).then((users:[])=>{
          if(users.length == 0){
            if(this.rejectedTable.getDataCount() == 0)
              document.getElementById("displayDiv").style.display = "none";
            this.allRead= true;
            this.dataService.setRejectedUsersCount(this.userList.length);
            this.overlay.closeSpinner();
            resolve();
            return;
          }
          let len = users.length-1;
          users.forEach(user=>{
            this.userList.push(user);
          })
          if(this.rejectedTable.getDataCount() == 0){        
            this.rejectedTable.setData(users);
          }else{
            this.rejectedTable.addData(users);
          }
          this.fetchUserDetails();
        }).catch((error)=>{
          this.logger.error("Error getting users list:"+error);
        });
             
      });  
     
      }
    
      resetPaginateQuery(){
        this.allRead = false;
        this.lastViewedUserDoc = {};
        this.lastViewedProfileDoc ={};
        this.userList = [];
      }


}
