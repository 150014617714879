<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
          </button>
          <div class="migrationStepInfo">
            <span class="migrationStep">Step 5/6 : ( Configuration Summary )</span>
            <span class="migrationCompany">{{companyName}}</span>
          </div>
    </div>
    <div class="contentMain">
        <div style="display:flex;">
            <mat-toolbar id="verticalBar">
                <span class="tabItem">
                    <img src="../../../../../assets/images/config-summary/Icon_File_upload_tab.svg" class="tabIcons">
                    <a routerLink="upload-greetings-files" routerLinkActive="active"><mat-label>Upload Files</mat-label></a>
                </span>
                <span class="tabItem">
                    <img src="../../../../../assets/images/config-summary/Icon_User_data_tab.svg" class="tabIcons">
                    <a routerLink="user-data" routerLinkActive="active" ><mat-label>User Data</mat-label></a>
                </span>
                <span class="tabItem">
                    <img src="../../../../../assets/images/config-summary/Icon_System_data_tab.svg" class="tabIcons">
                    <a routerLink="system-data" routerLinkActive="active"><mat-label>System Data</mat-label></a>
                </span>
            </mat-toolbar>
            <mat-divider [vertical]="true" class="verticalDivider">
            </mat-divider>
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="navigation">
          <button mat-button class="arrowBack" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateBack()">
              <mat-icon>arrow_back</mat-icon>            
          </button>
          <mat-label class="navLabel" style="margin-right:65%;">System Summary</mat-label>
          <mat-label class="navLabel">Start Migration</mat-label>
          <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)="navigateNext()" 
           [matTooltip]="getTooltipContent()" #toolTip="matTooltip">            
              <mat-icon>arrow_forward</mat-icon>
          </button>
    </div>
</div>