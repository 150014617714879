<div class="contentBlock migrationDataBlock">
    <div id="contentInfo" class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
    </div>
    <div class="contentMain">  
        <div class="tabulatorSettings" > 
            <div style="width: 50%;display: flex;height: 100%;">
                <mat-form-field class="searchFormField"
                    [ngClass]="showErrorWarningBlock ? 'searchHide' : 'searchShow'">
                    <button mat-button matPrefix mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-label>Search group name</mat-label>
                    <input matInput type="text" [(ngModel)]="searchInput" (keyup)="updateFilter()" >
                    <button mat-button  matSuffix mat-icon-button>
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="float:right;width: 50%;height: 100%;display: flex;align-items: center;">
                <span  matTooltip="Notifications" style="margin-left:85%;margin-top: 0.8%;" (click)="getErrorWarningList()" matBadge={{errorWarningList.length}} 
                    matBadgeSize="small"  [matBadgeHidden]="errorWarningList.length == 0">
                    <img src="../../../../assets/images/notification/Notification_thicker.svg" class="notificationIcon">
                </span>
                <button [ngClass]="showErrorWarningBlock ? 'searchHide' : 'searchShow'" mat-icon-button
                    matTooltip="Settings" (click)=select.open() style="float:right;margin-right: 2%;margin-left: auto;">                    

                    <mat-icon style=" font-size: 1vw;">more_vert</mat-icon>

                    <mat-select #select class="langSelect">
                        <mat-option value="option" (click)="changeAllToPG(true)" [disabled]="groupStatus === GROUP_STATUS.ALL_PAGINGGROUPS">Change all to paging groups</mat-option>
                        <mat-option value="option" (click)="changeAllToPG(false)" [disabled]="groupStatus === GROUP_STATUS.ALL_HUNTGROUPS">Change all to hunt groups</mat-option>
                    </mat-select>
                </button>
            </div>            
        </div>
        <div id="hunt-groups-tabulator-div" class="tableDiv">

        </div>
        <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showErrorWarningBlock>
            <p class="errorTitle">
                Notifications :
            </p>
            <div style="width: 100%;height: 75%; overflow-x: hidden;overflow-y: auto;">
                <table style="width: 90%;max-height: 100%;">
                    <tr class="errorWarningRow" *ngFor="let item of errorWarningList">
                        <td>
                            {{item}}
                        </td>
                        <td>
                            <mat-icon style=" font-size: 1.1vw;color: #FF8200F7;">
                              warning
                            </mat-icon>
                        </td>
                    </tr>
                </table>
            </div>
            <button class="tableButton" style="margin-top: 1%;align-self: center;" (click)="closeErrorWarningBlock()">
                Close
            </button>
        </div>
    <button id="tableDoneBtn" class="tableButton" (click)="done()">Done</button>
    </div>
</div>