import { Component, OnInit } from '@angular/core';
import { LogService } from '../../services/logging/log.service';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public appVersion:string;
  constructor(private logger:LogService) {
    this.logger.debug("AboutComponent constructor");
    this.appVersion = environment.app_version;
   }

  ngOnInit(): void {
  }

  public async downloadLogFile() {
    this.logger.info("downloadLogFile");
    const csv = this.logger.exportLogs();
    const csvAsBlob = new Blob([csv], { type: 'text/plain' });
    const fileNameToSaveAs = 'MT-App.log';
    var url = window.URL.createObjectURL(csvAsBlob);
    saveAs(csvAsBlob, fileNameToSaveAs);
    /*         for(var i=0;i<1000; i++){
            this.logger.debug("Iterating...", i);
        } */
  }

}
