<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn"
            mat-icon-button
            matTooltip="close"
            (click)= "closeMigrationActivity()">
            <mat-icon style="font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 3/6 : ACO Account Details</span>
            <span class="migrationCompany">{{companyName}}</span>
          </div>
    </div>

    <div class="contentMain">
        <mat-form-field class="migrationInputFormField" *ngIf="showAccId">
            <mat-label>Account ID</mat-label>
            <input matInput type="text" [(ngModel)]="accountId" matTooltip="Enter the account Id of Avaya Cloud Office" required=false>
        </mat-form-field>

        <span class="errorMsg" *ngIf="showRCSignInErrMsg">
            <mat-icon class="errorMsgIcon">error</mat-icon>
            {{rcSignInErrorMsg}}
            <a style="text-decoration: underline;" (click)="triggerRcAuth()">Service Web</a>
        </span>
    </div>

    <div class="navigation">
        <button mat-button class="arrowBack" matSuffix mat-icon-button (click) = "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)= "handleACOLogin()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>

