import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export enum LogLevel {
    All,
    Debug,
    Info,
    Warn,
    Error,
    Off
}
const MAX_LOG_FILES = 3;

@Injectable({
  providedIn: 'root'
})
export class LogService {
    _level: LogLevel = LogLevel.Debug;
    _loggerName: any = "MTLogging";
    _mtLoggerCurrentIndex: any = "MTLoggerCurrentIndex";

    constructor() { 
    }

    private isLoggingAllowed(level: LogLevel): boolean {
        let ret: boolean = false;
        if ((level >= this._level &&
             level !== LogLevel.Off) ||
             this._level === LogLevel.All) {
          ret = true;
        }
        return ret;
    }

    private formatParams(params: any[]): string {
        var result: string = params.join(",");
        if (params.some(p => typeof p == "object")) {
            result = "";
            for (let item of params) {
                result += JSON.stringify(item) + ",";
            }
        }
        return result;
    }

    public debug(msg: string, ...optionalParams: any[]) {
        this.sendLog(msg, LogLevel.Debug,
                        optionalParams);
    }
        
    public info(msg: string, ...optionalParams: any[]) {
        this.sendLog(msg, LogLevel.Info,
                        optionalParams);
    }
        
    public warn(msg: string, ...optionalParams: any[]) {
        this.sendLog(msg, LogLevel.Warn,
                        optionalParams);
    }
        
    public error(msg: string, ...optionalParams: any[]) {
        this.sendLog(msg, LogLevel.Error,
                        optionalParams);
    }

    private sendLog(msg: string,
        level: LogLevel,
        params: any[]) {
        if (this.isLoggingAllowed(level)) {
            var content: string = "";
            content = new Date() + " - ";
            content += LogLevel[this._level];
            content += " : " + msg;
            if (params.length) {
                content += " : "
                + this.formatParams(params);
            }
            content += "\r\n";

            if(!environment.production){
                switch(level)
                {
                    case LogLevel.All:
                        console.trace(content);
                        break;
                    case LogLevel.Debug:
                        console.debug(content);
                        break;
                    case LogLevel.Error:
                        console.error(content);
                        break;
                    case LogLevel.Warn:
                        console.warn(content);
                        break;
                    case LogLevel.Info:
                        console.info(content);
                        break;
                    default:
                        console.log(content);
                }
            }

            var values = [];
            var curIndex;
            try {
                curIndex = localStorage.getItem(this._mtLoggerCurrentIndex);
                if(curIndex === null){
                    curIndex = "1";
                    localStorage.setItem(this._mtLoggerCurrentIndex, "1");
                }

                var loggerName = this._loggerName + "-" + curIndex;
                values = JSON.parse(
                    localStorage.getItem(loggerName))
                    || [];
                values.push(content);
                localStorage.setItem(loggerName,
                                   JSON.stringify(values));
            } 
            catch (error) {
                curIndex = parseInt(curIndex);
                curIndex++;
                if(curIndex > MAX_LOG_FILES){
                    console.log("Max files reached - start rollover strategy");
                    var loggername = this._loggerName + '-1';
                    localStorage.removeItem(loggername);
                    localStorage.setItem(this._mtLoggerCurrentIndex, "1");
                }
                else {
                    console.log("Roll over to next log index - ", curIndex);
                    var loggername = this._loggerName + '-' + curIndex;
                    if(localStorage.getItem(loggername) !== null){
                        localStorage.removeItem(loggername);                        
                    }
                    localStorage.setItem(this._mtLoggerCurrentIndex, curIndex.toString());
                }
                this.sendLog("Roll over logging due to ", LogLevel.Error, error);
            }         
        }
    }

    public clearLogs() {
        localStorage.removeItem(this._loggerName);
    }

    public exportLogs() {
        var _contents = '';
        var idx = localStorage.getItem(this._mtLoggerCurrentIndex);
        if(idx !== null){
            var curIdx = parseInt(idx);
            for(var i=1; i<=MAX_LOG_FILES; i++){
                var loggerName = this._loggerName + '-' + i;
                var data = localStorage.getItem(loggerName);
                if(data !== null){
                    _contents  = _contents + JSON.parse(data);
                }
            }
        }
        return _contents;
    }
}
