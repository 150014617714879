import { Component, OnInit , Output, EventEmitter, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { FirestoreService , Result, REQUEST } from 'src/app/shared/services/firestore/firestore.service';
import { DataService } from 'src/app/shared/services/share-data/data.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { AdminTableDisplayService } from 'src/app/shared/services/admin-table-display/admin-table-display.service';
import { app } from 'firebase';
import { resolve } from 'dns';

@Component({
  selector: 'app-approved-requests',
  templateUrl: './approved-requests.component.html',
  styleUrls: ['../../../../../assets/customcss/user-requests-tabs.css']
})
export class ApprovedRequestsComponent implements OnInit {

  userList = [];
  lastViewedUserDoc = {};
  lastViewedProfileDoc = {};
  allRead = false;
  approvedTable :Tabulator;
  searchInput="";
  TIMER_INTERVAL=30000;

  constructor(private firestore:FirestoreService,private dataService:DataService,private overlay:OverlayService, 
    private logger:LogService, private adminDisplay:AdminTableDisplayService, private zone:NgZone) 
  {
    logger.info("Approved request constructor");
  }


  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }
  
  updateApprovedSearchFilter(){
    if(this.searchInput.trim() == "")
      this.approvedTable.clearFilter();
    else
      this.approvedTable.setFilter(this.customFilter,this.searchInput.toLowerCase());   
  }

  customFilter(data,searchString){
      return (data.name.toLowerCase().includes(searchString) ||
           data.email.toLowerCase() === searchString ||
           data.company.toLowerCase().includes(searchString))
  }
  ngOnInit(): void {
    this.approvedTable = new Tabulator("#approved-div", {	layout:"fitDataStretch",
                  headerVisible:false,
                  resizableColumns:false,
                  resizableRows:false, 
                  responsiveLayout:"hide",  //hide columns that dont fit on the table
                  tooltips:true,            //allow undo and redo actions on the table
                  pagination:"local",       //paginate the data
                  paginationSize:10,
                  addRowPos:"top",
                  columns:[
                    {title:"Avatar",formatter:this.adminDisplay.printImg,  hozAlign:"justify", width:"20%"},
                    {title:"userdetails", formatter:this.adminDisplay.printUserDetails, vertAlign:"center", width:"50%"},
                    {title:"Reject",formatter:this.adminDisplay.printRejectButton, hozAlign:"right",width:"30%" , vertAlign:"center" ,cellClick:(e, cell)=>{
                      this.rejectUserAccess(REQUEST.REJECT,cell);}}                   
                   ]              
      });
      window.addEventListener('resize',()=>{
        this.zone.run(() => {
          this.approvedTable.redraw(); 
        });
      })
  }

  rejectUserAccess(reqType,cell){
    var data =cell.getRow().getData();
    this.logger.info(data);
    this.logger.info("Request type:"+REQUEST[reqType]);
    this.firestore.addressRequest(data,reqType,false)
    .then((res:Result)=>{
          this.logger.info("User: "+res.user["id"]+"\nReason:"+res["reason"]);
          const index = this.userList.indexOf(res.user);
          if(index != -1){
            this.userList.splice(index,1);
            this.dataService.setApprovedUsersCount(this.userList.length);
            this.dataService.updateUsersCount(REQUEST.REJECT);
            this.dataService.updateUsersCount(REQUEST.NEWREQ);
            cell.getRow().delete();
            if(this.userList.length == 0){ 
              document.getElementById("displayDiv").style.display = "none";
            }
          }
    }).catch((err:Result)=>{
          this.logger.error("Type: "+err.type+"\nReason: "+err.reason);
    })
  }

  ngAfterViewInit(){
      this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
      this.resetPaginateQuery();
      this.fetchUserDetails();
  }

 

  fetchUserDetails(){
    return new Promise((resolve,reject)=>{
    var lastViewedDocId =  "";
    if(this.userList.length != 0)
    {
      lastViewedDocId = this.userList[this.userList.length-1]["id"];
    }
    
    this.firestore.getMergedUserList(REQUEST.APPROVE,lastViewedDocId).then((approvedUsersSnapshot:[])=>{
      if(approvedUsersSnapshot.length == 0){
        if(this.approvedTable.getDataCount() == 0)
          document.getElementById("displayDiv").style.display = "none";
        this.allRead= true;
        this.dataService.setApprovedUsersCount(this.userList.length);
        this.overlay.closeSpinner();
        resolve();
        return;
      }
      let len = approvedUsersSnapshot.length-1;
      approvedUsersSnapshot.forEach(user=>{
        this.userList.push(user);
      })
      if(this.approvedTable.getDataCount() == 0){        
        this.approvedTable.setData(approvedUsersSnapshot);
      }else{
        this.approvedTable.addData(approvedUsersSnapshot);
      }
      this.fetchUserDetails();
    }).catch((error)=>{
      this.logger.error("Error getting users list:"+error);
    });
         
  });  
 
  }


  
  resetPaginateQuery(){
    this.allRead = false;
    this.lastViewedUserDoc = {};
    this.lastViewedProfileDoc ={};
    this.userList = [];
  }

}
