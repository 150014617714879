import { FirestoreService, MIGRATION_STAGE } from './../../../shared/services/firestore/firestore.service';
import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { Component, OnInit ,NgZone, ChangeDetectorRef } from '@angular/core';

import { Router , ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';


export enum SEVERITY{
    WARNING,
    ERROR
}

const SUMMARY_DATA = {
    ACO_SUMMARY_INFORAMTION : "Avaya Cloud Office",
    IPO_SUMMARY_INFORAMTION : "Avaya IP Office",
    SYSTEM_NAME: "System Name :",
    SYSTEM_TYPE: "System Type :",
    RELEASE: "Release : ",
    MAX_EXTN_LEN: "Maximum Extension Length :",
    TOTAL_EXTENSION: "Extensions (Total) :",
    PHONES: "Phones :",
    PROFILES: "Profiles :",
    USERS_WITHOUT_EMAIL: "Users w/o Email :",
    COMPANY: "Company :",
    COMPANY_MAIN_NUMBER: "Main Company Number :",
    COMPANY_EMAIL: "Company Email :",
    TOTAL_DL: "Available Digital Lines (Devices + DID) :",
    TOTAL_DID: "Available DID (Direct Numbers) :",
    DEVICE: "Available Device Models :",
    SOFTPHONES : "Softphones",
    HARDPHONES : "Hardphones",
    AUTOATTENDANTS: "AutoAttendants :",
    COMPANYNUMBERS: "Available Company Numbers :"
}

const COMP_NUM_TYPE = {
    MAIN_COM_NUM: "MainCompanyNumber",
    ADD_COM_NUM: "AdditionalCompanyNumber",
    COM_NUM: "CompanyNumber"
}

@Component({
  selector: 'app-migration-summary',
  templateUrl: './migration-summary.component.html',
  styleUrls: ['./migration-summary.component.css']
})
export class MigrationSummaryComponent implements OnInit {
  ipoSummaryData:any;
  ipoUserData:any;
  aaICRData:any;
  acoPhData:any;
  acoSummaryData; 
  notifications = [];
  SEVERITY = SEVERITY;
  SUMMARY_STRINGS = SUMMARY_DATA;
  readonly TIMER_INTERVAL = 120000;
  readonly MAX_IPO_EXTNS = 1000;
  id:any;
  private isRefreshed = false;
  private _enableRefreshData: boolean = false;
    public get enableRefreshData(): boolean {
        return this._enableRefreshData;
    }
    public set enableRefreshData(value: boolean) {
        this._enableRefreshData = value;
        if(document.getElementsByClassName("mat-badge-content").length !== 0){
            if(this._enableRefreshData){
                document.getElementsByClassName("mat-badge-content")[0].classList.remove("warningBadge");
            }else{
                document.getElementsByClassName("mat-badge-content")[0].classList.add("warningBadge");
            }
        }
    }

  showSummary = true;
  public companyName: string;

  constructor(private cdr: ChangeDetectorRef,public zone: NgZone , 
    public _Activatedroute:ActivatedRoute , public db:AngularFirestore,
    private router: Router,
    private migrationDataService:MigrationDataService , private firestoreService:FirestoreService,
    private overlay:OverlayService, private logger:LogService,
    private session:SessionHandlerService
    ) {
    this.logger.debug("MigrationSummaryComponent constructor");
    this.id = this.session.cardId;
    this.companyName = this.session.companyName;
    if(this.id == null){
        this.logger.warn("CardId does not exist - route to dashboard");
        this.zone.run(() => { this.router.navigate(['/home/migration']); });
        return;
    }
    this.enableRefreshData = false;

    this.acoSummaryData = {
        "dl_count": "" ,
        "did_count": "",
        "count": "",
        "total": "",
        "company": "",
        "main_number": "",
        "accountID": "",
        "companyEmailId": "",
        "maxExtensionLen": "",
        "devicemodels": "",
        "companyNumbers":"",
        "timezone":""   
    };

    this.ipoSummaryData = {
      "IPOSummary" :{
          "Max_Extension_Length": "",
          "No_Of_Users": "" , 
          "No_Of_Users_With_Extension": "",
          "No_Of_Users_Without_email": "" ,
          "No_of_Hardphone_Users": "" ,
          "No_of_Softphone_Users": "" ,
          "No_of_AutoAttendants":"",
          "SY_Name": "" ,
          "SY_Virtualized": "" ,
          "TimeZoneString": "",
          "UN_CLASS": "" ,
          "UN_MAJOR": "" ,
          "UN_MINOR": "" ,
          "UN_TYPE": "" ,
          "User_Profile":{
            "BasicUserPackage": "",
            "NonLicensedUserPackage": "",
            "OfficeWorkerUserPackage": "",
            "PowerUserPackage":  "",
            "TeleworkerUserPackage":  ""
          }  
      }
    }

    this.firestoreService.updateStage(this.id, MIGRATION_STAGE.PREVIEWSUMMARY).catch();

    if(this.migrationDataService.IPOSummaryData !== undefined && this.migrationDataService.IPOSummaryData !== null){
        //this.logger.debug("IPO Summary data from migration ds: ", this.migrationDataService.IPOSummaryData);
        this.ipoSummaryData = this.migrationDataService.IPOSummaryData;
        this.getUserProfiles();
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.setIPOSummaryData(this.id, this.migrationDataService.IPOSummaryData)
        .then(() => this.firestoreService.setIPOCardInfoData(this.id, this.migrationDataService.IPOSummaryData))
        .then(() => this.fetchACOdata())
        .then(() => {
            this.logger.debug("ACO summary data fetched");
        })
        .catch((error) => {
            this.prerequisiteFetchFailure(error);
            this.logger.error("Unable to fetch ACO summary data");
            this.overlay.closeSpinner();
        })
    }
    else{
      this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.getIPOSummaryData(this.id)
        .then((result:any) => {          
            this.migrationDataService.IPOSummaryData = JSON.parse(result);
            this.ipoSummaryData = this.migrationDataService.IPOSummaryData;
            this.getUserProfiles();
            this.logger.debug("IPO SUmmary data from firestore db: ", this.migrationDataService.IPOSummaryData);
            return;                      
        })
        .then(() => this.fetchACOdata())
        .then(() => {
            this.logger.debug("ACO summary data fetched");
        })
        .catch((error) => {
            this.prerequisiteFetchFailure(error);
            this.overlay.closeSpinner();
            this.logger.error("Unable to fetch IPO, ACO summary data");
        })
    }
  }

  getUserProfiles(){
    let profiles = "";
    let obj = this.migrationDataService.IPOSummaryData.IPOSummary.User_Profile;
    for (let key in obj){
      if(profiles !== ""){
        profiles = profiles + ", ";
      }
      profiles = profiles + key + " ("+ obj[key] + ") ";
    }
    this.ipoSummaryData.IPOSummary['Profiles'] = profiles;
  }

  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    //this.prerequisiteFetchFailure('timed out');
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  ngOnInit(): void {
  }

  prerequisiteFetchFailure(reason){
    var msg = "Unable to fetch ACO prerequisite data - " + reason;
    this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    this.enableRefreshData = true;
  }

  navigateNext(){
    let found = this.notifications.find(entry => (
      entry.severity === SEVERITY.ERROR));
    if(found === undefined){ // allow navigation }
        this.zone.run(() => { this.router.navigate(['/home/migration/config-summary']); });
    }else{
      this.getErrorWarningList();
    }
  }

  navigateBack(){
    //this.router.navigate(['/home/migration/upload-csv']);
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("Migration summary - close current Migration Activity");
    this.migrationDataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

  fetchACOdata(){
    if(this.migrationDataService.ACOSummaryData !== undefined && this.migrationDataService.ACOSummaryData !== null){
        this.logger.debug("ACO SUmmary data from migration ds: ", this.migrationDataService.ACOSummaryData);
        this.acoSummaryData = this.migrationDataService.ACOSummaryData;
        this.firestoreService.setACOSummaryData(this.id, this.migrationDataService.ACOSummaryData, this.isRefreshed)
        .then(() => this.firestoreService.setMTUserCardData(this.id, this.migrationDataService.IPOSummaryData, this.migrationDataService.ACOSummaryData))
        .then(() => {
            this.acoSummaryData = this.migrationDataService.ACOSummaryData;
            this.validatePrerequisiteData();
            this.cdr.detectChanges();
        })
        .catch((error) => {
            this.logger.error("Update failure in ACO or IPO data: ", error);
        })
    }
    else{
        this.doACOPrerequisiteInfo();
    }
  }

  doACOPrerequisiteInfo(refresh=false){
    this.logger.debug("doACOPrerequisiteInfo");
    if(refresh){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.notifications = [];
        this.enableRefreshData = false;
        this.isRefreshed = true;
    }
    this.firestoreService.getPrerequisiteInfo(this.id)
    .then(() =>{
        this.checkAcoImportStatus();
    })
    .catch((error)=>{
        this.logger.error(error);
        return;
    })
    
}
    checkAcoImportStatus(){
        this.firestoreService.checkAcoImportStatus(this.id)
        .then((curdata:any) => {  
            this.logger.debug("checkAcoImportStatus "+curdata.desc);   
            if(curdata.status == 0){
                this.getAcoImportCfg();
            }else if(curdata.status == 1){
                this.notifications.push({msg: curdata.desc, severity: SEVERITY.ERROR});
                this.enableRefreshData = true;
                this.overlay.closeSpinner();
            }      
        }).catch((err) => {
            this.overlay.closeSpinner();
        });
    }

  getAcoImportCfg() {
    this.logger.debug("getAcoImportCfg "); 
    this.firestoreService.getAcoImportData(this.id)
    .then((doc:any) => {
        if (doc.exists) {
          var curdata = doc.data();
          var phoneGroup = '';
          var ext_cnt = 0;
          if (curdata.total) {
            ext_cnt = curdata.total - (curdata.dl_cnt + curdata.did_cnt);

            this.migrationDataService.ACOImportData = curdata;
            var acoExtnByPhoneGroup = JSON.parse(curdata['extns_by_phone']);

            var idx=0;
            acoExtnByPhoneGroup.forEach(element => {
                if(idx !== 0){
                    phoneGroup = phoneGroup + ", ";
                }
                phoneGroup = phoneGroup + element.phoneModel + "(" +
                element.extensionList.length + ")";
                idx++;
            });
            this.logger.debug("phoneGroupMap: ", phoneGroup);
          }
        this.getCardInfo(curdata.dl_cnt, curdata.did_cnt , ext_cnt, phoneGroup);        
        }
    }).catch(function (error) {
        this.logger.debug("Error getting document:", error);
        this.overlay.closeSpinner();
        this.prerequisiteFetchFailure(error);
    });
   
  }

  getErrorWarningList(){
    if(this.notifications.length != 0){
        this.showSummary = false;
    }else{
        this.logger.debug("Notification is empty");
    }
    }

  closeErrorWarningBlock(){
    this.showSummary = true;
  }

  validatePrerequisiteData(){

    this.notifications = [];
    var msg;

    if((this.migrationDataService.ACOSummaryData === undefined ||
        this.migrationDataService.ACOSummaryData === null) || 
        (this.migrationDataService.IPOSummaryData === undefined || 
        this.migrationDataService.IPOSummaryData === null)){
            this.logger.warn("Ignore validating prerequisite data with aco, ipo summary");
            return;
    }

    var ipoLen = parseInt(this.migrationDataService.IPOSummaryData.IPOSummary.Max_Extension_Length);
    var acoLen = parseInt(this.migrationDataService.ACOSummaryData.maxExtensionLen);

    if(ipoLen > acoLen){
        msg = "ACO max extension length(" 
        + acoLen
        + ") is lesser than IPO max extension length(" 
        + ipoLen + ")";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }

    var ipoUsers = parseInt(this.migrationDataService.IPOSummaryData.IPOSummary.No_Of_Users);
    if(ipoUsers > this.MAX_IPO_EXTNS){
        msg = "Migration attempt for " + ipoUsers  
        + " IP Office users not allowed. Max allowed limit is for 1000 users only";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }

    if(ipoUsers > this.migrationDataService.ACOSummaryData.total){
        msg = "IP Office contains " 
        + ipoUsers
        + " user(s) but the targeted ACO has only " 
        + this.migrationDataService.ACOSummaryData.total 
        + " unassigned extension(s)";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }

    var ipoUserNoEmails = parseInt(this.migrationDataService.IPOSummaryData.IPOSummary.No_Of_Users_Without_email);
    if(ipoUserNoEmails){
        msg = ipoUserNoEmails + " users in the CSV file do not have the email address associated with them";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }

    var ipoDuplicateExtns = this.migrationDataService.IPOSummaryData.IPOSummary.Duplicate_Extensions;
    //this.logger.debug("IPO duplicate extensions: ", ipoDuplicateExtns);
    if(ipoDuplicateExtns.length){
        msg = "Remove duplicate extension(s) ";
        ipoDuplicateExtns.forEach(element => {
            msg = msg + element + ", ";
        });
        msg = msg.slice(0,-2) + " in the csv and upload again";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }

    if(this.migrationDataService.ACOSummaryData.total === 0){
        msg = "No unassigned extensions available in ACO system, please contact admin";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }
    
    if(this.migrationDataService.ACOSummaryData.dl_count === 0){
        msg = "ACO system does not contain any hardware endpoints associated with it";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }

    if(ipoUsers > this.migrationDataService.ACOSummaryData.dl_count){
        msg = "ACO system has only " 
            + this.migrationDataService.ACOSummaryData.dl_count 
            + " extension(s) with DL";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    else if(ipoUsers > this.migrationDataService.ACOSummaryData.did_count){
        msg = "ACO system has only " 
        + this.migrationDataService.ACOSummaryData.did_count 
        + " extension(s) with DID";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    else if(ipoUsers > this.migrationDataService.ACOSummaryData.count){
        msg = "ACO system has only " 
        + this.migrationDataService.ACOSummaryData.did_count 
        + " extension(s)";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }

    var errorFound = this.notifications.find(entry => entry.severity === SEVERITY.ERROR)
    if(errorFound !== undefined){
        this.enableRefreshData = true;
    }
    else{
        this.enableRefreshData = false;
    }
    this.logger.debug("VALIDATION result: ", this.notifications);
    this.overlay.closeSpinner();
  }


  getCardInfo(dl_cnt, did_cnt , extn, phoneGroup) {
    this.logger.debug("getCardInfo");
    this.firestoreService.getCardDetails(this.id)
    .then((curdata:any) => {
        
        var total = dl_cnt + did_cnt + extn;
        var local = {
          "dl_count": dl_cnt ,
          "did_count": did_cnt,
          "count": extn,
          "total": total,
          "company": curdata.company,
          "main_number": curdata.mainNumber,
          "accountID": curdata.accountId,
          "companyEmailId": curdata.companyEmailId,
          "maxExtensionLen": curdata.maxExtensionLen,
          "devicemodels": phoneGroup,
          "country": curdata.country,
          "companyNumbers":"",
          "timezone":curdata.timezone
        };
        this.firestoreService.readAA_ICRData(this.id)
        .then((aaICRData:any) => {
            this.aaICRData = (aaICRData.data)?JSON.parse(aaICRData.data):[];
            return;
        })
        .then(() => this.firestoreService.readComPhNumberData(this.id))
        .then((comPhData:any) => {
            this.acoPhData = (comPhData && comPhData.data)?JSON.parse(comPhData.data):[];         
            local.companyNumbers = comPhData.total;
            if(this.aaICRData.length){
                let isUpdated = 'false';
                let _updateAAICRList = this.aaICRData.map((icrEntries, idx) => {
                    let _updateICREntries = icrEntries.map((icrEntry, dstIdx) => {
                         //if only one Company Number exists
                         if(local.companyNumbers == '1')
                         {
                             let mainPh = this.acoPhData.find((phEntry:any) => phEntry.usageType == COMP_NUM_TYPE.MAIN_COM_NUM);
                            if(mainPh){
                                 icrEntry.phoneNumber = mainPh.phoneNumber;
                                  icrEntry.phoneId = mainPh.id;
                              }
                          }
                     else{
                        //For first index always as main phone number
                        if(idx == 0){
                            let mainPh = this.acoPhData.find((phEntry:any) => phEntry.usageType == COMP_NUM_TYPE.MAIN_COM_NUM);
                            if(mainPh){
                                icrEntry.phoneNumber = mainPh.phoneNumber;
                                icrEntry.phoneId = mainPh.id;
                            }
                        }
                        else{
                            let phEntryList = this.acoPhData.filter((phEntry:any) => phEntry.usageType != COMP_NUM_TYPE.MAIN_COM_NUM);
                            if(phEntryList.length){
                                let phEntry = phEntryList.pop();
                                icrEntry.phoneNumber = phEntry.phoneNumber;
                                icrEntry.phoneId = phEntry.id;
                            }
                        }
                    }
                        return icrEntry;
                    });
                    //console.log("_updateICREntries - ", _updateICREntries);
                    return _updateICREntries;
                });
                //console.log("_updateAAICRList: ", _updateAAICRList);
                this.firestoreService.setAA_ICRData(this.id, _updateAAICRList);
                return this.firestoreService.updateAA_ICRData(this.id, _updateAAICRList,isUpdated);
            }
            return;
        })
        .then(() => {
            return this.firestoreService.setACOSummaryData(this.id, local, this.isRefreshed)
        })
        .then(() => {
            // this.overlay.closeSpinner();
            this.migrationDataService.ACOSummaryData = local;
            this.acoSummaryData = this.migrationDataService.ACOSummaryData;
            this.cdr.detectChanges();
            this.logger.debug("aco Summary data :", this.acoSummaryData);
            return;
        })
        .then(() => this.firestoreService.setMTUserCardData(this.id, this.migrationDataService.IPOSummaryData, this.migrationDataService.ACOSummaryData))
        .then(() => this.firestoreService.triggerFeaturePrerequisites(this.id))
        .then(() => this.validatePrerequisiteData())
        .catch(error => {
          this.logger.error(error);
          this.prerequisiteFetchFailure(error);
          this.overlay.closeSpinner();
        });
    }).catch(function (error) {
      this.logger.debug("Error getting document:", error);
      this.prerequisiteFetchFailure(error);
      this.overlay.closeSpinner();
    });

  }
}
