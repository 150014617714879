<div class="contentBlock  adminContentBlock">
    <!-- <div class="contentInfo"> -->
        <mat-toolbar id="appBar">  
            
            <a routerLink="newrequests" routerLinkActive="active">New Requests &nbsp;
                <button class="badge requestsBadge" >{{requestCount}} </button>               
            </a>
            
            <a routerLink="approved" routerLinkActive="active">Approved &nbsp;
                <button class="badge approvedBadge" >{{approvedCount}}</button>
            </a>

            <a routerLink="rejected" routerLinkActive="active">Rejected &nbsp;
                <button class="badge rejectedBadge" >{{rejectedCount}}</button>
            </a>            
        </mat-toolbar>
        <router-outlet>
            <!-- <app-new-requests (messageEvent)="recvReqNotificationsMsg($event)"></app-new-requests> -->
        </router-outlet>
    <!-- </div> -->
</div>