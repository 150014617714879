import { Component, OnInit, NgZone } from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TablePreviewService,PAGINATION_SIZE } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { table } from 'console';

@Component({
  selector: 'app-incoming-numbers-preview',
  templateUrl: './incoming-numbers-preview.component.html',
  styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class IncomingNumbersPreviewComponent implements OnInit {

  searchInput:any;
  searchOption = "Entire Table";
  incomingNumbersTable : Tabulator;
  readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
  readonly TABLE_DATA_UNSAVED_PROMPT_MSG = 'Closing the current migration will discard changes in the \
  table.\nAre you sure you want to close current migration?';
  readonly TABLE_DATA_SAVE_FAILED_MSG = 'Changes made in the table could not be saved at this moment.\n';
  readonly CLOSE_TABLE_MSG = 'Do you still want to close the table view or wait for sometime and retry?';
  id:any;
  companyName:string;
  showErrorWarningBlock = false; 
  showAutoConfigSettings = false; 
  columnNames: any[] = [];
  readonly TIMER_INTERVAL = 60000;
  public errorWarningList = [];
  allowEdit:boolean = false;
  private isReadyToClose: boolean = false;
  constructor(private tablePreviewService:TablePreviewService,
    private firestoreService:FirestoreService,
    private router: Router , 
    public zone: NgZone, 
    public dialog: MatDialog,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private overlay:OverlayService, private logger:LogService) {
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
      this.allowEdit = true;
      this.searchOption = "Entire Table";
      this.isReadyToClose = false;
      this.tablePreviewService.getCardClosureStatus().subscribe((data:boolean)=>{
        this.isReadyToClose = data;
    });
  }

  canDeactivate():boolean | Observable<boolean> | Promise<boolean>{
    if(this.isReadyToClose)
     return true;
     if( this.tablePreviewService.incomingNumbers.length > 0 )
     {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
        data:{
          message: this.TABLE_DATA_UNSAVED_PROMPT_MSG,
          buttonText: {
            ok: 'Ok',
            cancel: 'Cancel'
          }
        }
      });
      return dialogRef.afterClosed();
     }
     else 
      return true;
}

  ngOnInit(): void {
    this.logger.debug("Incoming Numbers tablePreview: ngOnInit");

    this.firestoreService.readComPhNumberData(this.id)
    .then((compPhData:any) => {
      if (compPhData && compPhData.total > 1) {
        this.columnNames = [
          { title: "Auto Attendant Name", field: "name" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
              return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
          }},
          { title: "Time Profile", field: "timeProfile" , headerSort:true, sorter:"alphanum", width:"10%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
        }},
        { title: "IP Office Incoming Call Route", field: "icrNumber" , headerSort:true, sorter:"alphanum", width:"20%", formatter:(cell, formatterParams, onRendered)=>{
          return '<span style="margin-left:15%;"> <span style="color:grey";>'+cell.getValue()+'</span></span>';
      }},
          { title: "Company Numbers", field: "companyNumbers" , headerSort:true, sorter:"alphanum", editor:"select",  editorParams: this.tablePreviewService.acoPhData }
        ];   
      } else {
        this.columnNames = [
          { title: "Auto Attendant Name", field: "name" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
              return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
          }},
          { title: "Time Profile", field: "timeProfile" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
        }},
        { title: "IP Office Incoming Call Route", field: "icrNumber" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
          return '<span style="margin-left:15%;"> <span style="color:grey";>'+cell.getValue()+'</span></span>';
      }},
          { title: "Company Numbers", field: "companyNumbers" , headerSort:true, sorter:"alphanum"}
        ];   
      }
    })  
  }

  ngAfterViewInit(){
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.tablePreviewService.initializeTableData(this.id)
        .then(() =>  {
        this.incomingNumbersTable = new Tabulator("#incoming-numbers-tabulator-div",{	layout:"fitDataFill",      //fit columns to width of table
            data:this.tablePreviewService.incomingNumbers,  
            maxHeight:"100%",
            autoResize:true,
            resizableColumns:false,
            resizableRows:false, 
            responsiveLayout:'hide',  //hide columns that dont fit on the table
            tooltips:true,            //show tool tips on cells
            addRowPos:"top",          //when adding a new row, add it to the top of the table
            history:true,             //allow undo and redo actions on the table
            pagination:"local",       //paginate the data
            paginationSize:PAGINATION_SIZE,
            footerElement:this.getFooterElement(),
            dataFiltered: this.updateFooterOnFiltered,
            pageLoaded: this.updateFooterOnPgLoaded,
            movableColumns:false,
            initialSort:[             //set the initial sort order of the data
                {column:"name", dir:"asc"}
            ]
        });
        this.incomingNumbersTable.setColumns(this.columnNames);
        this.incomingNumbersTable.setData(this.tablePreviewService.incomingNumbers);
        
       // this.fetchErrorWarningData();
        this.overlay.closeSpinner();
        
        let colHeaders = document.getElementsByClassName("tabulator-col");
        for(let i=1;i<colHeaders.length;i++){
          colHeaders[i].classList.add("centerAlign");
        }
        
      })
      .catch((error) => {
        this.logger.debug(error)
        this.overlay.closeSpinner();
      });
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

 /* fetchErrorWarningData(){ 
    this.errorWarningList = this.tablePreviewService.autoAttendantsWarningsList;   
  }*/

  updateFilter(){
    if(this.searchInput !== undefined){
        var filterVal = this.searchOption;
        var typeVal = "like";
        if(filterVal){
            if(filterVal == "Entire Table"){
                //TODO: We need to setfilter for all fields

                this.incomingNumbersTable.setFilter([[                
                  {field:"name", type:typeVal, value:this.searchInput},
                  {field:"timeProfile", type:typeVal, value:this.searchInput},
                  {field:"icrNumber", type:typeVal, value:this.searchInput},
                  {field:"companyNumbers", type:typeVal, value:this.searchInput},
                ]]);
              }
            else{
                this.incomingNumbersTable.setFilter(filterVal, typeVal, this.searchInput);
            }
        }
    }
  }

  getFooterElement(){
    return '<span style="float:left;" class="customFooter" id="footerNote"></span>';
  }

  updateFooterOnPgLoaded = (pageNum)=>{
    if( this.incomingNumbersTable){
      var el = document.getElementById("footerNote");
      let currPageNum = this.incomingNumbersTable.getPage();
      if(currPageNum){
        let startIdx = ((currPageNum-1)*PAGINATION_SIZE)+1;
        let endIdx = startIdx + this.incomingNumbersTable.rowManager.displayRowsCount - 1;
        //TO DO: Change tableUserList to tableAutoAttendants
        el.innerHTML = startIdx + ' - '+ endIdx +' of '+this.tablePreviewService.incomingNumbers.length +' Auto Attendants';
      }
    }
  }
 
  updateFooterOnFiltered = (filters, rows)=>{
    var el = document.getElementById("footerNote");
    if(rows.length){      
      if(filters.length != 0 ){
      if(this.searchOption == "Entire Table" )
      {
      var displayRowsCount = this.incomingNumbersTable.rowManager.displayRowsCount;
     if(filters[0][0].value.trim() =="" || displayRowsCount >  rows.length){
displayRowsCount =  rows.length;
      }
    }
    else
    {
      var displayRowsCount = this.incomingNumbersTable.rowManager.displayRowsCount;
      if(filters[0].value.trim() =="" || displayRowsCount >  rows.length){
 displayRowsCount =  rows.length;
    }

       var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
       el.innerHTML = '1-'+ selectedCount +' of '+rows.length +' Auto Attendants';
  }
}
      else{
        if( this.incomingNumbersTable){
          let currPageNum = this.incomingNumbersTable.getPage();
          if(currPageNum){
            //TO DO: Change tableUserList to tableAutoAttendants
            el.innerHTML = '1-'+this.incomingNumbersTable.rowManager.displayRowsCount+' of '+this.tablePreviewService.incomingNumbers.length +' Auto Attendants';
          }
        }
      }
    }
    else{
      el.innerHTML = "";
    }
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.tablePreviewService.saveCompanyData(this.id)
    .then((res) => {
        this.logger.debug("table data saved");
        //this.tablePreviewService.isTableUpdated(this.id);
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
    });
    
  }



  displayTable(){
    document.getElementById("incoming-numbers-tabulator-div").style.display = "block";
    document.getElementById("tableDoneBtn").style.display = "inline-block";
    this.showErrorWarningBlock = false;
  }

 onCloseRedirectToSummaryPg(){
        this.zone.run(() => { 
            this.isReadyToClose = true;
            this.router.navigate(['/home/migration/config-summary/user-data']); 
        });
    }
  done (){
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.saveCompanyData(this.id)
            .then((res) => {
             // this.tablePreviewService.isTableUpdated(this.id);
                this.overlay.closeSpinner();
                this.isReadyToClose = true;
                this.zone.run(() => { 
                    this.router.navigate(['/home/migration/config-summary/system-data']); 
                });
            }).catch((error) => {
                this.logger.debug("saving table data failed. ",error);
                this.overlay.closeSpinner();
                this.session.alertModal(this.onCloseRedirectToSummaryPg.bind(this), 
                    this.TABLE_DATA_SAVE_FAILED_MSG + this.CLOSE_TABLE_MSG ,'Close','Retry');
            });                  
  }
}
