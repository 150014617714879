import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { ErrorData } from './../../../shared/services/parser/datainterface';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.css']
})
export class UploadCSVComponent implements OnInit {
  csvFile: any;
  csvfilename: any;
  subscription: Subscription;
  isParseFailed = false;
  errorMsg:string;
  syntaxErrorMsg:string;
  showErrorModal = false;
  parserWorker: Worker;
  morrisonWorker: Worker;
  public companyName:string = "";
  isMorrison = false;

  constructor(private router: Router , private papa: Papa, 
    private migrationDataService:MigrationDataService , public zone: NgZone,
    private logger:LogService , private session: SessionHandlerService) { 
    this.logger.debug("Upload CSV Constructor");
    this.csvfilename = this.migrationDataService.ipoCSVFileName;
    this.companyName = this.session.companyName;
  }

  ngOnInit(): void {
    if(typeof Worker !== 'undefined'){
      this.parserWorker = new Worker('src/app/shared/services/parser/ipodatacsvparser.worker', { type: 'module' })
        this.parserWorker.onmessage = (res)=>{
          //event.currentTarget.value='';
          this.isParseFailed = false;
          this.migrationDataService.ipoCSVFileName = this.csvfilename;
          this.migrationDataService.IPOSummaryData = res.data.summaryData;
          this.migrationDataService.IPOCsvData = res.data.ipoUserData;
          this.migrationDataService.IPOTimeProfile = res.data.timeProfile;
          this.migrationDataService.IPOHuntGroup = res.data.huntGroup;
          this.migrationDataService.AutoAttendant = res.data.autoAttendant;
          this.migrationDataService.IncomingCallRoute = res.data.incomingCallRoute;
         // this.migrationDataService.PagingGroup = res.data.pagingGroup;
          this.migrationDataService.ShortCodes = res.data.shortCodesData;
          this.migrationDataService.SteeringCodes = res.data.steeringCodesData;
          this.migrationDataService.IPOUserData = this.migrationDataService.IPOCsvData['IPOUsers'];
          this.zone.run(() => {this.router.navigate(['/home/migration/rcauth']); });
         // this.parserWorker.terminate();
        }
        this.parserWorker.onerror = (e) => { 
          var err:ErrorData ={
              Generic_Error:'Invalid CSV File',
              Specific_Error:e.message
          } ;
          this.handleParserFailure(err);
         // this.parserWorker.terminate();
      }
    }
  }

  ngOnDestroy(){
    this.parserWorker.terminate();
    if(this.isMorrison === true)
    this.morrisonWorker.terminate();
  }

  navigateBack(){
    this.logger.debug(" navigateBack");
    this.zone.run(() => { this.router.navigate(['/home/migration/company-info']); }); 
  }

  navigateNext(){
    //TODO: if users enters 
    //required when user comes back from RC auth page and he has IPO csv file uploaded previously
    if(this.csvfilename != null && this.csvfilename != undefined && !this.isParseFailed)
    {
      this.zone.run(() => {this.router.navigate(['/home/migration/rcauth']);})
    }
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("upload csv - close current Migration Activity");
    this.migrationDataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

  onFileChanged(event: any) {
    this.logger.debug("onFileChanged: " + event);
    var csvfiles = event.target.files;
    this.csvFile = csvfiles[0];
    this.logger.debug("The file selected: ", this.csvFile.name);
    this.csvfilename = this.csvFile.name;
    this.parse(event)
    .then(()=>{})
    .catch((err:ErrorData)=>{
      this.handleParserFailure(err);
    })
  }

  viewErrorModal(){
    this.showErrorModal = true;
  }

  sendErrorReport(){
    this.showErrorModal = false;
  }

  closeErrorModal(){
    this.showErrorModal = false;
  }


  private handleParserFailure(err : ErrorData):void{
      //event.currentTarget.value='';
      this.isParseFailed = true;
      this.errorMsg = err.Generic_Error;
      this.syntaxErrorMsg = err.Specific_Error;
      this.logger.debug("parser error :"+err.Generic_Error+ " \nSyntax error: "+err.Specific_Error);
  }

  private parse(event: any):Promise<string>{
    let that = this;
    this.csvFile = event.target.files[0];
    this.logger.debug("The file selected: ", this.csvFile.name);
    var splitfile = this.csvFile.name.split(".");
    if (splitfile.length != 0)
    {
        var fileformat = splitfile[splitfile.length - 1];
        this.logger.debug("format is  ",fileformat);
    }
    return new Promise(function(resolve,reject){
      if(fileformat == 'csv')
      {
        if(that.csvFile.size > 0){
            that.papa.parse(that.csvFile, {
              header: false,
              delimiter: ",",
              quoteChar:"",
              worker:true,
              complete: function(results) {
                if(results.data.length == 0){
                    let err = {
                        Generic_Error:'Invalid CSV File',
                        Specific_Error:'File is empty'
                    } as ErrorData;
                    reject(err);
                    return;
                }
                var err = that.validateCSVFile(results.data);
                if( err == null)
                {
                  if(that.isMorrison === true)
                  {
                  that.morrisonWorker = new Worker('src/app/shared/services/parser/morrisoncsvparser.worker', { type: 'module' })
                  that.morrisonWorker.onmessage = (res)=>{
                    //event.currentTarget.value='';
                    that.isParseFailed = false;
                    that.migrationDataService.ipoCSVFileName = that.csvfilename;
                    that.migrationDataService.Morrison = res.data.morrisonData;
                    that.zone.run(() => {that.router.navigate(['/home/migration/rcauth']); });
                   // this.parserWorker.terminate();
                  }
                  that.morrisonWorker.onerror = (e) => { 
                    var err:ErrorData ={
                        Generic_Error:'Invalid CSV File',
                        Specific_Error:e.message
                    } ;
                    that.handleParserFailure(err);
                   // this.parserWorker.terminate();
                }
                  that.morrisonWorker.postMessage({
                    csvData:results.data,
                    isMorrison:that.isMorrison
                  });
                  }
                    else
                      that.parserWorker.postMessage({
                      csvData:results.data,
                     });
                }
                else{ 
                    reject(err);
                }
              }
          });
        }else{
          var err = {
                  Generic_Error:'Invalid File uploaded',
                  Specific_Error:'File does not exist '
            } as ErrorData;
          reject(err);
        }
      }else{
        var err = {
            Generic_Error:'Invalid File Format',
            Specific_Error:'File Uploaded is not a csv file'
                } as ErrorData;
        reject(err);
      }      
    });
  }

  private validateCSVFile(data):ErrorData{
    var fields = ['User_Info','System_Info','Extension_Info'];
    var genric_error = "Invalid CSV File";
    var specific_error = "";
    var flag = 0;
    if(this.csvfilename.startsWith('morrison') || this.csvfilename.startsWith('Morrison'))
    {
      this.isMorrison = true;
      let err = {
        Generic_Error:genric_error,
        Specific_Error:specific_error
      } as ErrorData;
      if(data && Array.isArray(data) && data.length > 1)
      {
      if(data[0][0] !== "AccessCode" || data[1][0] == "AccessCode")
        return err;
        if(data[0][1] === "" || isNaN(data[0][1]) === true)
        return err;
      }
    } 
    else
    {
      this.isMorrison = false;
    for(let fld of fields){
      specific_error = fld +" not found"
        let err = {
          Generic_Error:genric_error,
          Specific_Error:specific_error
        } as ErrorData;
        flag = 0;
      for(let i=0;i<data.length;i++){
        //Check if User_Info_Start , System_Info_Start,Extension_Info_Start , User_Info_End , System_Info_End,Extension_Info_End are also present 
        if(data[i][0].includes(fld+"_Start"))
          flag++;
        else if(data[i][0].includes(fld+"_End")){
          flag++;
          break;
        }
      }
      if(flag != 2)
        return err;
    } 
  }
    return null; 
  }
}
