import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { Component, OnInit ,NgZone, HostListener } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { skip } from 'rxjs/operators';


const ERORRMSG = {
  LOGIN_FAILED_ERROR : {
    GENERIC_MSG:'Login failed. Retry with valid account Id or Login using',
    RETRY_MSG:'Unable to validate the Account ID. Retry after 30 seconds or Login using'
  },
  NETWORK_ERROR : 'A network error has occurred.Retry after sometime or Login using'
};
const LOGIN_VIA_SERVICE_WEB_MSG = 'Login using ';

@Component({
  selector: 'app-rcauth',
  templateUrl: './rcauth.component.html',
  styleUrls: ['./rcauth.component.css']
})
export class RCAuthComponent implements OnInit {
  
    cardId:any;
    firebase: any;
    newWindow:any;
    id:any;
    private responseData:any;
    readonly TIMER_INTERVAL=60000;
    showRCSignInErrMsg = false;
    showAccId = false;
    onACOAuthSuccess = false;
    accountId = '';
    public companyName: string;
    rcSignInErrorMsg: string = ERORRMSG.LOGIN_FAILED_ERROR.GENERIC_MSG;
    wait:any;

  constructor(public zone: NgZone , private router: Router,
    private migrationDataService:MigrationDataService , 
    private firestoreService: FirestoreService, private spinnerOverlay:OverlayService,
    private logger:LogService, private session: SessionHandlerService) { 
        this.logger.debug("RCAuthComponent constructor");
        this.showRCSignInErrMsg = false;
        if(this.migrationDataService.IPOSummaryData == undefined && this.migrationDataService.IPOSummaryData  == null){
          if(this.migrationDataService.Morrison)
          skip;
            else
            this.zone.run(() => { this.router.navigate(['/home/migration'])})
        }else{
          this.companyName = this.session.companyName;
        }

        if(!this.migrationDataService.Morrison){
          this.buildACOTimeProfiles(this.migrationDataService.IPOTimeProfile);
        }
        
        this.firestoreService.readMTUserInfo()
        .then((result:any) => {
          var accIdLogin = result.accIdLogin;
          this.logger.debug("show account-id login: ",accIdLogin);
          if(accIdLogin === false){
            this.showAccId = false;
            this.triggerRcAuth();         
          }else{            
            this.showAccId = true;
          }
        })
        /* this.firestoreService.readMTUserInfo()
        .then((result: any) => {
            var policyList = result.policy;
            this.logger.debug("admin signin policy: ", policyList);
            policyList.forEach(entry => {
                if(entry === this.adminSignIn){
                    this.showAdminSignIn = true;
                }
            });
        })
        .catch((error) =>{
            this.logger.error("Unable to read MTUser info", error);
        }) */
  }

  ngOnInit(): void {
    window.addEventListener('beforeunload', (event) => {
      this.logger.debug("beforeunload listener : page refresh triggered");
      if(this.newWindow){
        this.newWindow.close()
      }
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }); 
  }

  ngOnDestroy() {
    if(this.newWindow){
      this.newWindow.close()
    }
    window.removeEventListener('beforeunload' , (event) => {});
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(event.keyCode == 116){
     // alert("f5 pressed");
      this.logger.debug("keydown listener : page refresh triggered by F5");
    }
  }

  navigateBack(){
    this.logger.debug(" navigateBack");
    this.zone.run(() => { this.router.navigate(['/home/migration/upload-csv']); }); 
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

  triggerRcAuth(){
    this.logger.debug("triggerRcAuth")
    this.showRCSignInErrMsg = false;
    this.spinnerOverlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);  
    if(this.responseData != undefined && this.responseData != null){
        this.logger.debug("response data already exists")
        this.validateResponse(this.responseData);
    }
    else{
        this.logger.debug("do fresh authentication");
        this.firestoreService.rcAuth()
        .then((val) => {      
            this.logger.debug("RC auth complete: ", val);
            //if validTokenExist is true then do below
            //login with rcLoginUrl value
            this.validateResponse(val);
        })
        .catch((err) => 
            {
            this.logger.debug(err);
            this.spinnerOverlay.closeSpinner();
            }
        );
    }

  }

  validateResponse(responseData){
    this.logger.debug("validateResponse");
    if(responseData && responseData.result){
      this.logger.debug("cardId : "+responseData.result.cardId);
      this.logger.debug("rcauth URL : "+responseData.result.rcLoginUrl) ;
      this.logger.debug("validtokenExist : "+responseData.result.validTokenExist);
      this.session.cardId = responseData.result.cardId;

      if(!responseData.result.validTokenExist){
        if(this.showAccId){
          this.rcSignInErrorMsg = ERORRMSG.LOGIN_FAILED_ERROR.GENERIC_MSG;
          this.showRCSignInErrMsg = true;       
        }                   
        this.responseData = responseData;
        this.spinnerOverlay.closeSpinner();
        this.onAcoAuthChange();

        //ACOS-762 , this change has to be reverted once we have new RC App created with ACO brand enabled for access permission 
        let rcLoginUrl = responseData.result.rcLoginUrl;
        if(window.location.hostname.includes("dev1.migrationsrv.com") && responseData.result.rcLoginUrl.includes("&brand_id=6010")){
          this.logger.debug("dev1.migrationsrv.com deployment , removing ACO brand from LoginURL");
          rcLoginUrl = rcLoginUrl.replace("&brand_id=6010","");
        }
        this.popupCenter(rcLoginUrl);
      }
    }
    else if(responseData && responseData.cardId){
      //show error dialog
      this.session.cardId = responseData.cardId;
      this.spinnerOverlay.closeSpinner();
      this.onAcoAuthChange();
    }
    else{
      //show error dialog
      this.rcSignInErrorMsg = ERORRMSG.LOGIN_FAILED_ERROR.GENERIC_MSG;
      this.showRCSignInErrMsg = true;
      this.logger.error("Response data is not valid");
      this.spinnerOverlay.closeSpinner();       
    }
    this.responseData = null;
  }

  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  onAcoAuthChange() {
    this.logger.debug(" onAcoAuthChange ");
    var cardId = this.session.cardId;
    this.onACOAuthSuccess = false;
   // this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.firestoreService.onAcoAuthChange(cardId)
    .then((curdata:any) =>{
      if (curdata.status == "started") {
        //this._snackBar.open(curdata.status);
        this.logger.debug("started");
      } else if (curdata.status == "inprogress") {
        this.logger.debug("inprogress");            
      } else if (curdata.status == "success") {
        this.logger.debug("success");
        if(this.newWindow){
          this.onACOAuthSuccess = true;
          this.showRCSignInErrMsg = false;          
          this.newWindow.close()
        }        
        this.spinnerOverlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);

        //if current migration type is morrison, directly call
        if(this.migrationDataService.Morrison){
          //this is for morrison migration and need to be removed after this release.
          this.firestoreService.setMorrisonData(cardId,this.migrationDataService.Morrison)    
          .then(() => {
            return this.firestoreService.updateCompanyName(cardId , this.migrationDataService.getCustomerCompanyName)
          }).then(()=>{
            this.routeMorrisonSummary();
          }).catch((err)=>{
            this.spinnerOverlay.closeSpinner();
            this.logger.error("onAcoAuthChange - updating company name failed. Error:",err)
          })          
        }else{
          //trigger acoImport data with trigger to 'start'       
          this.firestoreService.setIPOCfgData(cardId, this.migrationDataService.IPOCsvData)
          .then(() => {
              return this.firestoreService.updateCompanyName(cardId , this.migrationDataService.getCustomerCompanyName)          
          })
          .then(() => {
              if(this.migrationDataService.IPOTimeProfile &&
                  this.migrationDataService.IPOTimeProfile.TimeProfile &&
                  this.migrationDataService.IPOTimeProfile.TimeProfile.length){
                  return (this.buildACOTimeProfiles(this.migrationDataService.IPOTimeProfile)
                  .then((acoTimeProfiles) => {
                      return (this.firestoreService.setTimeProfilesData(cardId, acoTimeProfiles));
                  })
                  .catch((error) => {
                      this.logger.error("Time profile conversion failed: ", error);
                  }));
              }
              else{
                  return;
              }
          })
          .then(() => {
            if(this.migrationDataService.ShortCodes && 
              this.migrationDataService.ShortCodes.ShortCodesData && 
              this.migrationDataService.ShortCodes.ShortCodesData.length) {
              return (this.firestoreService.setShortCodeData(cardId, this.migrationDataService.ShortCodes.ShortCodesData));
            }
          })
          .then(() => {
            if(this.migrationDataService.SteeringCodes && 
              this.migrationDataService.SteeringCodes.SteeringCodesData && 
              this.migrationDataService.SteeringCodes.SteeringCodesData.length) {
                return (this.firestoreService.setSteeringCodeData(cardId, this.migrationDataService.SteeringCodes.SteeringCodesData));
              }
          })
          .then(() => {
              if(this.migrationDataService.IPOHuntGroup && 
                  this.migrationDataService.IPOHuntGroup.HuntGroup &&
                  this.migrationDataService.IPOHuntGroup.HuntGroup.length){
                  return (this.firestoreService.setHuntGroupData(cardId, this.migrationDataService.IPOHuntGroup.HuntGroup))
              }
              else{
                  return;
              }
          })
          .then(() => {
            if(this.migrationDataService.IncomingCallRoute && 
                this.migrationDataService.IncomingCallRoute.IncomingCallRoute &&
                this.migrationDataService.IncomingCallRoute.IncomingCallRoute.length){
                    let _icrAAList = [];
                    let _icrList = this.migrationDataService.IncomingCallRoute.IncomingCallRoute
                    _icrList.map((entry:any ) => {
                        let _AAList = [];
                        let ICRFound = entry.Standard.find((icrNum:any) =>  icrNum.ICR_Number != "");
                        if(ICRFound){
                            var tempICRData;
                            entry.Destinations.map((destEntry:any) => {
                                if(destEntry.Destination.includes('AA:')){
                                    let aaName = destEntry.Destination.split('AA:').pop();
                                    let aaTimeProfile = destEntry.Timeprofile;
                                    entry.Standard.forEach(element => {
                                      tempICRData = element.ICR_Number
                                    });
                                    let aaEntry = {
                                        name: aaName,
                                        timeProfile: aaTimeProfile,
                                        phoneNumber: '',
                                        phoneId: '',
                                        icrNumber: tempICRData,
                                    }
                                    _AAList.push(aaEntry);
                                }
                                else if (destEntry.Destination.includes('VM:')) {
                                  let aaName = destEntry.Destination.split('VM:').pop();
                                  let aaTimeProfile = destEntry.Timeprofile;
                                  entry.Standard.forEach(element => {
                                    tempICRData = element.ICR_Number
                                  });
                                  let aaEntry = {
                                      name: aaName,
                                      timeProfile: aaTimeProfile,
                                      phoneNumber: '',
                                      phoneId: '',
                                      icrNumber: tempICRData,
                                  }
                                  _AAList.push(aaEntry);
                                } 
                                else if (destEntry.Destination.startsWith('*')) {
                                  if (this.migrationDataService.ShortCodes && this.migrationDataService.ShortCodes.ShortCodesData && 
                                        this.migrationDataService.ShortCodes.ShortCodesData.length) {
                                      let aaName = destEntry.Destination.toString();
                                      let shortCodeEntry = this.migrationDataService.ShortCodes.ShortCodesData.find((entry) => (entry.SC_ShortCode === aaName));
                                      if (shortCodeEntry !== undefined) {
                                        aaName = shortCodeEntry.SC_Telephone;
                                        let aaTimeProfile = destEntry.Timeprofile;
                                        entry.Standard.forEach(element => {
                                            tempICRData = element.ICR_Number
                                        });
                                        let aaEntry = {
                                            name: aaName,
                                            timeProfile: aaTimeProfile,
                                            phoneNumber: '',
                                            phoneId: '',
                                            icrNumber: tempICRData,
                                        }
                                        _AAList.push(aaEntry);
                                      }
                                    } 
                                }
                            });

                            if(_AAList.length){
                                _icrAAList.push(_AAList);
                            }
                        }
                    });
                    this.firestoreService.setAA_ICRData(cardId, _icrAAList);
                    return ( this.firestoreService.updateAA_ICRData(cardId, _icrAAList,'false'));
              }
              else{
                  return;
              }
          })
          .then(() => {
              if(this.migrationDataService.AutoAttendant &&
                  this.migrationDataService.AutoAttendant.AutoAttendant &&
                  this.migrationDataService.AutoAttendant.AutoAttendant.length){
                      return (this.firestoreService.setAutoAttendantData(cardId, this.migrationDataService.AutoAttendant.AutoAttendant));
                  }
                  else{
                      return;
                  }
          })
          // .then(() => {
          //   if(this.migrationDataService.PagingGroup && 
          //       this.migrationDataService.PagingGroup.PagingGroup &&
          //       this.migrationDataService.PagingGroup.PagingGroup.length){
          //       return (this.firestoreService.setPagingGroupData(cardId, this.migrationDataService.PagingGroup.PagingGroup));
          //   }
          //   else{
          //       return;
          //   }
          // })
          .then(() => {
              this.triggerAcoImport(cardId) 
            })
          .catch(error => {
            this.spinnerOverlay.closeSpinner();
            this.logger.error(error)
          });
        }
      } else if (curdata.status == "failed") {
        this.logger.debug("failure");
      }
      
    }).catch((err) =>{
     this.spinnerOverlay.closeSpinner();
      this.logger.error(err);
    });

  }

  handleACOLogin() {
    this.logger.debug("handleACOLogin");
    if(this.accountId != null && this.accountId != undefined) {
        if(this.accountId.trim() !== ""){
            let migrationType = "";
            if(this.migrationDataService.Morrison){
              migrationType = "morrison"
            }
            
            this.spinnerOverlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent); 
            firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
                let passArgs = {
                    accountId: this.accountId,
                    type: migrationType,
                    idToken
                };

                var acoAdminSignin = firebase.functions().httpsCallable('acoAdminSignin');
                acoAdminSignin(passArgs).then((res) => {
                    // Read result of the Cloud Function.          
                    this.logger.debug('Received result -' + JSON.stringify(res.data));
                    return (res.data);
                })
                .then((result) => {
                    this.validateResponse(result);
                })
                .catch((error) => {
                    var code = error.code;
                    var message = error.message;
                    var details = error.details;
                    this.logger.debug("handleACO login failure: ", code + ' - ' + message);
                    //ACOS-209:Error "Login failed. Retry with Valid account Id" shown even the entered account id details are appropriate
                    if(message.includes("signature"))
                      this.rcSignInErrorMsg = ERORRMSG.LOGIN_FAILED_ERROR.RETRY_MSG;
                    else
                      this.rcSignInErrorMsg = ERORRMSG.LOGIN_FAILED_ERROR.GENERIC_MSG;
                    this.showRCSignInErrMsg = true;
                    this.spinnerOverlay.closeSpinner();
                });
            }).catch((error) => {
                this.rcSignInErrorMsg = ERORRMSG.NETWORK_ERROR;
                this.showRCSignInErrMsg = true;
                this.logger.debug("handleACO token failure: ", error);
                this.spinnerOverlay.closeSpinner();
            });
        }
    }
  }


  triggerAcoImport(cardId){
    this.logger.debug("triggerAcoImport");    
    this.spinnerOverlay.closeSpinner();
    this.zone.run(() => { this.router.navigate(['/home/migration/summary']); });      

  }

  routeMorrisonSummary(){
    this.logger.debug("routeMorrisonSummary");    
    this.spinnerOverlay.closeSpinner();
    this.zone.run(() => { this.router.navigate(['/home/migration/morrison-summary']); });
  }

  buildACOTimeProfiles(data){
    let _promise = new Promise((resolve, reject) => {
        try{
            let acoTimeProfiles = data.TimeProfile.map(tpEntry => {
                let timeprofile = {};
                timeprofile['name'] = tpEntry.TP_Name;
                let schedule = {};
                schedule['data'] = {};
                schedule['data']['schedule'] = {};

                tpEntry.Recurrance_TimeProfiles.map((scheduleEntry, idx) => {
                    if(idx == 0){
                        schedule['data']['schedule']['weeklyRanges'] = {};
                    }


                    if(scheduleEntry.MondaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['monday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['monday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].monday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                    if(scheduleEntry.TuesdaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['tuesday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['tuesday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].tuesday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                    if(scheduleEntry.WednesdaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['wednesday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['wednesday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].wednesday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                    if(scheduleEntry.ThursdaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['thursday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['thursday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].thursday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                    if(scheduleEntry.FridaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['friday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['friday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].friday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                    if(scheduleEntry.SaturdaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['saturday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['saturday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].saturday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                    if(scheduleEntry.SundaySelected.toLowerCase() == 'true'){
                        if(typeof schedule['data']['schedule']['weeklyRanges']['sunday'] == 'undefined')
                            schedule['data']['schedule']['weeklyRanges']['sunday'] = [];
                        schedule['data']['schedule']['weeklyRanges'].sunday.push({"from": scheduleEntry.StartTime,
                        "to": scheduleEntry.EndTime});
                    }
                });

                tpEntry.Calender_TimeProfiles.map((scheduleEntry, idx) => {
                    if(idx == 0){
                        schedule['data']['schedule']['ranges'] = [];
                    }
                    scheduleEntry.Dates.map(dateEntry => {
                        let startDate = dateEntry.concat('T', scheduleEntry.StartTime);
                        let endDate = dateEntry.concat('T', scheduleEntry.EndTime);
                        schedule['data']['schedule']['ranges'].push({"from": startDate, "to": endDate});
                    });
                });

                timeprofile['profiles'] = schedule;
                
                return timeprofile;
            });
            resolve(acoTimeProfiles);
        }
        catch(error){
            this.logger.error("Time profile conversion failed: ", error);
            reject(error);
        }
    });
    return _promise;
  }


  popupCenter(rcLoginUrl){
    //var url = "https://us-central1-vignaraj-playground.cloudfunctions.net/authorize?state="+id;
    var w = 400;
    var h = 400;
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    this.newWindow = window.open(rcLoginUrl, "RC Authentication", 
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (window.focus) this.newWindow.focus();
    document.onmousedown = this.focusPopup.bind(this);
    document.onkeyup  = this.focusPopup.bind(this);
    document.onmousemove = this.focusPopup.bind(this);
    this.wait = setInterval(()=>{
      this.setRCErrMsgStr();
      if(this.newWindow === null){
        clearInterval(this.wait);
      }
    },1000);
  }

  focusPopup(){
    if(this.newWindow && !this.newWindow.closed) {
         this.newWindow.focus(); 
    }
    this.setRCErrMsgStr();
  } 

  setRCErrMsgStr(){
    if(this.newWindow && this.newWindow.closed){
      this.newWindow = null;
      if(!this.onACOAuthSuccess){
        this.rcSignInErrorMsg = LOGIN_VIA_SERVICE_WEB_MSG;
        this.showRCSignInErrMsg = true;
      }else{
        this.rcSignInErrorMsg = "";
        this.showRCSignInErrMsg = false;
      }
    }
  }

}
