import { FireAuthService } from './../../../shared/services/firebaseAuth/fire-auth.service';
import { FirestoreService , MIGRATION_STAGE } from 'src/app/shared/services/firestore/firestore.service';
import { Component, OnInit, NgZone, ChangeDetectorRef ,  ViewChildren, QueryList, ElementRef, ViewChild, HostListener} from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import * as moment from 'moment';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { ReAuthService } from 'src/app/shared/services/rc-reauth/rc-reauth.service';
import { PromptDialogComponent } from 'src/app/shared/components/dialog/prompt-dialog/prompt-dialog.component';

const numberOfCardsToShow = 9;
const LAST_STATUS = {
      UNKNOWN: 'unknown',
      NOTSTARTED: 'notstarted',
      STARTED: 'started',
      INPROGRESS: 'inprogress',
      SUCCESS: 'success',
      FAILURE: 'failure',
      ABORTED: 'aborted'
  };

@Component({
  selector: 'app-migration-cards',
  templateUrl: './migration-cards.component.html',
  styleUrls: ['./migration-cards.component.css']
})

export class MigrationCardsComponent implements OnInit {
  //this is used in case we move to mat-select instead of now custom dropdown 
  /* @ViewChildren("search") components:QueryList<any>;
  @ViewChild("search", {static: false}) nameField: ElementRef;
  @ViewChild('search') someRef: MatSelect; */

  migrationCardsDetails = [];
  private TIMER_INTERVAL = 60000;
  private authStateChange: Subscription;
  public searchInput:string = "";
  public status = LAST_STATUS;
  lastDoc: any;
  allRead: boolean = false;
  public stage = MIGRATION_STAGE;

  constructor(private router: Router , private fireStoreService:FirestoreService,
    private fireauthService:FireAuthService, private cdRef: ChangeDetectorRef,
    private overlay:OverlayService, public zone: NgZone, private logger:LogService,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private reauthService: ReAuthService, public dialog: MatDialog) {
    this.logger.debug("MigrationCardsComponent Constructor");
    this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
      this.authStateChange.unsubscribe();
      if(data !== null){
        this.resetPaginateQuery();
        this.fetchUserCardDetails();
      }else{
        this.zone.run(() => { this.router.navigate(['/']); });
      } 
    });
    this.fireauthService.authStateChangeListener();
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    /* this.components.changes.subscribe(t => {
      this.ngForRendred();
    }) */
  }

  ngOnDestroy() {
  }

  ngForRendred() {
    //this is used in case we move to mat-select instead of now custom dropdown
    //if(this.someRef) this.someRef.focus();
    /* this.zone.run(() => {
      //this.cdRef.detectChanges();
      console.log('NgFor is Rendered');
      console.log("test::",this.components.toArray());
    }) */
  }

  addCard(){
    this.router.navigate(['/home/migration/company-info']);
  }
  
  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  updateSearchFilter(){
    this.logger.info(" updateSearchFilter: search " ,this.searchInput);
    if(this.searchInput.trim() !== "" && this.searchInput !== null ){
      this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
      this.fireStoreService.fetchCardsByCompanyName(this.searchInput,numberOfCardsToShow)
      .then((docArray) =>{
        this.logger.debug("search matched docs: ",docArray);
        this.migrationCardsDetails = [];
        return (this.fetchCardDataForThumbNail(docArray));
      })
      .then(() =>{
          this.overlay.closeSpinner();
      }).catch((error) =>{

      })
    }else{
        this.logger.debug("search imput is empty. calling fetchUserCardDetails");
        this.resetPaginateQuery();
        this.fetchUserCardDetails();
    }
    
  }

  fetchUserCardDetails() {
    this.logger.debug("fetchUserCardDetails");
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    if(!this.allRead){
        this.fireStoreService.fetchAllCards(numberOfCardsToShow, this.lastDoc)
        .then((querySnapshot: any) =>{
            if(querySnapshot.docs.length === 0) {
                this.logger.debug("All card fetch completed");
                this.allRead = true;
                this.overlay.closeSpinner();
                return;
            }
            else if(querySnapshot.docs.length < numberOfCardsToShow){
                this.allRead = true;
            }
            
            let len = querySnapshot.docs.length-1;
            this.lastDoc = querySnapshot.docs[len];
            let tempDoc = [];
            querySnapshot.forEach((doc) => {
                tempDoc.push({ id: doc.id, ...doc.data() })
            });
            this.fetchCardDataForThumbNail(tempDoc);
        })
        .then(()=>{
            this.overlay.closeSpinner();
        })
        .catch((error) =>{
            this.overlay.closeSpinner();
        //Display error
        })
    }
    else{
        this.logger.debug("All cards read already");
        this.overlay.closeSpinner();
    }
  }

  fetchCardDataForThumbNail(migrationCardsList){
    var promise = new Promise((resolve, reject) => {
            migrationCardsList.forEach((card) => {
                let temp = card;
                temp['id'] = card.id;
                temp['date'] = moment(temp.created.toDate()).fromNow();
                temp['users'] = card.totalIpoUsers;
                switch(card.stage){
                case MIGRATION_STAGE.PREVIEWSUMMARY:
                case MIGRATION_STAGE.MORRISONSUMMARY:
                    temp['percentage'] = "20%";
                    break;
                case MIGRATION_STAGE.PREPARETABLE:
                    temp['percentage'] = "40%";
                    break;
                case MIGRATION_STAGE.DATAMIGRATION:
                    temp['percentage'] = "50%";
                    break;
                case MIGRATION_STAGE.COMPLETE:
                    if(card.lastStatus == LAST_STATUS.SUCCESS){
                        temp['percentage'] = "100%";
                        temp['users'] = card.assignUsersSuccess;
                    }else if(card.lastStatus == LAST_STATUS.FAILURE || card.lastStatus == LAST_STATUS.ABORTED){
                        //calculate the percentage for 40% alloted value for cloud functions
                        /* let successPercentage:number = 0 , totalPercentage:number = 0;
                        successPercentage = Math.round(card.assignUsersSuccess/card.assignUsersTotalUsers) * 40;
                        totalPercentage = 60 + successPercentage;
                        
                        this.logger.debug("success percentage with failure migration :",successPercentage);
                        temp['percentage'] = totalPercentage+"%"; */
                        temp['percentage'] = "60%";
                        temp['users'] = card.assignUsersSuccess;
                    }else{
                        temp['percentage'] = "60%";
                        temp['users'] = card.totalIpoUsers;
                    }
                    break;
                default:
                    //this.logger.debug("Stage field not found for :",card.id); 
                    temp['percentage'] = "";  
                }
                if(temp.stage != -1){
                    if(temp.markAsCompleted){
                        temp['percentage'] = "100%";
                    }
                    this.migrationCardsDetails.push(temp);
                    }
                else{
                this.logger.debug("Stage is -1 for card id :",card.id);
                }
                
            });
            this.cdRef.detectChanges();
            this.logger.debug("Cards count: ", this.migrationCardsDetails.length);
            resolve();
    });


    return promise;
  }

  showCardSummary(cardId, stage, company) {
    this.logger.debug("showCardSummary", cardId);
    this.migrationDataService.clearData();
    this.reauthService.validateTokenAndReauthenticate(cardId)
      .then((result: any) => {
        this.session.cardId = cardId;
        this.session.companyName = company;
        switch (stage) {
          case MIGRATION_STAGE.PREVIEWSUMMARY:
            this.zone.run(() => { this.router.navigate(['/home/migration/summary']); });
            break;
          case MIGRATION_STAGE.MORRISONSUMMARY:
            this.zone.run(() => { this.router.navigate(['/home/migration/morrison-summary']); });
            break;
          case MIGRATION_STAGE.PREPARETABLE:
            this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/user-data']); });
            break;
          case MIGRATION_STAGE.DATAMIGRATION:
          case MIGRATION_STAGE.COMPLETE:
            this.zone.run(() => { this.router.navigate(['/home/migration/startMigration']); });
            break;
          default:
            this.logger.debug("showCardSummary for card id not found", cardId);
        }
      })
      .catch((error) => {
          this.logger.error(`${cardId} - showCardSummary: ${error}`);
          //open a UI pop up to show that exception has occurred
          this.notifyAuthenticationFailure(error);
      });
  }

  notifyAuthenticationFailure(message) {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
          message: message,
          isWarning: true
      }
    });
    return dialogRef.afterClosed();
  }

  markAsComplete(event:any , cardId){
    this.logger.debug("markAsComplete :",cardId);
    document.getElementById(cardId).classList.toggle("show");
    event.cancelBubble = true;
    //update CardId doc with markAsCompleted as 'true'
    //TODO: upadate export status with progress 100%
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.fireStoreService.updateMarkAsComplete(cardId)
    .then((res) =>{
      //TODO:
      //update the status in ACOExport status , progress to 100%   OR
      //in startMigration page , check for markascomplete field and update progress directly 

      //fetch all cards again to update UI with latest 9 cards 
      this.resetPaginateQuery();
      return this.fireStoreService.updateMarkAsCompleteToAnalytics(cardId);   
    }).then((res) =>{
      this.overlay.closeSpinner();
      this.fetchUserCardDetails();
    }).catch((error) =>{
      this.overlay.closeSpinner();
      this.logger.error("Updating markascomplete failed: ",cardId)     
    })
  }

  deleteCard(event:any , cardId , companyName){
    this.logger.debug("deleteCard :",cardId);
    event.cancelBubble = true;
    let msg = "'"+companyName + "' migration card will be deleted. Are you sure ?"
    this.session.alertModal(this.onDelete.bind(this , cardId) , msg); 
    this.zone.run(() => {
      this.cdRef.detectChanges();
    })
  }

  onDelete(cardId){
    document.getElementById(cardId).classList.toggle("show");
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.fireStoreService.deleteUserCard(cardId)
    .then((result:any) =>{
      this.logger.debug("card deleted :",cardId);
      this.overlay.closeSpinner();
      this.resetPaginateQuery();
      //fetch all cards again to update UI with latest 9 cards 
      this.fetchUserCardDetails();
    }).catch((error) =>{
      this.overlay.closeSpinner();
      this.logger.error("card deletion failed :",cardId)
    })
  }

  resetPaginateQuery(){
    this.allRead = false;
    this.lastDoc = {};
    this.migrationCardsDetails = [];
  }
  /* open(cardId){
    this.components.forEach((element, index) => {
      console.log(element._id)
      if(element._id === cardId){
        element.open();
        this.cdRef.detectChanges();
      }
    });
  } 
  
  
  log(event,cardId){
    console.log(event)
  } */

  openDropDown(event:any ,cardId) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    for (var i = 0; i < dropdowns.length; i++) {
      let openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show') && openDropdown.id !== cardId) {
        openDropdown.classList.remove('show');
      }
    }
    document.getElementById(cardId).classList.toggle("show");
    event.cancelBubble = true;
  }

  @HostListener('document:click', ['$event'])
  onClick(event:any) {
    // do something meaningful with it
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  /* window.addEventListener("click", function(event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }) */
}
