<div class = "tabInfo">
    <p class = "intro">
        Shows all the system data that are going to migrate. Each section is read only and details can be viewed by clicking on view button. 
    </p>
    <div style="display:flex;flex-direction:column;margin-right:-15%;">
        <div style="margin-top:3%;">
            <span style="display:flex;">
                <mat-label class="labelField"  style="margin-right:18%;">Hunt Groups and Paging Groups:</mat-label>
                <img class="editBtnImg" [style]="{'opacity':isDataReady && isGreetingsFileUploaded !== greetingsTriggerStatus.START?'1':'0.5'}" (click)="showHuntGroups()" src="../../../../../assets/images/config-summary/Icon_Edit_data.svg" matTooltip="Click to edit hunt/paging groups data">
                <img *ngIf = "isDataReady && !isHGWarnFound && !isPGWarnFound && isGreetingsFileUploaded !== greetingsTriggerStatus.START" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                <img *ngIf="isDataReady && (isHGWarnFound || isPGWarnFound )&& isGreetingsFileUploaded !== greetingsTriggerStatus.START" style="vertical-align:top" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_with_warning.svg" matTooltip="Found warnings in hunt groups/paging groups" >
                <div style="position:relative;margin-left:2%" *ngIf = "!isDataReady || isGreetingsFileUploaded == greetingsTriggerStatus.START">
                    <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                      <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                    </ngx-spinner>
                </div>
            </span>
            <span class="descrLabel" style="line-height:0.25%;">Number of Hunt groups : {{numberOfHuntGroups}}<br>
                Number of Paging groups : {{numberOfPagingGroups}}
            </span>
            <!-- <p class="descrLabel">Number of Paging groups : {{numberOfPagingGroups}}</p> -->
            <mat-divider class="hozDivider"></mat-divider>
        </div>

        <!-- <div style="margin-top:2%;">
            <span style="display:flex;">
                <mat-label class="labelField"  style="margin-right:32%;">Paging Groups :</mat-label>
                <img class="viewBtnImg" [style]="{'opacity':isDataReady?'1':'0.5'}" (click)="showPagingGroups()" src="../../../../../assets/images/config-summary/Icon_View_data.svg" matTooltip="Click to view paging groups data">
                <img *ngIf = "isDataReady && !isPGWarnFound" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                <img *ngIf="isDataReady && isPGWarnFound" style="vertical-align:top" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_with_warning.svg" matTooltip="Some Members do not have paging devices assigned" >
                <div style="position:relative;margin-left:2%" *ngIf = "!isDataReady">
                    <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                      <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                    </ngx-spinner>
                </div>
            </span>
            <p class="descrLabel">Number of Paging groups : {{numberOfPagingGroups}}</p>
            <mat-divider class="hozDivider"></mat-divider>
        </div> -->


        <div style="margin-top:3%;">
            <span style="display:flex;">
                <mat-label class="labelField"  style="margin-right:41%;">Auto Attendants :</mat-label>
                <img class="viewBtnImg" [style]="{'opacity':isDataReady && isGreetingsFileUploaded != greetingsTriggerStatus.START?'1':'0.5'}" (click)="showAutoAttendants()" src="../../../../../assets/images/config-summary/Icon_View_data.svg" matTooltip="Click to view auto attendants data">
                <img *ngIf = "isDataReady && !isAAWarnFound && isGreetingsFileUploaded != greetingsTriggerStatus.START" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                <img *ngIf="isDataReady && isAAWarnFound && isGreetingsFileUploaded != greetingsTriggerStatus.START" style="vertical-align:top" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_with_warning.svg" 
                     matTooltip="{{aaToolTipText}}" [matTooltipClass]="'multiLine'">
                <div style="position:relative;margin-left:2%" *ngIf = "!isDataReady || isGreetingsFileUploaded == greetingsTriggerStatus.START">
                    <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                      <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                    </ngx-spinner>
                </div>
            </span>
            <p class="descrLabel">Number of Auto Attendants : {{numberOfAutoAttendants}}</p>
            <mat-divider class="hozDivider"></mat-divider>
        </div>


        <div style="margin-top:3%;">
            <span style="display:flex;">
                <mat-label class="labelField"  style="margin-right:46.5%;">Time Profiles :</mat-label>
                <img class="viewBtnImg" [style]="{'opacity':isDataReady?'1':'0.5'}" (click)="showTimeProfiles()" src="../../../../../assets/images/config-summary/Icon_View_data.svg" matTooltip="Click to view time profiles data">
                <img *ngIf = "isDataReady && !isMixedTPFound" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                <img *ngIf="isDataReady && isMixedTPFound" style="vertical-align:top" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_with_warning.svg" matTooltip="Found mixed time profiles" >
                <div style="position:relative;margin-left:2%" *ngIf = "!isDataReady">
                    <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                      <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                    </ngx-spinner>
                </div>
            </span>
            <p class="descrLabel">Number of Time Profiles : {{numberOfTimeProfiles}}</p>
            <mat-divider class="hozDivider"></mat-divider>
        </div>
        <div style="display:flex;flex-direction:column;margin-right:-15%;">
            <div style="margin-top:3%;">
                <span style="display:flex;">
                    <mat-label class="labelField"  style="margin-right:33%;">Company Numbers:</mat-label>
                    <img class="editBtnImg" [style]="{'opacity':isDataReady&& isGreetingsFileUploaded != greetingsTriggerStatus.START?'1':'0.5'}" src="../../../../../assets/images/config-summary/Icon_Edit_data.svg" matTooltip="Click to view and edit the user data"
                     (click)="showIncomingNumbers()">
                     <img *ngIf = "isDataReady && (isCompanyNumberAssigned || numberOfAutoAttendants == 0 || this.numberofIncomingNumbers == 0)&& isGreetingsFileUploaded != greetingsTriggerStatus.START" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_1.svg" matTooltip="Ready To migrate">
                     <img *ngIf="isDataReady && (numberOfAutoAttendants > 0 && !isCompanyNumberAssigned && this.numberofIncomingNumbers > 0)&& isGreetingsFileUploaded != greetingsTriggerStatus.START" style="vertical-align:top" src="../../../../../assets/images/config-summary/Icon_Green_check_mark_with_warning.svg"
                     matTooltip="Auto Attendants assigned to default Company Numbers" >
                    <div style="position:relative;margin-left:2%" *ngIf="!isDataReady || isGreetingsFileUploaded == greetingsTriggerStatus.START">
                        <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                          <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                        </ngx-spinner>
                    </div>
                </span>
                <p class="descrLabel">Number of Incoming Numbers : {{numberofIncomingNumbers}}</p>
                <mat-divider class="hozDivider"></mat-divider>
            </div>
        </div>
    </div>
</div>

