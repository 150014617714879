import { Injectable } from '@angular/core';
import { LogService } from '../logging/log.service';

//cdk
import { Overlay, OverlayRef ,OverlayConfig} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private overlayRef ;
  private wait;
  private spinnerStateChange = new Subject<any>();
  private _showSpinner:boolean =false;
  get showSpinner(): boolean {
      return this._showSpinner;
  }
  set showSpinner(value: boolean) {
      this._showSpinner = value;
      this.broadcastMessage(value);
  } 
  
  
  constructor(private overlay: Overlay,
    private logger:LogService) {   
    this.logger.debug("OverlayService Constructor");  
  }

  broadcastMessage(data) {
    this.spinnerStateChange.next(data);
  }

  clearMessage() {
    //this.logger.debug("clear");
    this.spinnerStateChange.next();
  }

  subscribeMessage(): Observable<any> {
    //this.logger.debug("Subscribe");
    return this.spinnerStateChange.asObservable();
  }

  private cdkCreateOverlay(){
    return this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
          .global()
          .centerHorizontally()
          .centerVertically()
    });
  }

  openSpinner(interval:number,callback:Function,component:any){
    //this.logger.debug("openSpinner");   
    this.overlayRef = this.cdkCreateOverlay();
    this.overlayRef.attach(new ComponentPortal(component));
    this.showSpinner = true;
    this.startTimer(interval,callback, component);
  }

  startTimer(interval:number,callback:Function,component:any){
    this.wait = setTimeout((callbackfunc) => {
      if(this.wait != null || this.wait != undefined)
        clearTimeout(this.wait);
      callbackfunc(this);
    }, interval,callback);
    
  }
    
  closeSpinner(){
    //this.logger.debug("closeSpinner"); 
    this.showSpinner = false; 
    if(this.overlayRef.hasAttached())
    {
      this.overlayRef.detach(); 
      this.overlayRef.dispose();     
    }
    this.stopTimer();
  }

  stopTimer(){
    if(this.wait != null || this.wait != undefined)
      clearTimeout(this.wait);    
  }
  
}
