import { FireAuthService } from './../firebaseAuth/fire-auth.service';
import { environment } from '../../../../environments/environment';
import { LogService } from '../logging/log.service';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
declare var gapi: any;
@Injectable({
  providedIn: 'root'
})
export class GSignInService {

  private gToken:any;
  private profileInfo:any;
  private subject = new Subject<any>();
  
  constructor(private fireauthService:FireAuthService,
    private logger:LogService) {
    this.logger.info("GSignInService constructor");
    this.googleInit();
  }

  sendMessage(data) {
    this.subject.next(data);
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  public auth2: any;
  
  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: environment.client_id,
        cookie_policy: environment.cookie_policy,
        scope: environment.scope
      });
      //this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {

        this.profileInfo = googleUser.getBasicProfile();
        //this.logger.debug('Token || ' + googleUser.getAuthResponse().id_token);
        this.logger.debug('ID: ' + this.profileInfo.getId());
        this.logger.debug('Name: ' + this.profileInfo.getName());
        this.logger.debug('Email: ' + this.profileInfo.getEmail());

        //Broadcast with data 
        this.sendMessage(googleUser);

      }, (error) => {
        this.logger.error(JSON.stringify(error, undefined, 2));
      });
  }

  public signIn(){
    this.logger.debug("signIn");
    var promise = new Promise((resolve, reject) => {
      this.auth2.signIn().then((user) => {
        this.logger.debug("google signin successfull")
        this.profileInfo = user.getBasicProfile();
        resolve(user);
      }).catch((err) =>{
        this.logger.error("google signin failed :"+err)
        reject(err);
      });
    });

    return promise;
    
  }

  public signOut(){
    var promise = new Promise((resolve, reject) => {
      this.auth2.signOut().then((data) => {
        this.logger.info("google logout successfull")
        resolve(data);
      }).catch((err) =>{
        this.logger.error("google logout failed :"+err)
        reject(err);
      });
    });

    return promise;
    
  }

  public getProfileInfo(){
      return this.profileInfo;
  }
}
