<div class="contentBlock  migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn"
            mat-icon-button
            matTooltip="close"
            (click)= "closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 2/6 : Import CSV</span>
            <span class="migrationCompany">{{companyName}}</span>
        </div>
    </div>
    <div class="contentMain parent-flex">
        <mat-form-field class="migrationInputFormField" *ngIf="!showErrorModal">
            <mat-label>Upload CSV File (configuration.csv)</mat-label>
            <input accept=".csv" style="display: none" type="file" multiple (click)="fileInput.value = null" (change)="onFileChanged($event)" #fileInput> 
            <input matInput type="text"  [(ngModel)]="csvfilename">
            <button mat-button  matSuffix mat-icon-button (click)="fileInput.click()">
                <mat-icon>unarchive</mat-icon>
            </button>
        </mat-form-field>
        <span class="errorMsg" *ngIf="isParseFailed && !showErrorModal">
            <mat-icon style=" font-size: 130%;color: #DA291C;">error</mat-icon>
            Unable to extract configuration data,  
            <a style="text-decoration: underline;" (click)="viewErrorModal()">View details</a>
        </span>
                 
        <div class="errorModal" *ngIf="showErrorModal">
            <mat-icon style=" font-size: 100%;margin-left:auto;margin-top:2%" (click) = "closeErrorModal()">close</mat-icon>
            <p class="errorModalHeader">
                <mat-icon style=" font-size: 140%;color: #DA291C;margin-top: 2%;"> error</mat-icon>
                &nbsp;&nbsp;Error - Unable to parse the CSV File
            </p>
            <p class="errorModalMsg">Error Details : {{errorMsg}}</p>
            <p class="errorModalMsg">SyntaxError: {{syntaxErrorMsg}}</p>
        </div>
    </div>
    
    <div class="navigation">
        <button mat-button class="arrowBack" matSuffix mat-icon-button (click)= "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-button class="arrowForward" matSuffix mat-icon-button (click)= "navigateNext()" *ngIf = "csvfilename && !isParseFailed">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>