import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  message:string = "Are you sure?"
  confirmButtonText:string = "Ok"
  cancelButtonText:string = "Cancel";
  isWarning:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<ConfirmationDialogComponent>) { 
    
    dialogRef.disableClose = true;
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if(data.isWarning){
        this.isWarning = data.isWarning;
      }
    }
  }

  ngOnInit(): void {
  }

  onConfirmClicked(): void {
    this.dialogRef.close(true);
  }
  onCancelClicked(): void {
    this.dialogRef.close(false);
  }

}
